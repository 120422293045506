import { useEffect, useReducer } from 'react'
import { ReactStripeElements } from 'react-stripe-elements'
import * as t from 'io-ts'
import * as Sentry from '@sentry/browser'

import { PurchaseData } from '@recordset-local/types/server/api'
import { getProjectQuoteRequest } from '@recordset-local/core/api/requests/stripe'
import { callApi } from '@recordset-local/core/web/request'

export type PurchaseStep = 'INITIATE' | 'NEEDS_CONFIRMATION' | 'COMPLETED' | 'ERROR'

type Step = PurchaseStep | ('GET_INTENT' | 'CONFIRM_CHARGE')

type TPurchaseData = t.TypeOf<typeof PurchaseData>

type State = {
  step: Step
  processing: boolean
  purchaseData: TPurchaseData | null
  error: string | null
  stripe: ReactStripeElements.StripeProps | null
  cardElement: stripe.elements.Element | null
}

const initialState: State = {
  step: 'INITIATE',
  processing: false,
  purchaseData: null,
  error: null,
  stripe: null,
  cardElement: null,
}

type Action =
  | { type: 'GET_INTENT' }
  | { type: 'NEEDS_CONFIRMATION'; data: TPurchaseData }
  | { type: 'CONFIRM_CHARGE'; cardElement: stripe.elements.Element; stripe: ReactStripeElements.StripeProps }
  | { type: 'COMPLETED' }
  | { type: 'SET_ERROR'; error: string }
  | { type: 'RESET_ERROR' }
  | { type: 'RESET_STATE' }
  | { type: 'SET_PROCESSING'; processing: boolean }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'GET_INTENT':
      return { ...state, step: 'GET_INTENT' }
    case 'NEEDS_CONFIRMATION':
      return { ...state, purchaseData: action.data, step: 'NEEDS_CONFIRMATION' }
    case 'CONFIRM_CHARGE':
      return { ...state, cardElement: action.cardElement, stripe: action.stripe, step: 'CONFIRM_CHARGE' }
    case 'COMPLETED':
      return { ...state, step: 'COMPLETED' }
    case 'SET_ERROR':
      return { ...state, purchaseData: null, error: action.error, step: 'ERROR' }
    case 'RESET_ERROR':
      return { ...state, error: null }
    case 'RESET_STATE':
      return initialState
    case 'SET_PROCESSING':
      return { ...state, processing: action.processing }
  }
  return state
}

export const usePurchaseProject = (projectId: string, userAuth?: string) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getIntent = async () => {
    resetError()
    setProcessing(true)

    try {
      const purchaseData = await callApi(getProjectQuoteRequest, { projectId, userAuth })
      setNeedsConfirmation(purchaseData)
    } catch (e) {
      setError(e.message)
    } finally {
      setProcessing(false)
    }
  }

  const confirmCharge = async () => {
    resetError()
    setProcessing(true)

    try {
      // TODO: old types
      type ConfirmResponse =
        | {
            type: 'card_error'
            message: string
          }
        | stripe.paymentIntents.PaymentIntent

      if (state.purchaseData === null) {
        throw new Error('invalid state, attempt to confirm payment without purchaseData')
      }

      // @ts-ignore
      const { paymentIntent, error } = (await state.stripe.confirmCardPayment(state.purchaseData.secret, {
        payment_method: {
          card: state.cardElement,
        },
      })) as { paymentIntent?: ConfirmResponse; error: Error }
      if (error !== undefined) {
        throw error
      }
      if (paymentIntent !== undefined && 'type' in paymentIntent) {
        throw new Error(paymentIntent.message)
      }
      setCompleted()
    } catch (e) {
      Sentry.captureException(e)
      setError(e.message)
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    if (state.step === 'GET_INTENT' && !state.processing) {
      getIntent()
    } else if (state.step === 'CONFIRM_CHARGE' && !state.processing) {
      confirmCharge()
    }
    // eslint-disable-next-line
  }, [state])

  const resetError = () => dispatch({ type: 'RESET_ERROR' })
  const setError = (e: string) => dispatch({ type: 'SET_ERROR', error: e })
  const setNeedsConfirmation = (data: TPurchaseData) => dispatch({ type: 'NEEDS_CONFIRMATION', data })
  const setCompleted = () => dispatch({ type: 'COMPLETED' })
  const setProcessing = (processing: boolean) => dispatch({ type: 'SET_PROCESSING', processing })

  const getQuote = () => {
    if (state.step === 'INITIATE') {
      dispatch({ type: 'GET_INTENT' })
    }
  }

  const purchase = (cardElement: stripe.elements.Element, stripe: ReactStripeElements.StripeProps) => {
    if (state.step === 'NEEDS_CONFIRMATION') {
      dispatch({ type: 'CONFIRM_CHARGE', cardElement, stripe })
    }
  }

  const reset = () => dispatch({ type: 'RESET_STATE' })

  return {
    step: state.step,
    processing: state.processing,
    error: state.error,
    quote: state.purchaseData,
    getQuote,
    purchase,
    reset,
  }
}
