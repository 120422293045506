import React, { FunctionComponent, useState, useEffect } from 'react'
import { TextField, Typography, Button, Link as MaterialLink, Grid } from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'
import { useRouter } from 'react-router5'

import { useResetPasswordApi, useLoginApi } from '@/api/login'
import { getApiRequestErrorMessage } from '@/api/messages'
import { useFormStyles } from '@/styles/FormStyles'
import { createAfterForgotPasswordLink } from '@/services/branch'
import { RouteName } from '@/router'

interface IPasswordResetFormProps {
  email: string
  token: string
  onLogin: () => Promise<void>
}

const PasswordResetForm: FunctionComponent<IPasswordResetFormProps> = ({ email, token, onLogin }) => {
  const router = useRouter()

  const [password, setPassword] = useState('')
  const [branchUrl, setBranchUrl] = useState<string | null>(null)
  const [{ success: successReset, error: errorReset }, resetPassword] = useResetPasswordApi()
  const [{ token: loginToken, error: errorLogin }, doLogin] = useLoginApi()

  const classes = useFormStyles()

  useEffect(() => {
    if (loginToken === null) {
      return
    }

    const loginCb = async () => {
      await onLogin()
      router.navigate(RouteName.ProjectList)
    }
    loginCb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken])

  useEffect(() => {
    const createBranchUrl = async () => {
      const url = await createAfterForgotPasswordLink('webApp')
      setBranchUrl(url)
    }

    createBranchUrl()
  }, [])

  const handlePassword = (ev: React.ChangeEvent<HTMLInputElement>) => setPassword(ev.target.value)
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    resetPassword({ token, password })
  }
  const handleLoginSubmit = () => {
    doLogin({ email, password })
  }

  return (
    <I18n>
      {({ i18n }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" className={classes.title}>
            <Trans id="user.recovery_reset">Reset password for {email}</Trans>
          </Typography>

          {successReset !== true && (
            <>
              <TextField
                className={classes.textField}
                type="password"
                value={password}
                placeholder={i18n._(t('input.password_placeholder')`Enter your password`)}
                label={i18n._(t('input.password')`Password`)}
                onChange={handlePassword}
                variant="outlined"
                required
                fullWidth
              />

              <Button fullWidth variant="contained" color="primary" type="submit" className={classes.button}>
                <Trans id="user.reset_password">Reset password</Trans>
              </Button>
            </>
          )}

          {successReset === true && (
            <>
              <Typography paragraph>
                <Trans id="passwordReset.resetSuccessfull-text">Your password was successfully changed.</Trans>
              </Typography>

              <Grid item>
                <Button variant="contained" color="primary" onClick={handleLoginSubmit} className={classes.button}>
                  <Trans id="passwordReset.toTheWebApp-text">Go to the Web app</Trans>
                </Button>
              </Grid>

              {branchUrl !== null && (
                <>
                  <Typography paragraph>
                    <Trans id="passwordReset.or-text">or</Trans>
                  </Typography>

                  <Grid item>
                    <MaterialLink href={branchUrl} color="primary">
                      <Trans id="passwordReset.toTheMobileApp-text">Go to the Mobile app</Trans>
                    </MaterialLink>
                  </Grid>
                </>
              )}
            </>
          )}

          {errorReset !== null && (
            <Typography paragraph color="error">
              {getApiRequestErrorMessage(errorReset)}
            </Typography>
          )}

          {errorLogin !== null && (
            <Typography paragraph color="error">
              {getApiRequestErrorMessage(errorLogin)}
            </Typography>
          )}
        </form>
      )}
    </I18n>
  )
}

export default PasswordResetForm
