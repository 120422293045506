// @ts-nocheck

import { IApiRequest } from '@recordset-local/core/api/request'
import { getSessionToken } from '@recordset-local/core/web/storage/session'

export const getApiUrl = (): string => {
  const { REACT_APP_API_PATH } = process.env
  if (REACT_APP_API_PATH === undefined) {
    throw new Error(`REACT_APP_API_PATH env variable is not defined`)
  }

  return `${REACT_APP_API_PATH}`
}

export const callApi = async <T, K, U>(request: (request: IApiRequest<T, K>) => U, requestData?: T, headers?: K) => {
  const sessionToken = getSessionToken()
  const newHeaders = headers === undefined ? ({} as any) : { ...headers }
  if (sessionToken !== null) {
    newHeaders.Authorization = `Bearer ${sessionToken}`
  }
  return request({ apiUrl: getApiUrl(), requestData, headers: newHeaders })
}

export { HttpError } from '@recordset-local/core/api/request'
