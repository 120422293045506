import { I18n as I18nType } from '@lingui/core'
import { selectOrdinal, t } from '@lingui/macro'

import * as Types from '@recordset-local/types/graphql/generatedTypes'

// TODO FUTURE Move to "core package" when it will be converted to standalone package
// with Lingui support
// For now this is copied also in the "mobile" and "offline" packages,
// so if you make the change here, you have to do it also in these other 2 places
export const getFloorNameLocalized = (i18n: I18nType, floor: Pick<Types.FloorDetails, 'level' | 'name'> | null) =>
  floor !== null
    ? floor.name !== null && floor.name.trim() !== ''
      ? floor.name
      : `${
          floor.level === -1
            ? i18n._(t('floorTranslation.basemet-text')`Basement`)
            : `${i18n._(
                selectOrdinal({
                  value: floor.level + 1,
                  one: '1st',
                  two: '2nd',
                  few: '3rd',
                  other: '#th',
                }),
              )} floor`
        }`
    : i18n._(t('floorTranslation.floor-text')`floor`)
