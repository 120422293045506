import React, { FunctionComponent } from 'react'
import { Container, Card, CardActionArea, CardContent, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import { Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { ThemeColor } from '@recordset-local/theme'
import FloorPlan from '@recordset-local/core/web/components/FloorPlan'
import { getTimepointFromTimestamp, formatDate } from '@recordset-local/core/utils'

import { getFloorNameLocalized } from '@/modules/floor'
import { useSelectFloor } from '@/api/queries'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: '100%',
    borderRadius: 0,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  cardContentWrapper: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: theme.spacing(1, 4),
    display: 'flex',
    alignItems: 'center',
  },
  cardContent: {
    backgroundColor: ThemeColor.LIGHT_BACKGROUND,
    padding: theme.spacing(1, 4),
    borderRadius: 10,
  },
  cardActionArea: {
    width: 'auto',
    padding: '6px 0',
    marginRight: theme.spacing(4),
    '&:hover $cardActionAreaHighlight': {
      opacity: 0,
    },
  },
  cardActionAreaHighlight: {},
  cardActions: {
    padding: 0,
  },
  deleteButton: {
    color: ThemeColor.BRAND_NEGATIVE,
    '&:hover': {
      backgroundColor: 'rgba(255, 122, 0, .08)',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  transformWrapper: {
    width: '100%',
  },
  plan: {
    width: '100%',
  },
  floorTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

interface IFloorProps {
  projectId: string
  floorId: string
  activeTimepoint?: DateTime
}

const FLOOR_PLAN_SIZE = { height: window.innerHeight - 142, width: window.innerWidth }
// tslint:disable-next-line:no-big-function
const Floor: FunctionComponent<IFloorProps> = ({ projectId, floorId, activeTimepoint }) => {
  const classes = useStyles()

  const { floor } = useSelectFloor(projectId, floorId, activeTimepoint)

  const renderFloor = (floorDetails: Types.FloorDetails) => {
    return (
      <I18n>
        {({ i18n }) => (
          <Card elevation={0} className={classes.card}>
            {floor !== null && <FloorPlan size={FLOOR_PLAN_SIZE} floor={floor} />}

            <Container maxWidth={false} className={classes.cardContentWrapper}>
              <CardActionArea
                disableRipple
                classes={{
                  root: classes.cardActionArea,
                  focusHighlight: classes.cardActionAreaHighlight,
                }}
              >
                <CardContent classes={{ root: classes.cardContent }}>
                  <Typography variant="h3" className={classes.floorTitle}>
                    {getFloorNameLocalized(i18n, floorDetails)}
                  </Typography>
                  {floorDetails.plan !== null && (
                    <Typography>
                      <span>
                        {/* Test comment for lingui change */}
                        <Trans id="floor.areaAndDate-text">
                          Area: {floorDetails.plan.area}, Date:{' '}
                          {formatDate(getTimepointFromTimestamp(floorDetails.plan.lastEditDate))}
                        </Trans>
                      </span>
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Container>
          </Card>
        )}
      </I18n>
    )
  }

  return floor !== null ? renderFloor(floor) : null
}

export default Floor
