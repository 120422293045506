export enum ENVS {
  DEV = 'develop',
  TEST = 'test',
  PROD = 'production',
}

export const BASEMENT_FLOOR_LEVEL = -1

export const PURCHASE_PROJECT_PATH_PARAM_PROJECTID = 'projectId'
export const PURCHASE_PROJECT_PATH_PARAM_PROJECT_NAME = 'projectName'
export const PURCHASE_PROJECT_PATH_PARAM_TOKEN = 'token'
export const PURCHASE_PROJECT_PATH_PARAM_FEATURE = 'branchFeature'
export const PURCHASE_PROJECT_PATH_PARAM_REDIRECT = 'redirect'
export const PURCHASE_PROJECT_PATH_PARAM_EMAIL = 'email'

export const URLS = {
  PASSWORD_RESET: '/password/reset',
  RECEIPT_WEB: `/receipt/project/:${PURCHASE_PROJECT_PATH_PARAM_PROJECTID}?:${PURCHASE_PROJECT_PATH_PARAM_TOKEN}&:${PURCHASE_PROJECT_PATH_PARAM_EMAIL}`,
  PURCHASE_PROJECT: `/purchase/project/:${PURCHASE_PROJECT_PATH_PARAM_PROJECTID}?:${PURCHASE_PROJECT_PATH_PARAM_TOKEN}&:${PURCHASE_PROJECT_PATH_PARAM_PROJECT_NAME}&:${PURCHASE_PROJECT_PATH_PARAM_FEATURE}&:${PURCHASE_PROJECT_PATH_PARAM_REDIRECT}`,
}

export const STORE_URLS = {
  android: 'https://play.google.com/store/apps/details?id=com.recordset',
  // TODO iOS store URL
  ios: '',
}

export const EXAMPLE_PROJECT_PATH = 'https://www.badros.com/greg/13671gh/index-2.html'
