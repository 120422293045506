// @ts-nocheck

import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Container, Grid, Toolbar, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  projectHeader: {
    position: 'relative',
    zIndex: 1000,
    minHeight: 78,
    padding: theme.spacing(2, 0),
    boxShadow: '0 4px 10px rgba(0, 0, 0, .05)',
    margin: theme.spacing(-4, 0, 5),
    width: '100%',
  },
  projectDetailHeader: {
    marginBottom: 0,
  },
  titleWrapper: {
    flexGrow: 1,
    maxWidth: '50%',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.only('md')]: {
      maxWidth: '35%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      paddingRight: 0,
      maxWidth: 'none',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    width: '100%',
    display: 'inline',
    whiteSpace: 'nowrap',
  },
  actionsWrapper: {
    width: 'auto',
    alignItems: 'center',
  },
}))

interface IProjectHeaderProps {
  title: string
  detailHeader?: boolean
}

const ProjectHeader: FunctionComponent<IProjectHeaderProps> = ({ title, detailHeader, children }) => {
  const classes = useStyles()

  return (
    <Toolbar className={classNames(classes.projectHeader, { [classes.projectDetailHeader]: detailHeader })}>
      <Container maxWidth={false}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item className={classes.titleWrapper}>
            <Typography variant="h2" className={classes.title} data-qa="project-header">
              {title}
            </Typography>
          </Grid>
          <Grid container item className={classes.actionsWrapper}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Toolbar>
  )
}

export default ProjectHeader
