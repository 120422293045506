export * from './dateTime'
export * from './entityId'
export * from './money'
export * from './regexp'

/*
 * Removes null from properties of T
 */
export type RequiredProperty<T> = { [P in keyof T]: NonNullable<T[P]> }

/**
 * Can be used to infer element types from array:
 *
 * const vals = ["1", "2"] as const
 * type valsType = ElementType<typeof vals>
 *
 * "valsType" would be "1" | "2"
 *
 */
export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never

export class ExtendableError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

type NotPresentType = undefined | null | void

export function isPresent<T>(t: T | NotPresentType): t is T {
  return t !== undefined && t !== null
}
