export const SERVER_INTERNAL_ERROR = 'SERVER_INTERNAL_ERROR'
export const SERVER_SESSION_ERROR = 'SERVER_SESSION_ERROR'

export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED'
export const USER_NOT_VERIFIED = 'USER_NOT_VERIFIED'
export const USER_INVALID_CREDENTIALS = 'USER_INVALID_CREDENTIALS'
export const USER_WEAK_PASSWORD = 'USER_WEAK_PASSWORD'
export const USER_DUPLICATE_NAME = 'USER_DUPLICATE_NAME'
export const USER_ACCOUNT_ALREADY_CONFIRMED = 'USER_ACCOUNT_ALREADY_CONFIRMED'
export const USER_ACCOUNT_LOCKED = 'USER_ACCOUNT_LOCKED'
export const USER_CONFIRM_TOO_MANY_REQ = 'USER_CONFIRM_TOO_MANY_REQ'
export const USER_RECORD_NOT_FOUND = 'USER_RECORD_NOT_FOUND'
export const USER_TOO_MANY_REQUESTS = 'USER_TOO_MANY_REQUESTS'
export const USER_REQUIRES_REGISTRATION = 'USER_REQUIRES_REGISTRATION'
export const USER_ACCESS_VIOLATION = 'USER_ACCESS_VIOLATION'
export const USER_INVALID_INPUT = 'USER_INVALID_INPUT'
export const USER_DUPLICATE_PROJECT_NAME = 'USER_DUPLICATE_PROJECT_NAME'
export const VERIFICATION_LINK_EXPIRED = 'VERIFICATION_LINK_EXPIRED'
