// @ts-ignore
import * as t from 'io-ts'
// @ts-ignore
import { UploadUrl, DownloadUrl } from '@recordset-local/types/server/api'

import { request as doRequest, IApiRequest, getHeaders } from '../request'
import { ApiCalls } from '../constants'

/*
 * Provides auth token to allow client apps uploading photos
 */
export const uploadFileRequest = async (request: IApiRequest) =>
  doRequest(t.type({ url: UploadUrl }), request.apiUrl)(ApiCalls.CREATE_FLOOR_PLAN_UPLOAD_URL, {
    method: 'GET',
    headers: getHeaders(request.headers),
  })
/*
 * Provides auth token to allow client apps downloading of floor plans
 */
export const downloadResourceRequest = async (request: IApiRequest<{ projectId: string }, {}>) =>
  doRequest(t.type({ url: DownloadUrl }), request.apiUrl)(ApiCalls.CREATE_PROJECT_RESOURCES_URL, {
    method: 'PUT',
    headers: getHeaders(request.headers),
    body: JSON.stringify(request.requestData),
  })

export * from './resourcePaths'
