import React, { FunctionComponent, useState, useEffect } from 'react'
import { Button, TextField, Typography, Link } from '@material-ui/core'
import { useRouter, useRoute } from 'react-router5'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'

import { RouteName } from '@/router'
import { useForgotPasswordApi } from '@/api/login'
import { getApiRequestErrorMessage } from '@/api/messages'
import { useFormStyles } from '@/styles/FormStyles'

const PasswordForgotForm: FunctionComponent = () => {
  const router = useRouter()

  const {
    route: {
      params: { email: routeEmail },
    },
  } = useRoute()

  const [initialEmail] = useState(routeEmail)
  const [email, setEmail] = useState(routeEmail)
  const [{ error, success }, request] = useForgotPasswordApi()
  const classes = useFormStyles()

  useEffect(() => {
    if (success === true) {
      setEmail('')
    }
  }, [success])

  const handleEmail = (ev: React.ChangeEvent<HTMLInputElement>) => setEmail(ev.target.value.trim().toLowerCase())
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    request(email)
  }

  const onGoHome = () => {
    router.navigate(RouteName.Login, email !== undefined && email.trim() !== '' ? { email } : { email: initialEmail })
  }

  return (
    <I18n>
      {({ i18n }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" className={classes.title}>
            <Trans id="user.forgot_password">Forgot password or don't have one yet?</Trans>
          </Typography>

          <TextField
            className={classes.textField}
            type="email"
            value={email}
            placeholder={i18n._(t('input.email_placeholder')`Enter your email`)}
            label={i18n._(t('input.email')`Email`)}
            onChange={handleEmail}
            variant="outlined"
            inputProps={{
              autoCapitalize: 'none',
            }}
            autoCapitalize="none"
            required
            fullWidth
            autoFocus
          />

          <Button fullWidth variant="contained" color="primary" type="submit" className={classes.button}>
            <Trans id="user.send_recovery_email">Send recovery email</Trans>
          </Button>

          <Link color="primary" onClick={onGoHome} className={classes.link}>
            <Trans id="passwordForgotForm.backToLogin-btn">Back to login</Trans>
          </Link>

          {success === true ? (
            <Typography paragraph>
              <Trans id="user.recovery_check">Message with password reset link was sent to your email</Trans>
            </Typography>
          ) : null}
          {error !== null ? (
            <Typography paragraph color="error">
              {getApiRequestErrorMessage(error)}
            </Typography>
          ) : null}
        </form>
      )}
    </I18n>
  )
}

export default PasswordForgotForm
