import React, { FunctionComponent, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Link,
  Button,
  DialogContentText,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { DataProxy } from 'apollo-cache'
import * as Sentry from '@sentry/browser'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import AcceptProject from '@recordset-local/types/graphql/AcceptProject.graphql'
import DeclineProject from '@recordset-local/types/graphql/DeclineProject.graphql'
import GetPendingInvitations from '@recordset-local/types/graphql/PendingInvitations.graphql'
import { ThemeColor } from '@recordset-local/theme'

import { useGetPendingInvitations } from '@/api/queries'
import noImage from '@/assets/no-image.png'
import ProgressBar from './ProgressBar'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    fontSize: '0.875rem',
  },
  imageWrapper: {
    height: '70%',
    textAlign: 'center',
  },
  image: {
    height: '100%',
    border: `1px solid ${ThemeColor.TEXT_GRAY}`,
    borderRadius: 20,
  },
  buttons: {
    position: 'relative',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  declineButton: {
    color: ThemeColor.BRAND_NEGATIVE,
    '&:hover': {
      backgroundColor: 'rgba(255, 122, 0, .08)',
    },
  },
}))

const getCachedInvitations = (cache: DataProxy) => {
  const data = cache.readQuery<Types.PendingInvitations>(
    {
      query: GetPendingInvitations,
    },
    true,
  )
  if (data !== null) {
    return data.view.pendingInvitations.invitations
  }
  return null
}

const updateInvitation = () => (
  cache: DataProxy,
  { data }: { data?: Types.AcceptProject | Types.DeclineProject | null },
) => {
  if (data === undefined || data === null) {
    return
  }

  const payload: { ok: boolean; invitationId: string | null; acceptAction?: boolean } =
    'acceptProject' in data.view
      ? { ...data.view.acceptProject, acceptAction: true }
      : 'declineProject' in data.view
      ? data.view.declineProject
      : { ok: false, invitationId: '' }

  if (!payload.ok) {
    // TODO: notifications
    return
  }

  const cached = getCachedInvitations(cache)
  if (cached !== null) {
    const updated = {
      view: {
        __typename: 'RootMutationView',
        pendingInvitations: {
          __typename: 'PendingInvitationsPayload',
          invitations: cached.filter((i) => i.id !== payload.invitationId),
        },
      },
    }
    cache.writeQuery({
      query: GetPendingInvitations,
      data: updated,
    })
  }
}

interface IProjectInvitationDialogProps {
  onClose: () => void
}

const ProjectInvitationDialog: FunctionComponent<IProjectInvitationDialogProps> = ({ onClose }) => {
  const [acceptProject] = useMutation<Types.AcceptProject>(AcceptProject, { update: updateInvitation })
  const [declineProject] = useMutation<Types.DeclineProject>(DeclineProject, { update: updateInvitation })
  const { invitations, loading } = useGetPendingInvitations()
  const [currentInvitation, setCurrentInvitation] = useState(0)
  const [actionError, setActionError] = useState<Error | null>(null)
  const classes = useStyles()

  const renderInvitation = () => {
    const invitation = invitations[currentInvitation]
    if (invitation !== undefined) {
      return (
        <>
          <DialogContentText>
            <Typography paragraph className={classes.text}>
              from
              <br />
              <Link href={`mailto:${invitation.invitedBy.email}`} color="primary">
                {invitation.invitedBy.email}
              </Link>
            </Typography>
            <Typography variant="h2">{invitation.projectName}</Typography>
          </DialogContentText>
          <Box className={classes.imageWrapper}>
            <img alt="" src={noImage} className={classes.image} />
          </Box>
        </>
      )
    }
    return null
  }

  const advance = () => {
    if (currentInvitation + 1 < invitations.length) {
      setCurrentInvitation(currentInvitation + 1)
    } else {
      onClose()
    }
  }

  const handleAccept = async () => {
    const invitationId = invitations[currentInvitation].id
    try {
      const variables: Types.AcceptProjectVariables = {
        input: { invitationId },
      }
      await acceptProject({ variables })
      advance()
    } catch (e) {
      Sentry.captureException(e)
      setActionError(e)
    }
  }

  const handleDecline = async () => {
    const invitationId = invitations[currentInvitation].id
    try {
      const variables: Types.DeclineProjectVariables = {
        input: { invitationId },
      }
      await declineProject({ variables })
      advance()
    } catch (e) {
      Sentry.captureException(e)
      setActionError(e)
    }
  }

  // TODO Translations
  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs">
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        New invitations {currentInvitation + 1}/{invitations.length}
      </DialogTitle>
      <DialogContent dividers>
        {loading ? <ProgressBar /> : renderInvitation()}
        {actionError !== null ? <DialogContentText>{actionError.message}</DialogContentText> : null}
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button size="small" onClick={advance}>
          Later
        </Button>
        <Button size="small" onClick={handleDecline} className={classes.declineButton}>
          Dismiss
        </Button>
        <Button size="small" onClick={handleAccept} color="primary">
          Join
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectInvitationDialog
