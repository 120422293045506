import { setupI18n } from '@lingui/core'

// NOTE: i18n is supported but l10n is not a requirement for the project,
// here we simply load hardcoded en locale
import * as enLocale from '@/assets/locales/en/messages'

export const i18n = setupI18n({
  language: 'en',
  catalogs: {
    en: enLocale,
  },
})

export const catalogs = {
  en: enLocale,
}
