import React, { FunctionComponent } from 'react'
import { Link } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Trans } from '@lingui/macro'

import { buttonStyles } from '@recordset-local/theme'
import { STORE_URLS } from '@recordset-local/core/constants'

import googlePlayIcon from '@/assets/googlePlayIcon.svg'
import appleIcon from '@/assets/appleIcon.svg'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    width: 260,
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  text: {
    display: 'block',
    padding: theme.spacing(0, 1),
  },
  name: {
    display: 'block',
    fontSize: '1.5rem',
    textTransform: 'none',
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(6),
  },
  linkButton: {
    display: 'flex',
    justifyItems: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    borderRadius: buttonStyles.borderRadius,
    padding: theme.spacing(2, 2),
    backgroundColor: theme.palette.secondary.main,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    width: 260,
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
}))

interface IStoreButtonProps {
  platform: string
}

const StoreButton: FunctionComponent<IStoreButtonProps> = ({ platform }) => {
  const classes = useStyles()

  return (
    <Link
      href={platform === 'android' ? STORE_URLS.android : STORE_URLS.ios}
      underline="none"
      className={classes.linkButton}
    >
      {platform === 'android' && (
        <>
          <img src={googlePlayIcon} alt="" className={classes.icon} />
          <span className={classes.text}>
            <Trans id="storeDownload.androidAppOn-text">Android app on</Trans>
            <span className={classes.name}>
              <Trans id="storeDownload.googlePlay-text">Google Play</Trans>
            </span>
          </span>
        </>
      )}

      {platform === 'ios' && (
        <>
          <img src={appleIcon} alt="" className={classes.icon} />
          <span className={classes.text}>
            <Trans id="storeDownload.iosAppOn-text">iOS app on</Trans>
            <span className={classes.name}>
              <Trans id="storeDownload.appStore-text">App Store</Trans>
            </span>
          </span>
        </>
      )}
    </Link>
  )
}

export default StoreButton
