// @ts-nocheck

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { DownloadCameraImage } from '@recordset-local/core/api/requests/resources'

// get scale and offset to fit and center image inside Conva Stage
export const calculateImageParams = (
  image: HTMLImageElement,
  viewportSize: {
    width: number
    height: number
  },
) => {
  // This assumes that the viewport is always a rectangle.
  // We just upscale the image to fit the height and reposition it to be centered horizontally.
  const scale = viewportSize.height / image.height
  const offset = { x: (viewportSize.width / scale - image.width) / 2, y: 0 }

  return { scale, offset, imageWidth: image.width * scale, imageHeight: viewportSize.height }
}

type CameraImage = Omit<DownloadCameraImage, 'userId' | 'projectId'>

export const cameraImageDataDiffers = (data1: CameraImage, data2: CameraImage) => {
  return (
    (data1 !== null &&
      data2 !== null &&
      (data1.floorId !== data2.floorId ||
        data1.cameraId !== data2.cameraId ||
        data1.timepoint !== data2.timepoint ||
        data1.direction !== data2.direction ||
        data1.imageName !== data2.imageName)) ||
    (data1 === null && data2 !== null) ||
    (data1 !== null && data2 === null)
  )
}

// TODO Add Lingui support (web folder needs to be moved to separate package outside of "core")
export const imageDirectionToLocaleString = (direction: Types.ImageDirection): string => {
  switch (direction) {
    case Types.ImageDirection.E:
      return 'East'
    case Types.ImageDirection.N:
      return 'North'
    case Types.ImageDirection.NE:
      return 'North East'
    case Types.ImageDirection.NW:
      return 'North West'
    case Types.ImageDirection.S:
      return 'South'
    case Types.ImageDirection.SE:
      return 'South East'
    case Types.ImageDirection.SW:
      return 'South West'
    case Types.ImageDirection.W:
      return 'West'
    case Types.ImageDirection.DE:
      return 'East Diagonal'
    case Types.ImageDirection.DN:
      return 'North Diagonal'
    case Types.ImageDirection.DNE:
      return 'North East Diagonal'
    case Types.ImageDirection.DNW:
      return 'North West Diagonal'
    case Types.ImageDirection.DS:
      return 'South Diagonal'
    case Types.ImageDirection.DSE:
      return 'South East Diagonal'
    case Types.ImageDirection.DSW:
      return 'South West Diagonal'
    case Types.ImageDirection.DW:
      return 'West Diagonal'
    case Types.ImageDirection.UP:
      return 'Up'
    default:
      return ''
  }
}
