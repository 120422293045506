import React, { FunctionComponent, useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Grid,
  Tabs,
  Tab,
  Toolbar,
  Link,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from '@material-ui/core'
import { makeStyles, Theme, MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles'
import { AccountCircle } from '@material-ui/icons'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import * as branchSdk from 'branch-sdk'
import { useApolloClient } from '@apollo/react-hooks'

import { BranchLinkFeatures } from '@recordset-local/core/services/branch'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import { isRegisteredUser, isLinkUser, isDemoLinkUser, LinkUser } from '@recordset-local/core/utils/login'
import { removeSessionToken, setSessionToken } from '@recordset-local/core/web/storage/session'
import { branchDemoLoginRequest } from '@recordset-local/core/api/requests/login'
import { callApi } from '@recordset-local/core/web/request'

import { init, setIdentity, logout, getBranchKey } from '@/services/branch'
import { useLogoutApi } from '@/api/login'
import { RouteName, ROUTE_SEPARATOR } from '@/router'
import LoginForm from '@/components/LoginForm'
import PasswordForgotForm from '@/components/PasswordForgotForm'
import PasswordResetForm from '@/components/PasswordResetForm'
import ConfirmAccountForm from '@/components/ConfirmAccountForm'
import AcceptProjectInvitationForm from '@/components/AcceptProjectInvitationForm'
import InstallApp from '@/components/InstallApp'
import ProjectPreview from '@/components/ProjectPreview'
import PreviewWrapper from '@/components/PreviewWrapper'
import ProgressBar from '@/components/ProgressBar'
import User from '@/pages/User'
import Projects from '@/pages/Projects'
import PurchaseProject from '@/pages/PurchaseProject'
import { Theme as mainTheme } from '@/styles/Theme'
import mainImage from '@/assets/header_bg.png'
import logoSvg from '@/assets/logo.svg'

// tslint:disable:no-duplicate-string
const useStyles = makeStyles((theme: Theme) => ({
  appBarWrapper: {
    paddingBottom: theme.spacing(4),
  },
  logo: {
    height: '60px',
  },
  logoToolbar: {
    height: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  projectDetailPage: {
    height: '100vh',
    overflow: 'hidden',
  },
  rootUnauthorized: {
    minHeight: '100vh',
    padding: 0,
  },
  titleUnauthorizedWrapper: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      position: 'relative',
      boxShadow: '0 0 14px rgba(0, 0, 0, .1)',
      '&::before': {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        content: '""',
        background: `url(${mainImage}) center center no-repeat`,
        backgroundSize: 'cover',
        opacity: '.5',
        zIndex: -1,
      },
    },
  },
  titleUnauthorized: {
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '2.5rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  loginLink: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  loginIcon: {
    marginLeft: theme.spacing(2),
  },
  formUnauthorizedWrapper: {
    height: '65%',
  },
  form: {
    maxWidth: 450,
    margin: theme.spacing(0, 6),
  },
  tabs: {
    marginBottom: theme.spacing(6),
  },
  tab: {
    fontSize: '1.5rem',
  },
  userName: {
    marginRight: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const extractTopRoute = (route: string) => {
  return route.split(ROUTE_SEPARATOR, 1)[0] as RouteName | undefined
}

const WrappedForm: FunctionComponent = ({ children }) => {
  const classes = useStyles()

  const getContentPanel = () => {
    return (
      <Box p={2} textAlign="center">
        <img className={classes.logo} src={logoSvg} alt="Elephants never forget" />
        <Typography variant="subtitle1">
          <Trans id="header.title">Never Forget</Trans>
        </Typography>
      </Box>
    )
  }

  const getFormWrapper = (left: JSX.Element, right: JSX.Element) => (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid item container xs={12} alignItems="center" className={classes.rootUnauthorized}>
          <Grid
            item
            container
            xs={12}
            md={7}
            justify="center"
            alignItems="center"
            className={classes.titleUnauthorizedWrapper}
          >
            <Box>{left}</Box>
          </Grid>
          <Grid item container xs={12} md={5} justify="center" className={classes.formUnauthorizedWrapper}>
            <Box className={classes.form}>{right}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )

  return getFormWrapper(getContentPanel(), <>{children}</>)
}

// tslint:disable:no-big-function
const App: FunctionComponent = () => {
  const client = useApolloClient()
  const [branchData, setBranchData] = useState<branchSdk.InitData | null>(null)
  const [branchMatchId, setBranchMatchId] = useState<string | null>(null)
  const [demoProjectId, setDemoProjectId] = useState<string | null>(null)
  const [demoError, setDemoError] = useState(false)
  const [demoLoading, setDemoLoading] = useState(false)
  const { route, router } = useRoute()
  const { user, refetch: refetchUser, loading: userLoading } = useCurrentAuthGQLUser()
  const logoutUser = useLogoutApi()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [topRoute, setTopRoute] = useState<RouteName | undefined>(extractTopRoute(route.name))
  const classes = useStyles()

  const openUserMenu = (ev: React.MouseEvent<HTMLElement>) => setAnchorEl(ev.currentTarget)
  const closeUserMenu = () => setAnchorEl(null)
  const goToUserPage = () => {
    closeUserMenu()
    router.navigate(RouteName.User)
  }

  const adjustRoute = () => {
    if (route.name === RouteName.PurchaseProject) {
      // project purchase uses its own token to identify users
      return null
    }

    if (
      user === null &&
      ![
        RouteName.Login,
        RouteName.AccountConfirm,
        RouteName.PasswordForgot,
        RouteName.PasswordReset,
        RouteName.ProjectView,
        RouteName.GetMobileApp,
        RouteName.ReceiptWebAccess,
      ].includes(route.name as RouteName)
    ) {
      return { route: RouteName.Login }
    }

    if (
      isLinkUser(user) &&
      ![
        RouteName.Login,
        RouteName.AccountConfirm,
        RouteName.PasswordForgot,
        RouteName.PasswordReset,
        RouteName.ProjectView,
        RouteName.Home,
        RouteName.ProjectDetails,
        RouteName.GetMobileApp,
      ].includes(route.name as RouteName)
    ) {
      return { route: RouteName.Home }
    }

    if (
      isRegisteredUser(user) &&
      (route.name === RouteName.Login ||
        route.name === RouteName.AccountConfirm ||
        route.name === RouteName.PasswordReset)
    ) {
      return { route: RouteName.Home }
    }

    if (isRegisteredUser(user) && route.name === RouteName.ReceiptWebAccess) {
      if (user.email === route.params.email) {
        return {
          route: RouteName.ProjectDetails,
          params: {
            projectId: route.params.projectId,
          },
        }
      } else {
        return { route: RouteName.Home }
      }
    }

    return null
  }

  // get branch data from URL
  useEffect(() => {
    const callBranch = async () => {
      try {
        const branchKey = getBranchKey()
        if (branchKey !== undefined) {
          const data = await init(branchKey)
          setBranchData(data)
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    if (branchData === null) {
      // save ID before we start navigating, it will be used to track unique clicks
      const matchId = route.params._branch_match_id
      if (matchId !== undefined) {
        setBranchMatchId(matchId)
      }
      callBranch()
    }
    // eslint-disable-next-line
  }, [])

  // deferred initial routing to take branch.io data into account
  useEffect(() => {
    if (userLoading || branchData === null) {
      return
    }

    // make sure we don't carry leftover LinkUser token, or demo project ID if there is one left
    if (isRegisteredUser(user)) {
      removeSessionToken()
    }

    const feature = branchData.data_parsed['~feature']
    if (feature === BranchLinkFeatures.SHARE_PROJECT) {
      router.navigate(RouteName.ProjectView)
    } else if (feature === BranchLinkFeatures.DEMO_PROJECT) {
      const projectId = branchData.data_parsed.project_id
      if (projectId !== undefined && demoProjectId !== projectId) {
        setDemoProjectId(projectId)
        setDemoLoading(true)
      } else if (
        branchData.data_parsed['~feature'] === BranchLinkFeatures.FORGOT_PASSWORD_RESET ||
        branchData.data_parsed['~feature'] === BranchLinkFeatures.INSTALL_APP ||
        branchData.data_parsed['~feature'] === BranchLinkFeatures.SHARE_APP
      ) {
        router.navigate(RouteName.GetMobileApp)
      }
    } else {
      const redirect = adjustRoute()
      if (redirect !== null) {
        router.navigate(redirect.route, redirect.params !== undefined ? redirect.params : {})
      }
    }
    // eslint-disable-next-line
  }, [branchData, user, userLoading])

  useEffect(() => {
    const demoLogin = async (projectId: string) => {
      try {
        const { token } = await callApi(branchDemoLoginRequest, {
          branchId: branchMatchId !== null ? branchMatchId : undefined,
          projectId,
        })
        setSessionToken(token)
        await client.resetStore()
        refetchUser(() => {
          setDemoLoading(false)
          router.navigate(RouteName.ProjectDetails, { projectId })
        })
      } catch (e) {
        setDemoLoading(false)
        setDemoError(true)
        removeSessionToken()
        setDemoProjectId(null)
        Sentry.captureException(e)
      }
    }

    if (demoProjectId !== null) {
      setDemoError(false)
      if (!isDemoLinkUser(user) || user.projectId !== demoProjectId) {
        demoLogin(demoProjectId)
      } else {
        setDemoLoading(false)
        router.navigate(RouteName.ProjectDetails, { projectId: demoProjectId })
      }
    }
    // eslint-disable-next-line
  }, [demoProjectId, user])

  useEffect(() => {
    try {
      if (branchData !== null) {
        isRegisteredUser(user) ? setIdentity(`${user.email !== '' ? 'user' : 'device'}:${user.id}`) : logout()
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    const newTopRoute = extractTopRoute(route.name)
    if (newTopRoute !== topRoute) {
      const redirect = adjustRoute()
      if (redirect !== null) {
        router.navigate(redirect.route, redirect.params !== undefined ? route.params : {})
      } else {
        setTopRoute(newTopRoute)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  const getLoginTabs = () => (
    <I18n>
      {({ i18n }) => (
        <Tabs value={topRoute} indicatorColor="primary" textColor="primary" className={classes.tabs}>
          <Tab
            label={i18n._(t('user.login_tab')`Login`)}
            value={RouteName.Login}
            component="a"
            onClick={() => router.navigate(RouteName.Login)}
            className={classes.tab}
          />
        </Tabs>
      )}
    </I18n>
  )

  const getRegUserContent = () => {
    switch (topRoute) {
      case RouteName.Home:
      case RouteName.ProjectList:
      case RouteName.ProjectDetails:
        return <Projects />
      case RouteName.Login:
      case RouteName.User:
        return <User afterLogout={refetchUser} />
    }

    return getNotFoundPage()
  }

  const getUnauthForm = () => {
    switch (topRoute) {
      case RouteName.Login:
        return (
          <>
            {getLoginTabs()}
            <LoginForm onLogin={refetchUser} />
          </>
        )
      case RouteName.PasswordForgot:
        return <PasswordForgotForm />
      case RouteName.PasswordReset: {
        const { email, token } = route.params
        if (email !== undefined && token !== undefined) {
          return <PasswordResetForm email={email} token={token} onLogin={refetchUser} />
        }
        break
      }
      case RouteName.ReceiptWebAccess: {
        const { email, token, projectId } = route.params
        if (email !== undefined && token !== undefined) {
          return (
            <ConfirmAccountForm
              email={decodeURIComponent(email)}
              token={token}
              projectId={projectId}
              onLogin={refetchUser}
            />
          )
        }
        break
      }
    }
    return null
  }

  const getLinkUserContent = (user: LinkUser) => {
    if (topRoute === RouteName.ProjectDetails) {
      return <ProjectPreview projectId={user.projectId} userId={user !== null ? user.id : ''} />
    }

    const form = getUnauthForm()
    if (form !== null) {
      return <WrappedForm>{form}</WrappedForm>
    }

    return branchData !== null ? (
      <Container maxWidth="xl">
        <InstallApp branchData={branchData} allowGoToWeb />
      </Container>
    ) : (
      <WrappedForm>{getNotFoundPage()}</WrappedForm>
    )
  }

  const getUnauthContent = () => {
    const form = getUnauthForm()
    if (form !== null) {
      return <WrappedForm>{form}</WrappedForm>
    } else {
      return <WrappedForm>{getNotFoundPage()}</WrappedForm>
    }
  }

  const getNotFoundPage = () => {
    return <h2>404</h2>
  }

  const getAppBar = () => {
    if (
      topRoute === undefined ||
      // RouteName.Registero be included in the array if register will be allowed
      [RouteName.Login, RouteName.PasswordForgot, RouteName.PurchaseProject].includes(topRoute)
    ) {
      return null
    }

    return (
      <Grid item xs={12} className={classes.appBarWrapper}>
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <Box className={classes.titleWrapper}>
              <img
                aria-label="logo"
                onClick={() => router.navigate(RouteName.Home)}
                className={classes.logoToolbar}
                src={logoSvg}
                alt="RecordSet, elephants never forget"
              />
            </Box>
            {getUserMenu()}
          </Toolbar>
        </AppBar>
      </Grid>
    )
  }

  const getContent = () => {
    // wait for everything to load before deciding on what to display
    // due to delayed calls to demo project logins, user content can flash
    if (userLoading || branchData === null || demoLoading) {
      return <ProgressBar />
    }
    if (demoError) {
      // could not load demo project (most likely non-existent demo project ID)
      // TODO: better error styling
      return (
        <Grid container direction="column" alignItems="center" justify="center">
          <Typography variant="h2">
            <Trans id="demo.notFound">We could not find the demo project you have asked for.</Trans>
          </Typography>
        </Grid>
      )
    }
    // an account can be verified with or without an active session
    if (topRoute === RouteName.AccountConfirm) {
      const { email, token } = route.params
      if (email !== undefined && token !== undefined) {
        return <ConfirmAccountForm email={decodeURIComponent(email)} token={token} onLogin={refetchUser} />
      }
    }

    // branch links can be used to view projects without a login
    if (topRoute === RouteName.ProjectView) {
      return <AcceptProjectInvitationForm branchData={branchData} branchMatchId={branchMatchId} />
    }

    // branch links can be used to view projects without a login
    if (topRoute === RouteName.GetMobileApp) {
      return (
        <Container maxWidth="xl">
          <InstallApp branchData={branchData} allowGoToWeb />
        </Container>
      )
    }

    // project purchases use JWT supplied by mobile app
    if (topRoute === RouteName.PurchaseProject) {
      const { token, projectId, projectName, redirect, branchFeature } = route.params
      if (token !== undefined && projectId !== undefined) {
        return (
          <PurchaseProject
            token={token}
            projectId={projectId}
            projectName={decodeURIComponent(projectName)}
            redirectUrl={redirect}
            branchFeature={branchFeature}
          />
        )
      }
    }

    if (isRegisteredUser(user) || isDemoLinkUser(user)) {
      return getRegUserContent()
    }
    if (isLinkUser(user)) {
      return getLinkUserContent(user)
    }
    return getUnauthContent()
  }

  const getUserMenu = () => {
    if (userLoading || branchData === null || demoLoading) {
      return null
    }

    if (isRegisteredUser(user) && !demoError) {
      const handleLogout = () => {
        closeUserMenu()
        logoutUser(refetchUser)
      }

      return (
        <I18n>
          {({ i18n }) => (
            <>
              <Typography className={classes.userName}>{user.name}</Typography>
              <IconButton
                aria-label={i18n._(t('user.account')`User account`)}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openUserMenu}
                color="inherit"
              >
                <AccountCircle fontSize="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={anchorEl !== null}
                onClose={closeUserMenu}
              >
                <MenuItem onClick={goToUserPage}>
                  <Trans id="user.profile">Profile</Trans>
                </MenuItem>
                <Divider variant="fullWidth" />
                <MenuItem onClick={handleLogout}>
                  <Typography color="secondary">
                    <Trans id="user.logout">Logout</Trans>
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </I18n>
      )
    }

    if (isDemoLinkUser(user) || demoError) {
      const exitDemo = () => {
        removeSessionToken()
        window.location.href = '/'
      }

      return (
        <Link onClick={exitDemo} className={classes.loginLink}>
          <Trans id="demo.exit">Exit demo project</Trans>
          <AccountCircle fontSize="large" color="primary" className={classes.loginIcon} />
        </Link>
      )
    }

    // LinkUsers will see PreviewWrapper with it is own menu
    if (user === null || (isLinkUser(user) && topRoute !== RouteName.ProjectDetails)) {
      return (
        <Link onClick={() => router.navigate(RouteName.Login)} className={classes.loginLink}>
          <Trans id="invitation.login">Do you have an account? Please login</Trans>
          <AccountCircle fontSize="large" color="primary" className={classes.loginIcon} />
        </Link>
      )
    }
    return null
  }

  const mainLayout = (
    <Grid
      container
      alignContent="flex-start"
      className={topRoute === RouteName.ProjectDetails ? classes.projectDetailPage : ''}
    >
      {getAppBar()}
      <Grid item xs={12}>
        {getContent()}
      </Grid>
    </Grid>
  )

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {isLinkUser(user) && !isDemoLinkUser(user) && topRoute === RouteName.ProjectDetails ? (
        <PreviewWrapper>{mainLayout}</PreviewWrapper>
      ) : (
        mainLayout
      )}
    </ThemeProvider>
  )
}

export default App
