// @ts-ignore
import * as t from 'io-ts'
// @ts-ignore
import { PathReporter } from 'io-ts/lib/PathReporter'
// @ts-ignore
import { fold } from 'fp-ts/lib/Either'

import { ExtendableError } from '@recordset-local/core/utils'

import {
  ApiUserError,
  ApiServerError,
  isApiUserErrorDescriptor,
  isApiServerErrorDescriptor,
  isApiUserError,
  isApiServerError,
} from './errors'

export class HttpError extends ExtendableError {
  constructor(message: string, public readonly code: number) {
    super(message)
  }
}

export interface IApiRequest<T = undefined, K = undefined> {
  apiUrl: string
  requestData?: T
  headers?: K
}

const status = t.type({ ok: t.boolean })
const error = t.partial({ message: t.string, code: t.string })

type Raw<T> = T extends t.Type<infer R> ? R : T
type Status = t.TypeOf<typeof status> & t.TypeOf<typeof error>

export const getHeaders = (apiRequestHeaders: any) => {
  const headers: any = apiRequestHeaders !== undefined ? apiRequestHeaders : {}
  headers['Content-Type'] = 'application/json'
  return headers
}

export const decode = <T>(type: t.Type<T>, data: any): T => {
  const result = type.decode(data)
  return fold(
    () => {
      throw new Error(`Request failed with invalid payload, ${PathReporter.report(result).join('. ')}`)
    },
    (value: T) => value,
  )(result)
}

export const request = <T, U extends Raw<T>>(responseType: T, apiUrl: string) => async (
  ...args: Parameters<typeof fetch>
): Promise<U> => {
  try {
    const requestPath = args[0]
    const requestUrl = `${apiUrl}${requestPath}`

    const options: RequestInit = {
      credentials: 'same-origin',
      ...args[1],
    }

    const result = await fetch(requestUrl, options)
    if (responseType instanceof t.AnyType) {
      return result as U
    }

    if (result.ok) {
      const data = await result.json()
      let decoded = null
      try {
        const responseProps = ((responseType as unknown) as t.TypeC<t.Props>).props
        decoded = decode(t.intersection([t.partial(responseProps), status, error]), data) as Status
        if (decoded.ok) {
          return decoded as U
        }
      } catch (e) {
        throw new Error(`Cannot decode response data, ${e.message}`)
      }
      if (decoded !== null && !decoded.ok) {
        if (isApiServerErrorDescriptor(decoded)) {
          throw new ApiServerError(decoded.message, null, decoded.code)
        }
        if (isApiUserErrorDescriptor(decoded)) {
          throw new ApiUserError(decoded.message, decoded.code)
        }
        throw new Error(`API error, ${decoded.message}`)
      }
    }
    throw new HttpError(`Request failed with ${result.status}:${result.statusText}`, result.status)
  } catch (e) {
    if (e instanceof HttpError || isApiServerError(e) || isApiUserError(e)) {
      throw e
    }
    throw new Error(`Request failed: ${e.message}`)
  }
}
