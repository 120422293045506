import { makeStyles } from '@material-ui/core'
import { ThemeColor, inputStyles } from '@recordset-local/theme'

const stripeInvalidColor = '#eb1c26'
const labelTransform = 'translate(14px, -8px) scale(0.75)'

export const useStripeStyles = makeStyles(() => ({
  base: {
    ...inputStyles,
    width: '100%',
    border: `1px solid ${ThemeColor.TEXT_GRAY}`,
    padding: '18px 14px',
    fontSize: '1rem',
  },
  empty: {
    color: ThemeColor.TEXT_GRAY,
    borderColor: ThemeColor.TEXT_GRAY,
    '&:not($focus) + label': {
      color: ThemeColor.TEXT_GRAY,
    },
  },
  focus: {
    '&:not($invalid)': {
      color: ThemeColor.BLACK,
      borderColor: ThemeColor.BRAND_PRIMARY,
      '& + label': {
        transform: labelTransform,
        color: ThemeColor.BRAND_PRIMARY,
      },
    },
  },
  invalid: {
    color: stripeInvalidColor,
    borderColor: stripeInvalidColor,
    '& + label': {
      transform: labelTransform,
      color: stripeInvalidColor,
    },
  },
  complete: {
    color: ThemeColor.BRAND_PRIMARY,
    borderColor: ThemeColor.BRAND_PRIMARY,
    '& + label': {
      transform: labelTransform,
      color: ThemeColor.BRAND_PRIMARY,
    },
  },
}))
