import React, { FunctionComponent, useState, useEffect } from 'react'
import { useRouter } from 'react-router5'
import { DateTime } from 'luxon'
import {
  Link,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableFooter,
  Typography,
  IconButton,
  Container,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import GroupAddIcon from '@material-ui/icons/GroupAdd'

import { useDebounce } from '@recordset-local/core/hooks/debounce'
import { formatDate } from '@recordset-local/core/utils'
import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { ThemeColor } from '@recordset-local/theme'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import ProjectHeader from '@recordset-local/core/web/components/ProjectHeader'
import { isPaid } from '@recordset-local/core/modules/project'

import { RouteName, projectFieldToRouterParam, sortOrderToRouterParam } from '@/router'
import { useConfig } from '@/ConfigContext'
import { useProjectList, useGetPendingInvitations } from '@/api/queries'

import ProgressBar from './ProgressBar'
import PendingInvitations from './PendingInvitations'
import ProjectInvitationDialog from './ProjectInvitationDialog'
import SearchInput from './SearchInput'
import ProjectShareForm from './ProjectShareForm'

type ProjectViewType = Types.ProjectList_view_projects

interface IProjectListProps {
  projects: ProjectViewType[]
  navigateBack: (cursor: string) => void
  navigateForward: (cursor: string) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    textAlign: 'right',
  },
  tableWrapper: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      boxShadow: `inset ${theme.spacing(-5, 0, 3, -3)} rgba(0,0,0,.1)`,
    },
  },
  tableHeadText: {
    opacity: 0.42,
  },
  noProjects: {
    height: '30vh',
  },
  sharingButton: {
    // alignment - IconButton is circle with padding
    margin: '-12px -12px -12px 0',
  },
  mainButton: {
    margin: theme.spacing(5, 0, 4),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  newProject: {
    position: 'relative',
    '&::before': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: -13,
      transform: 'translateY(-50%)',
      content: '""',
      width: 6,
      height: 6,
      background: ThemeColor.BRAND_PRIMARY,
      borderRadius: 3,
    },
  },
}))

type FormatProjectData = {
  id: string
  name: string
  owner: {
    email: string
  }
  userCreatedDate: string
  userLastEditDate: string
}

const formatProjectRow = (
  project: FormatProjectData,
  highlight: boolean,
  classes: ReturnType<typeof useStyles>,
  handleClick: (id: string) => () => void,
  actions?: JSX.Element,
) => (
  <TableRow key={project.id} data-qa="project">
    <TableCell>
      <Link className={highlight ? classes.newProject : ''} color="primary" onClick={handleClick(project.id)}>
        {project.name}
      </Link>
    </TableCell>
    <TableCell>{project.owner.email}</TableCell>
    <TableCell align="right" data-qa="userCreatedDate">
      {formatDate(DateTime.fromISO(project.userCreatedDate))}
    </TableCell>
    <TableCell align="right" data-qa="userLastEditDate">
      {DateTime.fromISO(project.userLastEditDate).toRelative()}
    </TableCell>
    <TableCell align="right">{actions}</TableCell>
  </TableRow>
)

const ProjectList: FunctionComponent<IProjectListProps> = ({ projects, navigateBack, navigateForward }) => {
  const { user } = useCurrentAuthGQLUser()
  const router = useRouter()
  const classes = useStyles()
  const [shareProject, setShareProject] = useState<ProjectViewType | null>(null)

  const openProjectShare = (project: ProjectViewType) => () => {
    setShareProject(project)
  }

  const closeProjectShare = () => {
    setShareProject(null)
  }

  const handleNavBack = (cursor: string) => () => {
    navigateBack(cursor)
  }
  const handleNavForward = (cursor: string) => () => {
    navigateForward(cursor)
  }
  const handleClick = (projectId: string) => () => {
    router.navigate(RouteName.ProjectDetails, { projectId })
  }

  if (projects.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={5} className={classes.noProjects}>
            You don't have any paid projects yet
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  const first = projects[0]
  const last = projects.slice(-1)[0]

  const isForeign = (project: ProjectViewType) => user !== null && project.owner.id !== user.id
  const canBuy = (project: ProjectViewType) => !(isForeign(project) || isPaid(project))

  const getActions = (project: ProjectViewType) => {
    if (isPaid(project) || canBuy(project)) {
      return (
        <>
          <IconButton
            key="share"
            className={classes.sharingButton}
            data-qa="project-share"
            onClick={openProjectShare(project)}
          >
            <GroupAddIcon color="primary" />
          </IconButton>
        </>
      )
    }
  }

  return (
    <>
      <TableBody>
        {projects.map((project) => formatProjectRow(project, false, classes, handleClick, getActions(project)))}
      </TableBody>
      {(first !== undefined && first.pageInfo.hasPrevious) || (last !== undefined && last.pageInfo.hasNext) ? (
        <TableFooter>
          <TableRow>
            <TableCell className={classes.pagination} colSpan={5}>
              {first !== undefined && first.pageInfo.hasPrevious ? (
                <IconButton onClick={handleNavBack(first.pageInfo.cursor)} data-qa="projectList-previous-button">
                  <KeyboardArrowLeft />
                </IconButton>
              ) : null}
              {last !== undefined && last.pageInfo.hasNext ? (
                <IconButton onClick={handleNavForward(last.pageInfo.cursor)} data-qa="projectList-next-button">
                  <KeyboardArrowRight />
                </IconButton>
              ) : null}
            </TableCell>
          </TableRow>
        </TableFooter>
      ) : null}
      {shareProject !== null ? (
        <ProjectShareForm projectId={shareProject.id} projectName={shareProject.name} onClose={closeProjectShare} />
      ) : null}
    </>
  )
}

interface IProjectListViewProps {
  pageSize: number
  sortField: Types.ProjectSortField
  sortOrder: Types.SortOrder
}

const ProjectListView: FunctionComponent<IProjectListViewProps> = ({
  pageSize,
  sortField,
  sortOrder,
  // tslint:disable-next-line:no-big-function
}) => {
  const router = useRouter()
  const [searchName, setSearchName] = useState('')
  const [searchOwner, setSearchOwner] = useState('')
  const debouncedSearchName = useDebounce(searchName, 500)
  const debouncedSearchOwner = useDebounce(searchOwner, 500)

  const { projects, loading, error, state, setSearchText, setSorting, setPage } = useProjectList(
    pageSize,
    sortField,
    sortOrder,
  )
  const { invitations } = useGetPendingInvitations()
  const [acceptedProjects, setAcceptedProjects] = useState<Types.AcceptProject_view_acceptProject_project[]>([])
  const config = useConfig()
  const classes = useStyles()

  useEffect(() => {
    setSearchText({ name: debouncedSearchName, owner: debouncedSearchOwner })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchName, debouncedSearchOwner])

  useEffect(() => {
    setSorting(sortField, sortOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder])

  const handleNavBack = (cursor: string) => {
    setPage({
      before: cursor,
      last: pageSize,
    })
  }

  const handleNavForward = (cursor: string) => {
    setPage({
      after: cursor,
      first: pageSize,
    })
  }

  const handleSearchName = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchName(ev.target.value)
  const handleSearchOwner = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchOwner(ev.target.value)
  const handleSortBy = (field: Types.ProjectSortField, wasActive: boolean) => () => {
    const {
      sorting: { direction },
    } = state
    const newDirection = wasActive
      ? direction === Types.SortOrder.ASCENDING
        ? Types.SortOrder.DESCENDING
        : Types.SortOrder.ASCENDING
      : Types.SortOrder.ASCENDING
    router.navigate(RouteName.ProjectList, {
      sort: projectFieldToRouterParam(field),
      order: sortOrderToRouterParam(newDirection),
    })
  }

  if (error !== undefined) {
    return <Typography color="error">{error.message}</Typography>
  }

  const handleAcceptedProject = (project: Types.AcceptProject_view_acceptProject_project) => {
    setAcceptedProjects(acceptedProjects.concat(project))
  }

  const makeSortingLabel = (field: Types.ProjectSortField, title: string) => {
    const active = state.sorting.field === field
    const order = state.sorting.direction === Types.SortOrder.ASCENDING ? 'asc' : 'desc'

    return (
      <TableSortLabel active={active} direction={order} onClick={handleSortBy(field, active)} data-qa={field}>
        {active ? (
          <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
        ) : null}
        {title}
      </TableSortLabel>
    )
  }

  const renderAccepted = () => {
    const handleClick = (projectId: string) => () => {
      router.navigate(RouteName.ProjectDetails, { projectId })
    }

    if (acceptedProjects.length > 0) {
      return <TableBody>{acceptedProjects.map((p) => formatProjectRow(p, true, classes, handleClick))}</TableBody>
    }
    return null
  }

  const renderInvitationsDialog = () => {
    if (invitations.length > 0 && config.local.showInvitationsModal) {
      return <ProjectInvitationDialog onClose={() => config.local.setShowInvitationsModal(false)} />
    }
    return null
  }

  // TODO Translations
  return (
    <>
      <ProjectHeader title="Project list" />
      <PendingInvitations hideAccepted={true} onAccepted={handleAcceptedProject} />
      <Container>
        <form>
          <Box className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SearchInput
                      placeholder="Name"
                      value={searchName}
                      onChange={handleSearchName}
                      data-qa="filter-name"
                    />
                  </TableCell>
                  <TableCell>
                    <SearchInput
                      placeholder="Owner"
                      value={searchOwner}
                      onChange={handleSearchOwner}
                      data-qa="filter-owner"
                    />
                  </TableCell>
                  <TableCell align="right">
                    {makeSortingLabel(Types.ProjectSortField.USER_CREATED_DATE, 'Created')}
                  </TableCell>
                  <TableCell align="right">
                    {makeSortingLabel(Types.ProjectSortField.USER_EDIT_DATE, 'Modified')}
                  </TableCell>
                  <TableCell align="right">
                    <Typography className={classes.tableHeadText}>Sharing</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {renderAccepted()}
              {!loading ? (
                <ProjectList
                  projects={projects !== null ? projects : []}
                  navigateBack={handleNavBack}
                  navigateForward={handleNavForward}
                />
              ) : null}
            </Table>
          </Box>
          {loading ? <ProgressBar /> : null}
        </form>
      </Container>
      {renderInvitationsDialog()}
    </>
  )
}

export default ProjectListView
