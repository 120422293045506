export enum ApiCalls {
  CREATE_FLOOR_PLAN_UPLOAD_URL = '/createFloorPlanUploadUrl',
  CREATE_PROJECT_RESOURCES_URL = '/createProjectResourcesUrl',
  DOWNLOAD_PROJECT = '/downloadProject',
  LOGIN = '/login',
  BRANCH_LOGIN = '/branchLogin',
  BRANCH_DEMO_LOGIN = '/branchDemoLogin',
  SIGN_DATA = '/signData',
  LOGOUT = '/logout',
  REGISTER = '/register',
  CONFIG = '/config',
  GET_PROJECT_QUOTE_AND_PREPARE_PAYMENT = '/purchases/getProjectQuote',
  CONFIRM_PROJECT_PURCHASE = '/purchases/stripeHook',
  FORGOT_PASSWORD = '/password/forgot',
  RESET_PASSWORD = '/password/reset',
  CREATE_PASSWORD = '/password/create',
  CHECK_ACCOUNT_STATUS = '/account/status',
  SEND_CONFIRM_ACCOUNT = '/account/requestConfirm',
  CONFIRM_ACCOUNT = '/account/confirm',
  PROJECT_INVITE = '/project/invite',
  PROJECT_ACCEPT = '/project/accept',
}

export type ProjectDownloadJobStatus = 'waiting' | 'running' | 'done' | 'failed'
