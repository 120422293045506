import * as t from 'io-ts'

import { request as doRequest, IApiRequest, getHeaders } from '../request'
import { ApiCalls } from '../constants'

const DownloadOfflineResponse = t.type({ jobId: t.string })
const DownloadPollResponse = t.any

export const downloadProjectRequest = async (apiRequest: IApiRequest<{ projectId: string }>) =>
  doRequest(DownloadOfflineResponse, apiRequest.apiUrl)(ApiCalls.DOWNLOAD_PROJECT, {
    method: 'PUT',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify(apiRequest.requestData),
  })

export const downloadProjectPoll = async (apiRequest: IApiRequest<{ jobId: string }>) =>
  doRequest(DownloadPollResponse, apiRequest.apiUrl)(
    `${ApiCalls.DOWNLOAD_PROJECT}?jobId=${apiRequest.requestData!.jobId}`,
    {
      method: 'GET',
      headers: getHeaders(apiRequest.headers),
    },
  )
