import { promisify } from 'typed-promisify'
import * as branch from 'branch-sdk'

import { ShareProjectData, BranchLinkFeatures, PaidForProjectData } from '@recordset-local/core/services/branch'

export const init = promisify(branch.init.bind(branch))
export const setIdentity = promisify(branch.setIdentity.bind(branch))
export const logout = promisify(branch.logout.bind(branch))
export const link = promisify(branch.link.bind(branch))
export const sendSMS = promisify(branch.sendSMS.bind(branch))

export const getBranchKey = () => {
  const isTestInstance = process.env.REACT_APP_BRANCH_TEST
  if (isTestInstance === undefined) {
    throw new Error('REACT_APP_BRANCH_TEST is not set')
  }
  return isTestInstance === 'false' ? process.env.REACT_APP_BRANCH_KEY : process.env.REACT_APP_BRANCH_KEY_TEST
}

export const createShareProjectLink = async (channel: string, data: ShareProjectData) => {
  const linkData = {
    channel,
    feature: BranchLinkFeatures.SHARE_PROJECT,
    data,
  }

  return link(linkData)
}

export const createPaidForProjectLink = async (channel: string, data: PaidForProjectData) => {
  const linkData = {
    channel,
    feature: BranchLinkFeatures.PAID_FOR_SHARING,
    data,
    $uri_redirect_mode: 2, // aggressive URI redirect mode
  }

  return link(linkData)
}

export const createAfterForgotPasswordLink = async (channel: string) => {
  const linkData = {
    channel,
    feature: BranchLinkFeatures.FORGOT_PASSWORD_RESET,
    $uri_redirect_mode: 2, // aggressive URI redirect mode
  }

  return link(linkData)
}

export const createInstallAppLink = async (channel: string) => {
  const linkData = {
    channel,
    feature: BranchLinkFeatures.INSTALL_APP,
  }

  return link(linkData)
}

export const sendAppInstallSms = async (channel: string, phoneNumber: string, shareProject?: ShareProjectData) => {
  const linkData = {
    channel,
    feature: BranchLinkFeatures.INSTALL_APP,
    data: {
      shareProject,
    },
  }

  return sendSMS(phoneNumber, linkData, { make_new_link: false })
}
