import React, { FunctionComponent } from 'react'
import { useRoute } from 'react-router5'
import { Trans } from '@lingui/macro'

import { Grid, Typography, Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'

import { ThemeColor } from '@recordset-local/theme'
import { RouteName } from '@/router'

// TODO: fix and remove hardcoded fontsizes, set them responsive in theme
const useStyles = makeStyles((theme: Theme) => {
  const textStyle = {
    fontSize: '0.875rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.125rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  }

  return createStyles({
    wrapper: {
      position: 'fixed',
      height: '100vh',
      paddingTop: theme.spacing(5),
      overflow: 'hidden',
      border: `${theme.spacing(1)}px solid ${ThemeColor.BRAND_SECONDARY}`,
      borderTop: 'none',
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(7),
        borderWidth: theme.spacing(2),
      },
    },
    content: {
      position: 'fixed',
      marginTop: -theme.spacing(5),
      width: `calc(100% - ${theme.spacing(2)}px)`,
      height: theme.spacing(5),
      background: ThemeColor.BRAND_SECONDARY,
      textTransform: 'uppercase',
      color: ThemeColor.WHITE,
      [theme.breakpoints.up('lg')]: {
        marginTop: -theme.spacing(7),
        width: `calc(100% - ${theme.spacing(4)}px)`,
        height: theme.spacing(7),
      },
    },
    title: {
      ...textStyle,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    backLink: {
      ...textStyle,
      display: 'inline-flex',
      alignItems: 'center',
    },
    backIcon: {
      transform: 'rotate(-90deg)',
      marginRight: theme.spacing(2),
    },
    hideSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    hideXs: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    loginLink: {
      ...textStyle,
    },
  })
})

const PreviewWrapper: FunctionComponent = ({ children }) => {
  const { router } = useRoute()
  const classes = useStyles()

  return (
    <Grid container className={classes.wrapper}>
      <Grid container item alignItems="center" justify="space-between" className={classes.content}>
        <Button
          className={classes.backLink}
          color="inherit"
          size="small"
          onClick={() => router.navigate(RouteName.Home)}
        >
          <ChangeHistoryIcon fontSize="inherit" className={classes.backIcon} />
          <Trans id="invitation.navigate_home">
            <span className={classes.hideXs}>Back</span>&nbsp;<span className={classes.hideSm}>to the invitation</span>
          </Trans>
        </Button>
        <Trans id="invitation.preview_mode">
          <Typography className={classes.title}>View plan only.</Typography>
        </Trans>
        <Button
          className={classes.loginLink}
          color="inherit"
          size="small"
          onClick={() => router.navigate(RouteName.Login)}
        >
          <Trans id="user.login">Login</Trans>
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

export default PreviewWrapper
