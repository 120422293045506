import React, { FunctionComponent, useEffect, useState, useContext } from 'react'
import * as t from 'io-ts'
import * as Sentry from '@sentry/browser'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { configRequest } from '@recordset-local/core/api/requests/config'
import { Config } from '@recordset-local/types/server/api'
import { callApi } from '@recordset-local/core/web/request'

type LocalConfig = {
  projectsPerPage: number
  projectSortField: Types.ProjectSortField
  projectSortOrder: Types.SortOrder
  showInvitationsModal: boolean
}

const LOCAL_CONFIG = {
  projectsPerPage: 20,
  projectSortField: Types.ProjectSortField.USER_EDIT_DATE,
  projectSortOrder: Types.SortOrder.DESCENDING,
  showInvitationsModal: true,
  setShowInvitationsModal: (_show: boolean) => {
    return
  },
}

type LocalConfigSettings = {
  setShowInvitationsModal: (show: boolean) => void
}

type ConfigState = t.TypeOf<typeof Config> & { local: LocalConfig & LocalConfigSettings }

const ConfigContext = React.createContext<ConfigState>({
  stripePk: '',
  webapp: {
    enableEditing: false,
    sentryDsn: '',
  },
  local: {
    ...LOCAL_CONFIG,
  },
})

const ConfigProvider: FunctionComponent = ({ children }) => {
  const [config, setConfig] = useState<ConfigState | null>(null)

  const setShowInvitationsModal = (config: ConfigState) => (show: boolean) => {
    setConfig({
      ...config,
      local: {
        ...config.local,
        showInvitationsModal: show,
      },
    })
  }

  useEffect(() => {
    const call = async () => {
      try {
        const { config } = await callApi(configRequest)
        const fullConfig: ConfigState = {
          ...config,
          local: {
            ...LOCAL_CONFIG,
          },
        }
        fullConfig.local.setShowInvitationsModal = setShowInvitationsModal(fullConfig)
        setConfig(fullConfig)
      } catch (e) {
        Sentry.captureException(e)
        setConfig(null)
      }
    }

    call()
  }, [])

  // TODO: pass a loading component to display during initial fetch?
  return config !== null ? <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider> : null
}

const useConfig = () => useContext<ConfigState>(ConfigContext)

export { ConfigContext, ConfigProvider, useConfig }
