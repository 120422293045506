// @ts-nocheck
import React, { FunctionComponent, useState, useEffect } from 'react'
import { Circle, Group, Text, Label, Tag, Path } from 'react-konva'
import konva from 'konva'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { ThemeColor } from '@recordset-local/theme'
import {
  WEB_ORDERED_CAMERA_STATION_COMPASS_DIRECTIONS,
  CameraStationPlaneDirection,
} from '@recordset-local/types/domain/cameraStation'

type Station = Types.FloorDetails_stations

const STROKE_WIDTH = 4.19
const STROKE_WIDTH_CIRCLE = 3
const STROKE_COLOR = ThemeColor.WHITE
const SHAPE_OPACITY = 0.7

const CameraWedge: React.FC = (props) => (
  <Path
    {...props}
    data={
      'm -6.2430127,-28.754 a 28.39,28.39 0 0 1 12.88,0 l 0.62,0.13 c 0.77,0.19 1.54,0.41 2.29,0.67 l 28.2499997,-68.2 h -75.2 l 28.2500003,68.2 c 0.75,-0.26 1.52,-0.48 2.29,-0.67 z'
    }
    stroke={STROKE_COLOR}
    strokeWidth={STROKE_WIDTH}
  />
)

// TODO UPSHOT Diagonals are not implemented
interface ICameraStationProps {
  station: Station
  size: number
  selectedDirection?: CameraStationPlaneDirection
  onClick: () => void
  onPlaneClick: (station: Station, direction: CameraStationPlaneDirection) => void
  onPlaneHover: (station: Station, direction: CameraStationPlaneDirection, action: 'enter' | 'leave') => void
  customPosition?: { x: number; y: number }
}

const CameraStation: FunctionComponent<ICameraStationProps> = ({
  station,
  size,
  selectedDirection,
  onPlaneClick,
  onPlaneHover,
  customPosition,
}) => {
  const [hoverPlaneDirection, setHoverPlaneDirection] = useState<CameraStationPlaneDirection | null>(
    selectedDirection !== undefined ? selectedDirection : null,
  )

  const highlightPlane = (highlight: boolean) => (ev: konva.KonvaEventObject<MouseEvent>) => {
    const direction = ev.target.name() as CameraStationPlaneDirection
    setHoverPlaneDirection(highlight ? direction : selectedDirection !== undefined ? selectedDirection : null)
    onPlaneHover(station, direction, highlight ? 'enter' : 'leave')
  }

  useEffect(() => {
    if (selectedDirection !== undefined && hoverPlaneDirection !== selectedDirection) {
      setHoverPlaneDirection(selectedDirection)
    }
  }, [selectedDirection])

  const getPlaneColor = (direction: CameraStationPlaneDirection) => {
    const dirImages = station.images.find((i) => i.direction === direction)
    return dirImages !== undefined && dirImages.images.length > 0
      ? ThemeColor.WEDGE_WITH_PHOTO
      : ThemeColor.WEDGE_NO_PHOTO
  }

  const activePos = customPosition !== undefined ? customPosition : station.position
  const wedgeRotDeg = 45
  const wedgeScale = size / 100.0 // 100 is max distance of wedge path from (0,0)
  const selScaleFactor = 1.2
  const wedgeSelScale = wedgeScale * selScaleFactor

  return (
    <Group x={activePos.x} y={activePos.y} name={station.id}>
      <Group>
        {WEB_ORDERED_CAMERA_STATION_COMPASS_DIRECTIONS.map((compassDirection, index) => (
          <CameraWedge
            key={index}
            name={compassDirection}
            fill={getPlaneColor(compassDirection)}
            rotation={index * wedgeRotDeg + 90}
            opacity={hoverPlaneDirection === compassDirection ? 1 : SHAPE_OPACITY}
            scale={
              hoverPlaneDirection === compassDirection
                ? { x: wedgeSelScale, y: wedgeSelScale }
                : { x: wedgeScale, y: wedgeScale }
            }
            onMouseEnter={highlightPlane(true)}
            onMouseLeave={highlightPlane(false)}
            onClick={() => onPlaneClick(station, compassDirection)}
          />
        ))}

        <Circle
          name={Types.ImageDirection.UP}
          radius={size / 4}
          stroke={STROKE_COLOR}
          strokeWidth={STROKE_WIDTH_CIRCLE}
          fill={getPlaneColor(Types.ImageDirection.UP)}
          opacity={hoverPlaneDirection === Types.ImageDirection.UP ? 1 : SHAPE_OPACITY}
          scale={
            hoverPlaneDirection === Types.ImageDirection.UP ? { x: selScaleFactor, y: selScaleFactor } : { x: 1, y: 1 }
          }
          onMouseEnter={highlightPlane(true)}
          onMouseLeave={highlightPlane(false)}
          onClick={() => onPlaneClick(station, Types.ImageDirection.UP)}
        />
      </Group>

      <Label x={-size * 0.63} y={size + 10} opacity={SHAPE_OPACITY}>
        <Tag fill={ThemeColor.BLACK} cornerRadius={size * 0.15} />
        <Text
          text={station.label}
          padding={size * 0.15}
          fontSize={size * 0.55}
          fill={ThemeColor.WHITE}
          align="center"
        />
      </Label>
    </Group>
  )
}

export default CameraStation
