// @ts-nocheck

import React, { FunctionComponent, useState, useEffect, useContext } from 'react'

import {
  AccessUrl,
  getFloorPlanDownloadUrl,
  getCameraImageDownloadUrl,
  DownloadCameraImage,
  DownloadFloorPlan,
} from '@recordset-local/core/api/requests/resourcePaths'
import { getAccessUrl } from '@recordset-local/core/web/resources/remoteResources'
import { DB, readLocalResource } from '@recordset-local/core/web/resources/staticResources'
import { usePrevious } from '@recordset-local/core/hooks/previous'

type ProviderType = 'remote' | 'static'

// NOTE: typesafety can be improved, IResourceState has to provide common interface for static/remote
type CameraImage = Partial<Omit<DownloadCameraImage, 'userId' | 'projectId'>>
type FloorImage = Partial<Omit<DownloadFloorPlan, 'userId' | 'projectId'>>

const REFETCH_ACCESSURL_DEBOUNCE_MS = 1000

export interface IResourceState {
  provider: ProviderType
  getFloorPlan: (imageData: FloorImage | null) => string | null
  getCameraImage: (imageData: CameraImage | null, thumbnail: boolean) => string | null
}

export interface IResourceStateRemote extends IResourceState {
  provider: 'remote'
  accessUrl: AccessUrl | null
  userId: string
  projectId: string
}

export interface IResourceStateStatic extends IResourceState {
  provider: 'static'
  db: DB
}

let ACTIVE_CONTEXT: React.Context<IResourceStateRemote | IResourceStateStatic> | null = null

interface IResourceProviderRemoteProps {
  userId: string
  projectId: string
}

interface IResourceProviderStaticProps {
  db: DB
}

export const makeRemoteResourceProvider = (): FunctionComponent<IResourceProviderRemoteProps> => {
  if (ACTIVE_CONTEXT !== null) {
    throw new Error('only one ResourceContext can be instantiated')
  }

  const ResourceContext = React.createContext<IResourceProviderRemoteProps>({
    provider: 'remote',
    accessUrl: null,
    userId: '',
    projectId: '',
    getCameraImage: (_data: CameraImage | null, _thumbnail: boolean) => null,
    getFloorPlan: (_data: CameraImage | null) => {
      return null
    },
  })
  ACTIVE_CONTEXT = ResourceContext

  const getCameraImageWithAccess = (accessUrl: AccessUrl | null, userId: string, projectId: string) => (
    imageData: CameraImage | null,
    thumbnail: boolean,
  ) =>
    accessUrl !== null && imageData !== null
      ? getCameraImageDownloadUrl(accessUrl, { userId, projectId, ...imageData }, thumbnail)
      : null

  const getFloorPlanImageWithAccess = (accessUrl: AccessUrl | null, userId: string, projectId: string) => {
    return (imageData: FloorImage | null) =>
      accessUrl !== null && imageData !== null
        ? getFloorPlanDownloadUrl(accessUrl, { userId, projectId, ...imageData })
        : null
  }

  return ({ children, projectId, userId }) => {
    const prevProjectId = usePrevious(projectId)
    const [accessUrl, setAccessUrl] = useState<AccessUrl | null>(null)
    const [refetchTrigger, setRefetchTrigger] = useState(false)

    useEffect(() => {
      const callUrl = async () => {
        try {
          const { url } = await getAccessUrl(projectId)
          setAccessUrl(url)
        } catch {
          setAccessUrl(null)
        } finally {
          if (refetchTrigger) {
            setRefetchTrigger(false)
          }
        }
      }

      if (prevProjectId !== projectId || refetchTrigger) {
        callUrl()
      }
    }, [prevProjectId, projectId, refetchTrigger])

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout> | null = null
      if (accessUrl === null && refetchTrigger === false) {
        timeout = setTimeout(() => {
          setRefetchTrigger(true)
        }, REFETCH_ACCESSURL_DEBOUNCE_MS)
      }

      return () => {
        if (timeout !== null) {
          clearTimeout(timeout)
        }
      }
    }, [accessUrl, refetchTrigger])

    // NOTE: children most likely rely on resources provided by the context, therefore before rendering it is important
    // we wait for accessUrl.
    // TODO: render loading indicator while getting URL?
    return (
      <ResourceContext.Provider
        value={{
          provider: 'remote',
          accessUrl,
          projectId,
          userId,
          getCameraImage: getCameraImageWithAccess(accessUrl, userId, projectId),
          getFloorPlan: getFloorPlanImageWithAccess(accessUrl, userId, projectId),
        }}
      >
        {accessUrl !== null ? children : null}
      </ResourceContext.Provider>
    )
  }
}

export const makeStaticResourceProvider = (): FunctionComponent<IResourceProviderStaticProps> => {
  const ResourceContext = React.createContext<IResourceProviderStaticProps>({
    provider: 'remote',
    db: null,
    getCameraImage: (_data: CameraImage | null, _thumbnail: boolean) => null,
    getFloorPlan: (_data: FloorImage | null) => {
      return null
    },
  })

  ACTIVE_CONTEXT = ResourceContext

  return ({ children, db }) => {
    return (
      <ResourceContext.Provider
        value={{
          provider: 'static',
          db,
          getCameraImage: (image: CameraImage | null, _thumbnail: boolean) =>
            image !== null ? readLocalResource(image.imageName, db) : null,
          getFloorPlan: (image: FloorImage | null) => {
            return image !== null ? readLocalResource(image.imageName, db) : null
          },
        }}
      >
        {children}
      </ResourceContext.Provider>
    )
  }
}

export const useResourceProvider = () => {
  if (ACTIVE_CONTEXT === null) {
    throw new Error('ResourceProvider must be initialised first, no active context is present')
  }
  return useContext<IResourceState>(ACTIVE_CONTEXT)
}
