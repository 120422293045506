import React, { FunctionComponent, useState, useEffect } from 'react'
import { useRoute } from 'react-router5'
import { useQuery } from '@apollo/react-hooks'
import { Container, Box, Drawer, Button, Select, MenuItem, Typography } from '@material-ui/core'
import { Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import DetailsIcon from '@material-ui/icons/Details'
import { DateTime } from 'luxon'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import GetProject from '@recordset-local/types/graphql/GetProject.graphql'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import { getTimepointFromTimestamp } from '@recordset-local/core/utils'
import ProjectHeader from '@recordset-local/core/web/components/ProjectHeader'
import CameraStationDatePicker from '@recordset-local/core/web/components/CameraStationDatePicker'
import ProgressBar from '@recordset-local/core/web/components/ProgressBar'

import { RouteName } from '@/router'
import { getFloorNameLocalized } from '@/modules/floor'
import { ResourceProvider } from '@/services/resources'

import ProjectSummary from './ProjectSummary'
import Floor from './Floor'

const useStyles = makeStyles((theme: Theme) => ({
  // AppBar+ProjectHeader are fixed on detail page
  detailWrapper: {
    position: 'relative',
  },
  detailsIcon: {
    transform: 'rotate(-90deg)',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
  },
  detailsIconClose: {
    transform: 'rotate(90deg)',
  },
  drawerButton: {
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  drawer: {
    // TODO: calculate this position from AppBar+ProjectHeader current element height
    top: 142,
    [theme.breakpoints.down('sm')]: {
      top: 169,
    },
    display: 'block',
    width: 320,
    maxHeight: 'calc(100vh - 142px)',
    overflowY: 'auto',
    boxShadow: '-10px 4px 10px rgba(0, 0, 0, .05)',
    padding: theme.spacing(5, 2),
  },
  datePickerWrapper: {
    minWidth: 150,
    maxWidth: 260,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  select: {
    minWidth: 150,
    maxWidth: 260,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  selectRoot: {
    padding: theme.spacing(1, 2),
    fontSize: '1rem',
  },
  selectIcon: {
    fontSize: '1.5rem',
  },
  selectItem: {
    fontSize: '1rem',
  },
  addFloorButton: {
    position: 'fixed',
    left: theme.spacing(4),
    bottom: theme.spacing(10),
  },
}))

interface IProjectDetailsViewProps {
  projectId: string
}

// tslint:disable:no-big-function
const ProjectDetailsView: FunctionComponent<IProjectDetailsViewProps> = ({ projectId }) => {
  const { user } = useCurrentAuthGQLUser()
  const { route, router } = useRoute()
  const classes = useStyles()
  const queryVariables: Types.GetProjectVariables = { input: { id: projectId } }
  const { loading, error, data } = useQuery<Types.GetProject>(GetProject, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  })
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [stationTimepoints, setStationTimepoints] = useState<DateTime[]>([])
  const [activeTimepoint, setActiveTimepoint] = useState<DateTime>(DateTime.local().endOf('day'))

  const isOwner = (project: Types.GetProject_view_project, userId: string) => project.owner.id === userId

  const getCurrentFloor = () => {
    const project = data !== undefined ? data.view.project : null
    if (project === null) {
      return undefined
    }
    if (route.params.floor === undefined) {
      return project.floors[0]
    }

    return project.floors.find((f) => f.id === route.params.floor)
  }

  useEffect(() => {
    const currFloor = getCurrentFloor()
    if (currFloor !== undefined) {
      const timepoints = Array.from(
        new Set(
          currFloor.stations
            .flatMap((station) => station.timepoints)
            .map((tp) => getTimepointFromTimestamp(tp).toISO()),
        ),
        (iso) => getTimepointFromTimestamp(iso),
      )
      setStationTimepoints(timepoints)
      return
    }

    setStationTimepoints([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, data])

  const handleChangeFloor = (event: React.ChangeEvent<{ value: unknown }>) => {
    router.navigate(RouteName.ProjectDetails, { projectId, floor: event.target.value as string })
  }

  const toggleSummary = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopperAnchorEl(popperAnchorEl !== null ? null : event.currentTarget)
  }

  const renderProject = (project: Types.GetProject_view_project) => {
    const floor = getCurrentFloor()
    const activeFloor = floor !== undefined ? floor : project.floors.length > 0 ? project.floors[0] : undefined

    return (
      <I18n>
        {({ i18n }) => (
          <>
            {user !== null ? (
              <ResourceProvider projectId={project.id} userId={user.id}>
                <ProjectHeader title={project.name} detailHeader>
                  {activeFloor !== undefined ? (
                    <>
                      <Select
                        value={activeFloor.id}
                        onChange={handleChangeFloor}
                        displayEmpty
                        variant="outlined"
                        className={classes.select}
                        classes={{
                          root: classes.selectRoot,
                          icon: classes.selectIcon,
                        }}
                      >
                        {[...project.floors]
                          .sort((a, b) => a.level - b.level)
                          .map((f) => (
                            <MenuItem key={f.id} value={f.id} className={classes.selectItem}>
                              {getFloorNameLocalized(i18n, f)}
                            </MenuItem>
                          ))}
                      </Select>
                      <Box className={classes.datePickerWrapper}>
                        <CameraStationDatePicker
                          availableDates={stationTimepoints}
                          onDateSelected={setActiveTimepoint}
                        />
                      </Box>
                    </>
                  ) : null}

                  {isOwner(project, user.id) && (
                    <Button color="secondary" size="small" onClick={toggleSummary} className={classes.drawerButton}>
                      <DetailsIcon
                        fontSize="small"
                        className={`${classes.detailsIcon}
              ${popperAnchorEl !== null ? classes.detailsIconClose : ''}`}
                      />
                      Project summary
                    </Button>
                  )}
                </ProjectHeader>

                {isOwner(project, user.id) && (
                  <Drawer
                    variant="persistent"
                    anchor="right"
                    open={popperAnchorEl !== null}
                    onClose={toggleSummary}
                    BackdropProps={{ invisible: true }}
                    classes={{ paper: classes.drawer }}
                  >
                    <ProjectSummary project={project} />
                  </Drawer>
                )}

                <Container disableGutters maxWidth={false} className={classes.detailWrapper}>
                  {loading ? <ProgressBar /> : null}
                  {activeFloor !== undefined ? (
                    <Floor projectId={project.id} floorId={activeFloor.id} activeTimepoint={activeTimepoint} />
                  ) : null}
                </Container>
              </ResourceProvider>
            ) : null}
          </>
        )}
      </I18n>
    )
  }

  return error === undefined && data !== undefined && data.view.project !== null ? (
    renderProject(data.view.project)
  ) : (
    <Container>
      {error !== undefined ? (
        <Typography color="error">{error.message}</Typography>
      ) : (
        <Typography>
          <Trans id="project.no_data">Cannot load project data</Trans>
        </Typography>
      )}
    </Container>
  )
}

export default ProjectDetailsView
