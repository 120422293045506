import React, { FunctionComponent, useState, useEffect } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Button, Link } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import UserIcon from '@material-ui/icons/Person'
import AddIcon from '@material-ui/icons/Add'
import FileSaver from 'file-saver'
import { Trans, t } from '@lingui/macro'
import { DateTime } from 'luxon'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { ThemeColor } from '@recordset-local/theme'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import { isRegisteredUser } from '@recordset-local/core/utils/login'
import { formatDate } from '@recordset-local/core/utils'
import { isPaid } from '@recordset-local/core/modules/project'

import { useOfflineDownload } from '@/api/offlineDownload'
import { i18n } from '@/locale'

import ProjectPurchase from './ProjectPurchase'
import ProjectShareForm from './ProjectShareForm'
import ProgressBar from './ProgressBar'

const useStyles = makeStyles((theme: Theme) => ({
  users: {
    marginBottom: theme.spacing(3),
    '& + $pendingUsers': {
      marginTop: -theme.spacing(3),
    },
  },
  user: {
    alignItems: 'flex-start',
  },
  userIcon: {
    minWidth: 0,
    margin: theme.spacing(1, 2, 0, 0),
    color: ThemeColor.TEXT_GRAY,
  },
  userName: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pendingUsers: {
    marginBottom: theme.spacing(3),
  },
  pendingUserName: {
    opacity: 0.42,
  },
  inviteButton: {
    textTransform: 'none',
    marginBottom: theme.spacing(3),
  },
  offlineButton: {
    textTransform: 'none',
  },
  membersTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  offlineButtonWrapper: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
}))

interface IProjectSummaryProps {
  project: Types.GetProject_view_project
}

const canGetQuote = (project: Types.GetProject_view_project) => {
  if (isPaid(project)) {
    return false
  }
  return (
    project.floors.length > 0 &&
    project.floors
      .map((f) => f.plan !== null && f.plan.image !== null && f.plan.area !== null && f.plan.area > 0)
      .every((v) => v === true)
  )
}

// TODO FUTURE This might get moved to "core" as it can be used also from the web
const INVITATION_STATE_TO_LABEL = new Map<Types.InvitationState, string>([
  [Types.InvitationState.A, `${i18n._(t('invitationState.accepted-text')`Accepted`)}`],
  [Types.InvitationState.D, `${i18n._(t('invitationState.declined-text')`Declined`)}`],
  [Types.InvitationState.P, `${i18n._(t('invitationState.pending-text')`Pending`)}`],
])

const ProjectSummary: FunctionComponent<IProjectSummaryProps> = ({ project }) => {
  const { user } = useCurrentAuthGQLUser()
  const classes = useStyles()
  const [showShareOptions, setShowShareOptions] = useState<boolean>(false)
  const showPurchaseForm = canGetQuote(project)
  const purchaseDate = showPurchaseForm ? null : project.payment !== null ? project.payment.confirmedDate : null
  const {
    state: downloadState,
    error: downloadError,
    result: projectFile,
    download: startDownload,
  } = useOfflineDownload(project.id)

  useEffect(() => {
    if (projectFile !== null) {
      FileSaver.saveAs(projectFile)
    }
  }, [projectFile])

  const openShareOptions = () => {
    setShowShareOptions(true)
  }
  const closeShowShareOptions = () => {
    setShowShareOptions(false)
  }

  return (
    <>
      {isRegisteredUser(user) && user.id === project.owner.id ? (
        <>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={openShareOptions}
            className={classes.inviteButton}
            fullWidth
          >
            <AddIcon fontSize="small" />
            <Trans id="projectSummary.share-text">Share</Trans>
          </Button>
          <Box className={classes.offlineButtonWrapper}>
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={startDownload}
              className={classes.offlineButton}
              fullWidth
              disabled={downloadState === 'running' || downloadState === 'waiting'}
            >
              <Trans id="projectSummary.downloadOfflineVersion-text">Download offline version</Trans>
            </Button>
            {downloadState === 'running' || downloadState === 'waiting' ? <ProgressBar progressBarSize={24} /> : null}
          </Box>
          {downloadState === 'waiting' && (
            <Typography>
              <Trans id="projectSummary.downloadOfflineVersionInfoWait-text">
                Please wait, your download is scheduled to run, this might take a couple of minutes
              </Trans>
            </Typography>
          )}
          {downloadState === 'running' && (
            <Trans id="projectSummary.downloadOfflineVersionInfoStartingSoon-text">
              Please wait, we are building your package, download will start shortly
            </Trans>
          )}
          {downloadError !== null && <Typography color="error">{downloadError}</Typography>}
        </>
      ) : null}

      {isRegisteredUser(user) && showShareOptions && (
        <ProjectShareForm projectId={project.id} projectName={project.name} onClose={closeShowShareOptions} />
      )}

      {project.sharedWith.length > 0 && user !== null && project.owner.id === user.id && (
        <>
          <Typography variant="h3" className={classes.membersTitle}>
            <Trans id="projectSummary.projectMembersTitle-text">Project members</Trans>
          </Typography>

          <List disablePadding className={classes.users}>
            {project.sharedWith.map((u) => (
              <ListItem color="primary" disableGutters key={u.id} classes={{ root: classes.user }}>
                <ListItemIcon className={classes.userIcon}>
                  <UserIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  className={classes.userName}
                  primary={<Typography>{u.name}</Typography>}
                  secondary={
                    <Link href={`mailto:${u.email}`} color="primary">
                      {u.email}
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}

      {project.issuedInvitations.length > 0 && (
        <>
          <List disablePadding className={classes.pendingUsers}>
            <Trans id="projectSummary.issuedInvitationsTitle-text">Issued invitations</Trans>
            {project.issuedInvitations.map((i) => (
              <ListItem disableGutters key={i.id} classes={{ root: classes.user }}>
                <ListItemIcon className={classes.userIcon}>
                  <UserIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  className={`${classes.userName} ${classes.pendingUserName}`}
                  primary={
                    i.invitedEmail !== null ? (
                      <>
                        <Link href={`mailto:${i.invitedEmail}`} color="inherit">
                          {i.invitedEmail}
                        </Link>
                        <Typography>{INVITATION_STATE_TO_LABEL.get(i.state)}</Typography>
                      </>
                    ) : (
                      <>
                        <Typography>{i.invitedPhone}</Typography>
                        <Typography>{INVITATION_STATE_TO_LABEL.get(i.state)}</Typography>
                      </>
                    )
                  }
                  secondary={
                    i.invitedEmail != null && i.invitedPhone !== null ? (
                      <>
                        <Typography>{i.invitedPhone}</Typography>
                      </>
                    ) : null
                  }
                />
                {/* <Button>
                  <Trans id="projectSummary.revoke-btn">Revoke</Trans>
                </Button> */}
              </ListItem>
            ))}
          </List>
        </>
      )}

      {showPurchaseForm ? (
        <ProjectPurchase projectId={project.id} />
      ) : purchaseDate !== null && project.payment!.confirmedDate !== null ? (
        <Typography>
          <Trans id="projectSummary.projectBoughtOn-text">
            You bought this project on {formatDate(DateTime.fromISO(project.payment!.confirmedDate))}
          </Trans>
        </Typography>
      ) : null}
    </>
  )
}

export default ProjectSummary
