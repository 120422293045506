import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography, Button, TextField, Link } from '@material-ui/core'
import { useRouter } from 'react-router5'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'

import ProgressBar from '@recordset-local/core/web/components/ProgressBar'

import { RouteName } from '@/router'
import { useFormStyles } from '@/styles/FormStyles'
import { useCreatePasswordApi, useCheckAccountStatusApi, useLoginApi } from '@/api/login'
import { getApiRequestErrorMessage } from '@/api/messages'

interface IConfirmAccountFormProps {
  email: string
  token: string
  projectId?: string
  onLogin: () => Promise<void>
}

// Account confirmation can be done on mobile only
const ConfirmAccountForm: React.FC<IConfirmAccountFormProps> = ({ email, token, projectId, onLogin }) => {
  const router = useRouter()
  const classes = useFormStyles()
  const [{ token: loginToken, error: loginError }, doLogin] = useLoginApi()
  const [{ success: successCreatePwd, error: errorCreatePwd }, createPassword] = useCreatePasswordApi()
  const [
    { accountStatus, success: successAccCheck, error: errorAccCheck },
    checkAccountStatus,
  ] = useCheckAccountStatusApi()

  const [password, setPassword] = useState('')

  useEffect(() => {
    if (loginToken === null) {
      return
    }

    const loginCb = async () => {
      await onLogin()
      if (projectId !== undefined) {
        router.navigate(RouteName.ProjectDetails, { projectId })
      }
    }
    loginCb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken, projectId])

  useEffect(() => {
    checkAccountStatus({ token })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const handlePassword = (ev: React.ChangeEvent<HTMLInputElement>) => setPassword(ev.target.value)
  const handleSubmitForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (successCreatePwd === null || successCreatePwd === false) {
      createPassword({ token, password })
    } else {
      doLogin({ email, password })
    }
  }

  const isCheckingAccount = successAccCheck === null && errorAccCheck === null

  return (
    <I18n>
      {({ i18n }) => (
        <Container maxWidth="xl">
          <Grid container alignContent="center" alignItems="center">
            <Grid item xs={12}>
              {isCheckingAccount && (
                <>
                  <Typography align="center">
                    <Trans id="verifyAccount.checkingStatusNotice">Checking {email} account status...</Trans>
                  </Typography>
                  <ProgressBar />
                </>
              )}

              {!isCheckingAccount && accountStatus !== null && accountStatus.isAccountConfirmed === false && (
                <>
                  <Typography align="center">
                    <Trans id="verifyAccount.verifyFromMobileNotice">
                      Account verification needs to be done on your mobile phone with the RecordSet app installed.
                    </Trans>
                  </Typography>
                  <br />
                  <Typography align="center">
                    <Trans id="verifyAccount.alternativeVerifyLink">
                      If you opened the link on your mobile phone and got redirected here please try opening
                    </Trans>
                  </Typography>
                  <Typography align="center">
                    <a
                      href={`recordset://verify-account?email=${encodeURIComponent(email)}&token=${encodeURIComponent(
                        token,
                      )}`}
                    >
                      the following link.
                    </a>
                  </Typography>
                  <Typography align="center">
                    <Trans id="verifyAccount.iosNotice">
                      * iOS might require to open the above link in the Safari browser
                    </Trans>
                  </Typography>
                  <br />
                  <Typography align="center">
                    <Trans id="verifyAccount.thankYouMessage">
                      If you will have any troubles please contact our support via "Report a Problem" feature in the
                      RecordSet mobile app. Thank you!
                    </Trans>
                  </Typography>
                </>
              )}

              {!isCheckingAccount && accountStatus !== null && accountStatus.isAccountConfirmed === true && (
                <>
                  <Typography variant="h2" className={classes.title} align="center">
                    <Trans id="verifyAccount.alreadyVerifiedNotice">{email} account has been already verified</Trans>
                  </Typography>

                  {accountStatus.isPasswordSet === true && (
                    <>
                      <Typography className={classes.paragraph}>
                        <Trans id="verifyAccount.passwordCreatedNotice">
                          You have already created a web app password for this account
                        </Trans>
                      </Typography>

                      <Link onClick={() => router.navigate(RouteName.Login, { email })}>
                        <Trans id="confirmAccountForm.login-btn">Go to login</Trans>
                      </Link>
                    </>
                  )}

                  {accountStatus.isPasswordSet === false && (
                    <>
                      <Typography className={classes.paragraph}>
                        <Trans id="verifyAccount.createPasswordNotice">
                          You can now set the password for this account to access your projects in the RecordSet web app
                        </Trans>
                      </Typography>

                      <form onSubmit={handleSubmitForm}>
                        {successCreatePwd !== true && (
                          <>
                            <Grid item>
                              <TextField
                                className={classes.textField}
                                type="password"
                                value={password}
                                placeholder={i18n._(t('input.password_placeholder')`Enter your password`)}
                                label={i18n._(t('input.password')`Password`)}
                                onChange={handlePassword}
                                variant="outlined"
                                required
                              />
                            </Grid>

                            <Grid item>
                              <Button variant="contained" color="primary" type="submit" className={classes.button}>
                                <Trans id="confirmAccountForm.createPassword-btn">Create password</Trans>
                              </Button>
                            </Grid>
                          </>
                        )}

                        {successCreatePwd === true && (
                          <>
                            <Typography paragraph color="primary">
                              <Trans id="confirmAccountForm.passwordCreatedsuccess-text">
                                Password created successfully
                              </Trans>
                            </Typography>

                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                              {projectId === undefined && (
                                <Trans id="confirmAccountForm.takeMeToApp-btn">Take me to the app</Trans>
                              )}
                              {projectId !== undefined && (
                                <Trans id="confirmAccountForm.takeMeToAppProject-btn">Take me to my project</Trans>
                              )}
                            </Button>
                          </>
                        )}

                        {errorCreatePwd !== null && (
                          <Typography paragraph color="error">
                            {getApiRequestErrorMessage(errorCreatePwd)}
                          </Typography>
                        )}
                      </form>
                    </>
                  )}
                </>
              )}

              {errorAccCheck !== null && (
                <Typography paragraph color="error">
                  {getApiRequestErrorMessage(errorAccCheck)}
                </Typography>
              )}

              {loginError !== null && (
                <Typography paragraph color="error">
                  {getApiRequestErrorMessage(loginError)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </I18n>
  )
}

export default ConfirmAccountForm
