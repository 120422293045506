// @ts-nocheck

import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react'
import { Dialog, DialogTitle, Button, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Stage, Layer, Image } from 'react-konva'
import Konva from 'konva'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import {
  WEB_ORDERED_CAMERA_STATION_PLANE_DIRECTIONS,
  CameraStationPlaneDirection,
} from '@recordset-local/types/domain/cameraStation'

import { useCameraImage } from '@recordset-local/core/web/resources/hooks'
import CameraStation from '@recordset-local/core/web/components/CameraStation'
import ProgressBar from '@recordset-local/core/web/components/ProgressBar'
import {
  calculateImageParams,
  cameraImageDataDiffers,
  imageDirectionToLocaleString,
} from '@recordset-local/core/web/utils'
import { ThemeColor } from '@recordset-local/theme'

type Station = Types.FloorDetails_stations
type ImageParams = ReturnType<typeof calculateImageParams>

// TODO UPSHOT Diagonals not implemented
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    position: 'absolute',
    zIndex: 9,
    top: -theme.spacing(6),
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 0,
    color: ThemeColor.WHITE,
  },
  modal: {
    position: 'relative',
    borderRadius: 0,
    overflow: 'inherit',
  },
  image: {
    width: '100%',
  },
  buttonDirection: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: ThemeColor.WHITE,
    background: ThemeColor.BRAND_PRIMARY,
    '&:hover': {
      background: '#00746B',
    },
  },
  buttonPrev: {
    left: -theme.spacing(10),
  },
  buttonNext: {
    right: -theme.spacing(10),
  },
}))

interface ICameraStationPlaneDialog {
  floorId: string
  station: Station
  direction: CameraStationPlaneDirection
  onClose: () => void
}

// TODO FUTURE Make size responsive
const VIEW_WIDTH = window.innerWidth * 0.75
const VIEW_HEIGHT = window.innerHeight * 0.9

const getImageData = (floorId: string, station: Station, direction: CameraStationPlaneDirection) => {
  const entry = station.images.find((i) => i.direction === direction)
  const image = entry !== undefined && entry.images[0] !== undefined ? entry.images[0] : null
  return image !== null
    ? {
        floorId,
        cameraId: station.id,
        timepoint: image.timepoint,
        direction,
        imageName: image.image,
      }
    : null
}

const INITIAL_SIZE: ImageParams = { scale: 1, offset: { x: 0, y: 0 }, imageWidth: VIEW_WIDTH, imageHeight: VIEW_HEIGHT }

const CameraStationPlaneDialog: FunctionComponent<ICameraStationPlaneDialog> = ({
  floorId,
  station,
  direction,
  onClose,
}) => {
  const [selectedDirection, setSelectedDirection] = useState(direction)
  const [imageData, setImageData] = useState(getImageData(floorId, station, selectedDirection))
  const [image, state] = useCameraImage(imageData, false, true)
  const [imageParams, setImageParams] = useState<ImageParams>(INITIAL_SIZE)
  const [zoomLevel, setZoomLevel] = useState(1)
  const imageRef = useRef<Image | null>(null)
  const stageRef = useRef<any>()
  const classes = useStyles()

  const getImageLayer = () => {
    const stage = imageLayerRef.current
    if (stage !== null) {
      return stage
    }
    return null
  }

  const resetImageLayer = () => {
    setZoomLevel(1)
    const imageLayer = getImageLayer()
    if (imageLayer !== null) {
      imageLayer.position({ x: imageParams.offset.x * imageParams.scale, y: imageParams.offset.y * imageParams.scale })
      imageLayer.scale({ x: 1, y: 1 })
      imageLayer.batchDraw()
    }
  }

  const useTrackImageLayerRef = () => {
    const imageLayerRef = useRef<any>()

    const ref = useCallback(
      (node) => {
        if (imageLayerRef !== undefined && imageLayerRef.current !== null && imageLayerRef.current !== undefined) {
          imageLayerRef.current.off('dblclick dbltap')
        }

        if (node !== null) {
          node.on('dblclick dbltap', () => {
            resetImageLayer()
          })
          imageLayerRef.current = node
        }
      },
      [imageParams],
    )

    return [imageLayerRef, ref]
  }

  const [imageLayerRef, imageLayerRefCb] = useTrackImageLayerRef()

  useEffect(() => {
    const newData = getImageData(floorId, station, selectedDirection)
    if (cameraImageDataDiffers(newData, imageData)) {
      setImageData(newData)
    }
  }, [floorId, station, selectedDirection])

  useEffect(() => {
    if (state !== 'loading' && image !== null) {
      setImageParams(calculateImageParams(image, { width: VIEW_WIDTH, height: VIEW_HEIGHT }))
    }
  }, [image, state, VIEW_WIDTH, VIEW_HEIGHT])

  useEffect(() => {
    if (imageRef !== null && imageRef.current !== null) {
      const image = imageRef.current

      if (state === 'loaded' || state === 'missing') {
        if (image.attrs.width > 0) {
          image.cache()
        }
        image.to({ blurRadius: 0, duration: 0.5 })
      }
      if (state === 'loading') {
        image.to({ blurRadius: 50, duration: 0.5 })
        image.getLayer().batchDraw()
      }
    }
  }, [state, imageParams])

  const getStage = () => {
    const stage = stageRef.current
    if (stage !== null && stage instanceof Konva.Stage) {
      return stage
    }
    return null
  }

  const handleMoveImage = (ev: konva.KonvaEventObject<DragEvent>) => {
    const imageLayer = getImageLayer()
    if (imageLayer !== null) {
      imageLayer.position({ x: ev.target.x(), y: ev.target.y() })
    }
  }

  const handleDirection = (dir: -1 | 1) => () => {
    const dirLength = WEB_ORDERED_CAMERA_STATION_PLANE_DIRECTIONS.length
    const currIndex = WEB_ORDERED_CAMERA_STATION_PLANE_DIRECTIONS.findIndex((dir) => dir === selectedDirection)
    const newIndex = currIndex + dir >= dirLength ? 0 : currIndex + dir < 0 ? dirLength - 1 : currIndex + dir
    resetImageLayer()
    setSelectedDirection(WEB_ORDERED_CAMERA_STATION_PLANE_DIRECTIONS[newIndex])
  }

  const zoomImage = (delta: number, atPoint: { x: number; y: number }) => {
    const imageLayer = getImageLayer()
    if (imageLayer === null) {
      return
    }

    const oldScale = zoomLevel
    const pos = {
      x: (atPoint.x - imageLayer.x()) / oldScale,
      y: (atPoint.y - imageLayer.y()) / oldScale,
    }

    const scaleBy = 1.1
    let newScale = delta > 0 ? oldScale * scaleBy : oldScale / scaleBy
    if (newScale < 1) {
      resetImageLayer()
      return
    }

    imageLayer.scale({ x: newScale, y: newScale })
    setZoomLevel(newScale)

    const newPos = {
      x: -(pos.x - atPoint.x / newScale) * newScale,
      y: -(pos.y - atPoint.y / newScale) * newScale,
    }
    imageLayer.position(newPos)
    imageLayer.batchDraw()
  }

  const handleStageWheel = (ev: Konva.KonvaEventObject<WheelEvent>) => {
    ev.evt.preventDefault()

    const stage = getStage()
    if (stage === null) {
      return
    }

    const pointer = stage.getPointerPosition()
    if (pointer === null) {
      return
    }

    zoomImage(-ev.evt.deltaY, pointer)
  }

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.modal }} maxWidth="xl">
      <DialogTitle className={classes.title}>{imageDirectionToLocaleString(selectedDirection)}</DialogTitle>
      <Stage width={VIEW_WIDTH} height={VIEW_HEIGHT} onWheel={handleStageWheel} ref={stageRef}>
        <Layer
          ref={imageLayerRefCb}
          x={imageParams.offset.x * imageParams.scale}
          y={imageParams.offset.y * imageParams.scale}
          draggable={zoomLevel > 1}
          onDragEnd={handleMoveImage}
        >
          <Image
            image={image}
            ref={imageRef}
            width={imageParams.imageWidth}
            height={imageParams.imageHeight}
            filters={[Konva.Filters.Blur]}
          />
        </Layer>

        <Layer>
          <CameraStation
            station={station}
            selectedDirection={selectedDirection}
            customPosition={{ x: 50, y: 50 }}
            size={40}
            onClick={() => {
              return
            }}
            onPlaneClick={(_s, d) => {
              resetImageLayer()
              setSelectedDirection(d)
            }}
            onPlaneHover={() => {
              return
            }}
          />
        </Layer>
      </Stage>

      {state === 'loading' ? <ProgressBar /> : null}

      <IconButton
        onClick={handleDirection(-1)}
        color="primary"
        className={classes.buttonPrev}
        classes={{ colorPrimary: classes.buttonDirection }}
      >
        <ChevronLeftIcon fontSize="large" />
      </IconButton>

      <IconButton
        onClick={handleDirection(1)}
        color="primary"
        className={classes.buttonNext}
        classes={{ colorPrimary: classes.buttonDirection }}
      >
        <ChevronRightIcon fontSize="large" />
      </IconButton>
    </Dialog>
  )
}

export default CameraStationPlaneDialog
