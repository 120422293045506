import React, { FunctionComponent } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

import { ThemeColor } from '@recordset-local/theme'

const useStyles = makeStyles(() => ({
  searchRoot: {
    fontSize: 'inherit',
    '& $searchIcon': {
      opacity: 0.42,
      fontSize: 'inherit',
    },
    '&:hover $searchIcon': {
      opacity: 1,
    },
  },
  searchIcon: {},
  searchInput: {
    background: 'transparent',
  },
  searchMarginDense: {
    padding: 0,
  },
  searchDisabled: {},
  searchFocused: {
    '& $searchIcon': {
      opacity: 1,
    },
  },
  searchUnderline: {
    '&::before': {
      borderBottomColor: 'transparent',
    },
    '&:hover:not($searchDisabled)::before': {
      borderBottomColor: ThemeColor.BRAND_PRIMARY,
    },
    '&$searchFocused::after': {
      borderBottomColor: 'transparent',
      transform: 'none',
    },
  },
}))

interface ISearchInputProps {
  placeholder: string
  value: string
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: FunctionComponent<ISearchInputProps> = ({ placeholder, value, onChange, ...props }) => {
  const classes = useStyles()

  return (
    <TextField
      placeholder={placeholder}
      value={value}
      type="search"
      onChange={onChange}
      size="small"
      InputProps={{
        classes: {
          root: classes.searchRoot,
          input: classes.searchInput,
          disabled: classes.searchDisabled,
          focused: classes.searchFocused,
          marginDense: classes.searchMarginDense,
          underline: classes.searchUnderline,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
      fullWidth
      {...props}
    />
  )
}

export default SearchInput
