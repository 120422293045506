/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptProject
// ====================================================

export interface AcceptProject_view_acceptProject_project_owner {
  __typename: "User";
  email: string;
}

export interface AcceptProject_view_acceptProject_project {
  __typename: "Project";
  id: string;
  name: string;
  owner: AcceptProject_view_acceptProject_project_owner;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
}

export interface AcceptProject_view_acceptProject {
  __typename: "AcceptProjectPayload";
  ok: boolean;
  project: AcceptProject_view_acceptProject_project | null;
  invitationId: string | null;
}

export interface AcceptProject_view {
  __typename: "RootMutationView";
  acceptProject: AcceptProject_view_acceptProject;
}

export interface AcceptProject {
  view: AcceptProject_view;
}

export interface AcceptProjectVariables {
  input: AcceptProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptTerms
// ====================================================

export interface AcceptTerms_view_acceptTerms_device_gpsTerms {
  __typename: "GPS";
  lat: number;
  lon: number;
}

export interface AcceptTerms_view_acceptTerms_device {
  __typename: "Device";
  id: string;
  deviceId: string;
  signupDate: RsDate;
  lastSeenDate: RsDate;
  userTermsAcceptedDate: RsDate | null;
  gpsTerms: AcceptTerms_view_acceptTerms_device_gpsTerms | null;
}

export interface AcceptTerms_view_acceptTerms {
  __typename: "AcceptTermsPayload";
  device: AcceptTerms_view_acceptTerms_device | null;
}

export interface AcceptTerms_view {
  __typename: "RootMutationView";
  acceptTerms: AcceptTerms_view_acceptTerms;
}

export interface AcceptTerms {
  view: AcceptTerms_view;
}

export interface AcceptTermsVariables {
  input: AcceptTermsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCameraStation
// ====================================================

export interface AddCameraStation_view_addCameraStation_cameraStation_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface AddCameraStation_view_addCameraStation_cameraStation_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface AddCameraStation_view_addCameraStation_cameraStation_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: AddCameraStation_view_addCameraStation_cameraStation_imageSets_imageEntries_images[];
}

export interface AddCameraStation_view_addCameraStation_cameraStation_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: AddCameraStation_view_addCameraStation_cameraStation_imageSets_imageEntries[];
}

export interface AddCameraStation_view_addCameraStation_cameraStation_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface AddCameraStation_view_addCameraStation_cameraStation_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: AddCameraStation_view_addCameraStation_cameraStation_images_images[];
}

export interface AddCameraStation_view_addCameraStation_cameraStation {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: AddCameraStation_view_addCameraStation_cameraStation_position;
  timepoints: RsDate[];
  imageSets: AddCameraStation_view_addCameraStation_cameraStation_imageSets[];
  images: AddCameraStation_view_addCameraStation_cameraStation_images[];
}

export interface AddCameraStation_view_addCameraStation {
  __typename: "AddCameraStationPayload";
  projectId: string;
  floorId: string;
  cameraStation: AddCameraStation_view_addCameraStation_cameraStation;
}

export interface AddCameraStation_view {
  __typename: "RootMutationView";
  addCameraStation: AddCameraStation_view_addCameraStation;
}

export interface AddCameraStation {
  view: AddCameraStation_view;
}

export interface AddCameraStationVariables {
  input: AddCameraStationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddFloor
// ====================================================

export interface AddFloor_view_addFloor_floor_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface AddFloor_view_addFloor_floor_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface AddFloor_view_addFloor_floor_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface AddFloor_view_addFloor_floor_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: AddFloor_view_addFloor_floor_stations_imageSets_imageEntries_images[];
}

export interface AddFloor_view_addFloor_floor_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: AddFloor_view_addFloor_floor_stations_imageSets_imageEntries[];
}

export interface AddFloor_view_addFloor_floor_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface AddFloor_view_addFloor_floor_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: AddFloor_view_addFloor_floor_stations_images_images[];
}

export interface AddFloor_view_addFloor_floor_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: AddFloor_view_addFloor_floor_stations_position;
  timepoints: RsDate[];
  imageSets: AddFloor_view_addFloor_floor_stations_imageSets[];
  images: AddFloor_view_addFloor_floor_stations_images[];
}

export interface AddFloor_view_addFloor_floor {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: AddFloor_view_addFloor_floor_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: AddFloor_view_addFloor_floor_stations[];
}

export interface AddFloor_view_addFloor {
  __typename: "AddFloorPayload";
  projectId: string;
  floor: AddFloor_view_addFloor_floor;
}

export interface AddFloor_view {
  __typename: "RootMutationView";
  addFloor: AddFloor_view_addFloor;
}

export interface AddFloor {
  view: AddFloor_view;
}

export interface AddFloorVariables {
  input: AddFloorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddProject
// ====================================================

export interface AddProject_view_addProject_owner {
  __typename: "User";
  id: string;
  email: string;
}

export interface AddProject_view_addProject_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface AddProject_view_addProject_issuedInvitations {
  __typename: "Invitation";
  id: string;
  invitedEmail: string | null;
  invitedPhone: string | null;
  expiresAt: RsDate;
  state: InvitationState;
}

export interface AddProject_view_addProject_sharedWith {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface AddProject_view_addProject_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface AddProject_view_addProject_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface AddProject_view_addProject_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: AddProject_view_addProject_floors_stations_position;
  timepoints: RsDate[];
}

export interface AddProject_view_addProject_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: AddProject_view_addProject_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: AddProject_view_addProject_floors_stations[];
}

export interface AddProject_view_addProject {
  __typename: "Project";
  id: string;
  name: string;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  owner: AddProject_view_addProject_owner;
  payment: AddProject_view_addProject_payment | null;
  issuedInvitations: AddProject_view_addProject_issuedInvitations[];
  sharedWith: AddProject_view_addProject_sharedWith[];
  linkUserCount: number;
  floors: AddProject_view_addProject_floors[];
}

export interface AddProject_view {
  __typename: "RootMutationView";
  addProject: AddProject_view_addProject | null;
}

export interface AddProject {
  view: AddProject_view;
}

export interface AddProjectVariables {
  data: NewProject;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineProject
// ====================================================

export interface DeclineProject_view_declineProject {
  __typename: "DeclineProjectPayload";
  ok: boolean;
  invitationId: string;
}

export interface DeclineProject_view {
  __typename: "RootMutationView";
  declineProject: DeclineProject_view_declineProject;
}

export interface DeclineProject {
  view: DeclineProject_view;
}

export interface DeclineProjectVariables {
  input: DeclineProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCameraStation
// ====================================================

export interface DeleteCameraStation_view_deleteCameraStation {
  __typename: "DeleteCameraStationPayload";
  cameraStationId: string;
  projectId: string;
  floorId: string;
  userDeletedDate: RsDate;
}

export interface DeleteCameraStation_view {
  __typename: "RootMutationView";
  deleteCameraStation: DeleteCameraStation_view_deleteCameraStation;
}

export interface DeleteCameraStation {
  view: DeleteCameraStation_view;
}

export interface DeleteCameraStationVariables {
  input: DeleteCameraStationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFloor
// ====================================================

export interface DeleteFloor_view_deleteFloor {
  __typename: "DeleteFloorPayload";
  id: string;
  projectId: string;
}

export interface DeleteFloor_view {
  __typename: "RootMutationView";
  deleteFloor: DeleteFloor_view_deleteFloor;
}

export interface DeleteFloor {
  view: DeleteFloor_view;
}

export interface DeleteFloorVariables {
  input: DeleteFloorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProject
// ====================================================

export interface DeleteProject_view_deleteProject {
  __typename: "DeleteProjectPayload";
  id: string;
}

export interface DeleteProject_view {
  __typename: "RootMutationView";
  deleteProject: DeleteProject_view_deleteProject;
}

export interface DeleteProject {
  view: DeleteProject_view;
}

export interface DeleteProjectVariables {
  input: DeleteProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProject
// ====================================================

export interface GetProject_view_project_owner {
  __typename: "User";
  id: string;
  email: string;
}

export interface GetProject_view_project_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface GetProject_view_project_issuedInvitations {
  __typename: "Invitation";
  id: string;
  invitedEmail: string | null;
  invitedPhone: string | null;
  expiresAt: RsDate;
  state: InvitationState;
}

export interface GetProject_view_project_sharedWith {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface GetProject_view_project_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface GetProject_view_project_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface GetProject_view_project_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: GetProject_view_project_floors_stations_position;
  timepoints: RsDate[];
}

export interface GetProject_view_project_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: GetProject_view_project_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: GetProject_view_project_floors_stations[];
}

export interface GetProject_view_project {
  __typename: "Project";
  id: string;
  name: string;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  owner: GetProject_view_project_owner;
  payment: GetProject_view_project_payment | null;
  issuedInvitations: GetProject_view_project_issuedInvitations[];
  sharedWith: GetProject_view_project_sharedWith[];
  linkUserCount: number;
  floors: GetProject_view_project_floors[];
}

export interface GetProject_view {
  __typename: "RootView";
  project: GetProject_view_project | null;
}

export interface GetProject {
  view: GetProject_view;
}

export interface GetProjectVariables {
  input: ProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProjectFloorsAtTimepoint
// ====================================================

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_imageSets_imageEntries_images[];
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_imageSets_imageEntries[];
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_images_images[];
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_position;
  timepoints: RsDate[];
  imageSets: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_imageSets[];
  images: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations_images[];
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors_stations[];
}

export interface GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint {
  __typename: "ProjectFloorsAtTimepointPayload";
  floors: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint_floors[];
}

export interface GetProjectFloorsAtTimepoint_view {
  __typename: "RootView";
  projectFloorsAtTimepoint: GetProjectFloorsAtTimepoint_view_projectFloorsAtTimepoint;
}

export interface GetProjectFloorsAtTimepoint {
  view: GetProjectFloorsAtTimepoint_view;
}

export interface GetProjectFloorsAtTimepointVariables {
  input: ProjectFloorsAtTimepointInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProjectFloorsWithAllImages
// ====================================================

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_imageSets_imageEntries_images[];
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_imageSets_imageEntries[];
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_images_images[];
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_position;
  timepoints: RsDate[];
  imageSets: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_imageSets[];
  images: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations_images[];
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors_stations[];
}

export interface GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages {
  __typename: "ProjectFloorsWithAllImagesPayload";
  floors: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages_floors[];
}

export interface GetProjectFloorsWithAllImages_view {
  __typename: "RootView";
  projectFloorsWithAllImages: GetProjectFloorsWithAllImages_view_projectFloorsWithAllImages;
}

export interface GetProjectFloorsWithAllImages {
  view: GetProjectFloorsWithAllImages_view;
}

export interface GetProjectFloorsWithAllImagesVariables {
  input: ProjectFloorsWithAllImagesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProjectWithEntireHistory
// ====================================================

export interface GetProjectWithEntireHistory_view_project_owner {
  __typename: "User";
  id: string;
  email: string;
}

export interface GetProjectWithEntireHistory_view_project_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface GetProjectWithEntireHistory_view_project_issuedInvitations {
  __typename: "Invitation";
  id: string;
  invitedEmail: string | null;
  invitedPhone: string | null;
  expiresAt: RsDate;
  state: InvitationState;
}

export interface GetProjectWithEntireHistory_view_project_sharedWith {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface GetProjectWithEntireHistory_view_project_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface GetProjectWithEntireHistory_view_project_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface GetProjectWithEntireHistory_view_project_floors_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface GetProjectWithEntireHistory_view_project_floors_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: GetProjectWithEntireHistory_view_project_floors_stations_imageSets_imageEntries_images[];
}

export interface GetProjectWithEntireHistory_view_project_floors_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: GetProjectWithEntireHistory_view_project_floors_stations_imageSets_imageEntries[];
}

export interface GetProjectWithEntireHistory_view_project_floors_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface GetProjectWithEntireHistory_view_project_floors_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: GetProjectWithEntireHistory_view_project_floors_stations_images_images[];
}

export interface GetProjectWithEntireHistory_view_project_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: GetProjectWithEntireHistory_view_project_floors_stations_position;
  timepoints: RsDate[];
  imageSets: GetProjectWithEntireHistory_view_project_floors_stations_imageSets[];
  images: GetProjectWithEntireHistory_view_project_floors_stations_images[];
}

export interface GetProjectWithEntireHistory_view_project_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: GetProjectWithEntireHistory_view_project_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: GetProjectWithEntireHistory_view_project_floors_stations[];
}

export interface GetProjectWithEntireHistory_view_project {
  __typename: "Project";
  id: string;
  name: string;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  owner: GetProjectWithEntireHistory_view_project_owner;
  payment: GetProjectWithEntireHistory_view_project_payment | null;
  issuedInvitations: GetProjectWithEntireHistory_view_project_issuedInvitations[];
  sharedWith: GetProjectWithEntireHistory_view_project_sharedWith[];
  linkUserCount: number;
  floors: GetProjectWithEntireHistory_view_project_floors[];
}

export interface GetProjectWithEntireHistory_view {
  __typename: "RootView";
  project: GetProjectWithEntireHistory_view_project | null;
}

export interface GetProjectWithEntireHistory {
  view: GetProjectWithEntireHistory_view;
}

export interface GetProjectWithEntireHistoryVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PendingInvitations
// ====================================================

export interface PendingInvitations_view_pendingInvitations_invitations_invitedBy {
  __typename: "User";
  name: string | null;
  email: string;
}

export interface PendingInvitations_view_pendingInvitations_invitations {
  __typename: "Invitation";
  id: string;
  invitedBy: PendingInvitations_view_pendingInvitations_invitations_invitedBy;
  projectId: string;
  projectName: string;
  expiresAt: RsDate;
}

export interface PendingInvitations_view_pendingInvitations {
  __typename: "PendingInvitationsPayload";
  invitations: PendingInvitations_view_pendingInvitations_invitations[];
}

export interface PendingInvitations_view {
  __typename: "RootView";
  pendingInvitations: PendingInvitations_view_pendingInvitations;
}

export interface PendingInvitations {
  view: PendingInvitations_view;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectList
// ====================================================

export interface ProjectList_view_projects_pageInfo {
  __typename: "PageInfo";
  cursor: string;
  total: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface ProjectList_view_projects_owner {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface ProjectList_view_projects_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface ProjectList_view_projects {
  __typename: "Project";
  pageInfo: ProjectList_view_projects_pageInfo;
  id: string;
  name: string;
  owner: ProjectList_view_projects_owner;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  payment: ProjectList_view_projects_payment | null;
}

export interface ProjectList_view {
  __typename: "RootView";
  projects: ProjectList_view_projects[];
}

export interface ProjectList {
  view: ProjectList_view;
}

export interface ProjectListVariables {
  page: Page;
  filter?: ProjectFilter | null;
  sorting?: ProjectSorting | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReportProblem
// ====================================================

export interface ReportProblem_view_reportProblem {
  __typename: "ReportProblemPayload";
  userId: string;
}

export interface ReportProblem_view {
  __typename: "RootMutationView";
  reportProblem: ReportProblem_view_reportProblem;
}

export interface ReportProblem {
  view: ReportProblem_view;
}

export interface ReportProblemVariables {
  input: ReportProblemInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetInstallReferringParams
// ====================================================

export interface SetInstallReferringParams_view_setInstallReferringParams {
  __typename: "SetInstallReferringParamsPayload";
  deviceId: string;
}

export interface SetInstallReferringParams_view {
  __typename: "RootMutationView";
  setInstallReferringParams: SetInstallReferringParams_view_setInstallReferringParams;
}

export interface SetInstallReferringParams {
  view: SetInstallReferringParams_view;
}

export interface SetInstallReferringParamsVariables {
  input: SetInstallReferringParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPushNotificationsToken
// ====================================================

export interface SetPushNotificationsToken_view_setPushNotificationsToken {
  __typename: "SetPushNotificationsTokenPayload";
  pushNotificationsToken: string;
}

export interface SetPushNotificationsToken_view {
  __typename: "RootMutationView";
  setPushNotificationsToken: SetPushNotificationsToken_view_setPushNotificationsToken;
}

export interface SetPushNotificationsToken {
  view: SetPushNotificationsToken_view;
}

export interface SetPushNotificationsTokenVariables {
  input: SetPushNotificationsTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareProject
// ====================================================

export interface ShareProject_view_shareProject {
  __typename: "ShareProjectPayload";
  ok: boolean;
}

export interface ShareProject_view {
  __typename: "RootMutationView";
  shareProject: ShareProject_view_shareProject;
}

export interface ShareProject {
  view: ShareProject_view;
}

export interface ShareProjectVariables {
  input: ShareProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCameraStationImages
// ====================================================

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_imageSets_imageEntries_images[];
}

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_imageSets_imageEntries[];
}

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_images_images[];
}

export interface UpdateCameraStationImages_view_updateCameraStationImages_cameraStation {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_position;
  timepoints: RsDate[];
  imageSets: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_imageSets[];
  images: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation_images[];
}

export interface UpdateCameraStationImages_view_updateCameraStationImages {
  __typename: "UpdateCameraStationImagesPayload";
  projectId: string;
  floorId: string;
  userProjectLastEditDate: RsDate;
  timepoint: RsDate;
  cameraStation: UpdateCameraStationImages_view_updateCameraStationImages_cameraStation;
}

export interface UpdateCameraStationImages_view {
  __typename: "RootMutationView";
  updateCameraStationImages: UpdateCameraStationImages_view_updateCameraStationImages;
}

export interface UpdateCameraStationImages {
  view: UpdateCameraStationImages_view;
}

export interface UpdateCameraStationImagesVariables {
  input: UpdateCameraStationImagesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCameraStationPosition
// ====================================================

export interface UpdateCameraStationPosition_view_updateCameraStationPosition_position {
  __typename: "CameraPosition";
  userLastEditDate: RsDate;
  lastEditDate: RsDate;
  x: number;
  y: number;
}

export interface UpdateCameraStationPosition_view_updateCameraStationPosition {
  __typename: "UpdateCameraStationPositionPayload";
  cameraStationId: string;
  projectId: string;
  floorId: string;
  position: UpdateCameraStationPosition_view_updateCameraStationPosition_position;
}

export interface UpdateCameraStationPosition_view {
  __typename: "RootMutationView";
  updateCameraStationPosition: UpdateCameraStationPosition_view_updateCameraStationPosition;
}

export interface UpdateCameraStationPosition {
  view: UpdateCameraStationPosition_view;
}

export interface UpdateCameraStationPositionVariables {
  input: UpdateCameraStationPositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFloor
// ====================================================

export interface UpdateFloor_view_updateFloor_floor_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface UpdateFloor_view_updateFloor_floor_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface UpdateFloor_view_updateFloor_floor_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface UpdateFloor_view_updateFloor_floor_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: UpdateFloor_view_updateFloor_floor_stations_imageSets_imageEntries_images[];
}

export interface UpdateFloor_view_updateFloor_floor_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: UpdateFloor_view_updateFloor_floor_stations_imageSets_imageEntries[];
}

export interface UpdateFloor_view_updateFloor_floor_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface UpdateFloor_view_updateFloor_floor_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: UpdateFloor_view_updateFloor_floor_stations_images_images[];
}

export interface UpdateFloor_view_updateFloor_floor_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: UpdateFloor_view_updateFloor_floor_stations_position;
  timepoints: RsDate[];
  imageSets: UpdateFloor_view_updateFloor_floor_stations_imageSets[];
  images: UpdateFloor_view_updateFloor_floor_stations_images[];
}

export interface UpdateFloor_view_updateFloor_floor {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: UpdateFloor_view_updateFloor_floor_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: UpdateFloor_view_updateFloor_floor_stations[];
}

export interface UpdateFloor_view_updateFloor {
  __typename: "UpdateFloorPayload";
  projectId: string;
  floor: UpdateFloor_view_updateFloor_floor;
}

export interface UpdateFloor_view {
  __typename: "RootMutationView";
  updateFloor: UpdateFloor_view_updateFloor;
}

export interface UpdateFloor {
  view: UpdateFloor_view;
}

export interface UpdateFloorVariables {
  input: UpdateFloorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFloorPlan
// ====================================================

export interface UpdateFloorPlan_view_updateFloorPlan_floorPlan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  userLastEditDate: RsDate;
  imageSource: ImageSource;
}

export interface UpdateFloorPlan_view_updateFloorPlan {
  __typename: "UpdateFloorPlanPayload";
  projectId: string;
  floorId: string;
  floorPlan: UpdateFloorPlan_view_updateFloorPlan_floorPlan;
}

export interface UpdateFloorPlan_view {
  __typename: "RootMutationView";
  updateFloorPlan: UpdateFloorPlan_view_updateFloorPlan;
}

export interface UpdateFloorPlan {
  view: UpdateFloorPlan_view;
}

export interface UpdateFloorPlanVariables {
  input: UpdateFloorPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProject
// ====================================================

export interface UpdateProject_view_updateProject {
  __typename: "UpdateProjectPayload";
  projectId: string;
  name: string;
  userLastEditDate: RsDate;
}

export interface UpdateProject_view {
  __typename: "RootMutationView";
  updateProject: UpdateProject_view_updateProject;
}

export interface UpdateProject {
  view: UpdateProject_view;
}

export interface UpdateProjectVariables {
  input: UpdateProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_identity_user_gpsTerms {
  __typename: "GPS";
  lat: number;
  lon: number;
}

export interface User_identity_user {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
  emailConfirmedDate: RsDate | null;
  signupDate: RsDate;
  lastSeenDate: RsDate;
  userTermsAcceptedDate: RsDate | null;
  gpsTerms: User_identity_user_gpsTerms | null;
}

export interface User_identity_linkUser {
  __typename: "LinkUser";
  id: string;
  projectId: string;
  demoProject: boolean | null;
}

export interface User_identity_device_gpsTerms {
  __typename: "GPS";
  lat: number;
  lon: number;
}

export interface User_identity_device {
  __typename: "Device";
  id: string;
  deviceId: string;
  signupDate: RsDate;
  lastSeenDate: RsDate;
  userTermsAcceptedDate: RsDate | null;
  gpsTerms: User_identity_device_gpsTerms | null;
}

export interface User_identity {
  __typename: "Identity";
  user: User_identity_user | null;
  linkUser: User_identity_linkUser | null;
  device: User_identity_device | null;
}

export interface User {
  identity: User_identity;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CameraStationDetails
// ====================================================

export interface CameraStationDetails_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface CameraStationDetails_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface CameraStationDetails_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: CameraStationDetails_imageSets_imageEntries_images[];
}

export interface CameraStationDetails_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: CameraStationDetails_imageSets_imageEntries[];
}

export interface CameraStationDetails_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface CameraStationDetails_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: CameraStationDetails_images_images[];
}

export interface CameraStationDetails {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: CameraStationDetails_position;
  timepoints: RsDate[];
  imageSets: CameraStationDetails_imageSets[];
  images: CameraStationDetails_images[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CameraStationDetailsWithoutImages
// ====================================================

export interface CameraStationDetailsWithoutImages_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface CameraStationDetailsWithoutImages {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: CameraStationDetailsWithoutImages_position;
  timepoints: RsDate[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FloorDetails
// ====================================================

export interface FloorDetails_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface FloorDetails_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface FloorDetails_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface FloorDetails_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: FloorDetails_stations_imageSets_imageEntries_images[];
}

export interface FloorDetails_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: FloorDetails_stations_imageSets_imageEntries[];
}

export interface FloorDetails_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface FloorDetails_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: FloorDetails_stations_images_images[];
}

export interface FloorDetails_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: FloorDetails_stations_position;
  timepoints: RsDate[];
  imageSets: FloorDetails_stations_imageSets[];
  images: FloorDetails_stations_images[];
}

export interface FloorDetails {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: FloorDetails_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: FloorDetails_stations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FloorDetailsWithoutImages
// ====================================================

export interface FloorDetailsWithoutImages_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface FloorDetailsWithoutImages_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface FloorDetailsWithoutImages_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: FloorDetailsWithoutImages_stations_position;
  timepoints: RsDate[];
}

export interface FloorDetailsWithoutImages {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: FloorDetailsWithoutImages_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: FloorDetailsWithoutImages_stations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FloorDetailsWithoutStations
// ====================================================

export interface FloorDetailsWithoutStations_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface FloorDetailsWithoutStations {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: FloorDetailsWithoutStations_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectDetails
// ====================================================

export interface ProjectDetails_owner {
  __typename: "User";
  id: string;
  email: string;
}

export interface ProjectDetails_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface ProjectDetails_issuedInvitations {
  __typename: "Invitation";
  id: string;
  invitedEmail: string | null;
  invitedPhone: string | null;
  expiresAt: RsDate;
  state: InvitationState;
}

export interface ProjectDetails_sharedWith {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface ProjectDetails {
  __typename: "Project";
  id: string;
  name: string;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  owner: ProjectDetails_owner;
  payment: ProjectDetails_payment | null;
  issuedInvitations: ProjectDetails_issuedInvitations[];
  sharedWith: ProjectDetails_sharedWith[];
  linkUserCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectDetailsWithImages
// ====================================================

export interface ProjectDetailsWithImages_owner {
  __typename: "User";
  id: string;
  email: string;
}

export interface ProjectDetailsWithImages_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface ProjectDetailsWithImages_issuedInvitations {
  __typename: "Invitation";
  id: string;
  invitedEmail: string | null;
  invitedPhone: string | null;
  expiresAt: RsDate;
  state: InvitationState;
}

export interface ProjectDetailsWithImages_sharedWith {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface ProjectDetailsWithImages_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface ProjectDetailsWithImages_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface ProjectDetailsWithImages_floors_stations_imageSets_imageEntries_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface ProjectDetailsWithImages_floors_stations_imageSets_imageEntries {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: ProjectDetailsWithImages_floors_stations_imageSets_imageEntries_images[];
}

export interface ProjectDetailsWithImages_floors_stations_imageSets {
  __typename: "CameraStationImageSet";
  id: string;
  timepoint: RsDate;
  imageEntries: ProjectDetailsWithImages_floors_stations_imageSets_imageEntries[];
}

export interface ProjectDetailsWithImages_floors_stations_images_images {
  __typename: "CameraImage";
  userCreatedDate: RsDate;
  timepoint: RsDate;
  image: RsURL;
}

export interface ProjectDetailsWithImages_floors_stations_images {
  __typename: "CameraImageEntry";
  direction: ImageDirection;
  images: ProjectDetailsWithImages_floors_stations_images_images[];
}

export interface ProjectDetailsWithImages_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: ProjectDetailsWithImages_floors_stations_position;
  timepoints: RsDate[];
  imageSets: ProjectDetailsWithImages_floors_stations_imageSets[];
  images: ProjectDetailsWithImages_floors_stations_images[];
}

export interface ProjectDetailsWithImages_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: ProjectDetailsWithImages_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: ProjectDetailsWithImages_floors_stations[];
}

export interface ProjectDetailsWithImages {
  __typename: "Project";
  id: string;
  name: string;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  owner: ProjectDetailsWithImages_owner;
  payment: ProjectDetailsWithImages_payment | null;
  issuedInvitations: ProjectDetailsWithImages_issuedInvitations[];
  sharedWith: ProjectDetailsWithImages_sharedWith[];
  linkUserCount: number;
  floors: ProjectDetailsWithImages_floors[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectDetailsWithoutImages
// ====================================================

export interface ProjectDetailsWithoutImages_owner {
  __typename: "User";
  id: string;
  email: string;
}

export interface ProjectDetailsWithoutImages_payment {
  __typename: "Payment";
  confirmedDate: RsDate | null;
}

export interface ProjectDetailsWithoutImages_issuedInvitations {
  __typename: "Invitation";
  id: string;
  invitedEmail: string | null;
  invitedPhone: string | null;
  expiresAt: RsDate;
  state: InvitationState;
}

export interface ProjectDetailsWithoutImages_sharedWith {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
}

export interface ProjectDetailsWithoutImages_floors_plan {
  __typename: "FloorPlan";
  lastEditDate: RsDate;
  area: number | null;
  image: RsURL;
  imageSource: ImageSource;
}

export interface ProjectDetailsWithoutImages_floors_stations_position {
  __typename: "CameraPosition";
  x: number;
  y: number;
}

export interface ProjectDetailsWithoutImages_floors_stations {
  __typename: "CameraStation";
  id: string;
  index: number;
  label: string;
  floorLevel: number;
  userCreatedDate: RsDate;
  position: ProjectDetailsWithoutImages_floors_stations_position;
  timepoints: RsDate[];
}

export interface ProjectDetailsWithoutImages_floors {
  __typename: "Floor";
  id: string;
  name: string | null;
  level: number;
  plan: ProjectDetailsWithoutImages_floors_plan | null;
  notes: string | null;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  stations: ProjectDetailsWithoutImages_floors_stations[];
}

export interface ProjectDetailsWithoutImages {
  __typename: "Project";
  id: string;
  name: string;
  userCreatedDate: RsDate;
  userLastEditDate: RsDate;
  owner: ProjectDetailsWithoutImages_owner;
  payment: ProjectDetailsWithoutImages_payment | null;
  issuedInvitations: ProjectDetailsWithoutImages_issuedInvitations[];
  sharedWith: ProjectDetailsWithoutImages_sharedWith[];
  linkUserCount: number;
  floors: ProjectDetailsWithoutImages_floors[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Defines possible directions for a camera and identifies the direction in which a photo was taken.
 */
export enum ImageDirection {
  DE = "DE",
  DN = "DN",
  DNE = "DNE",
  DNW = "DNW",
  DS = "DS",
  DSE = "DSE",
  DSW = "DSW",
  DW = "DW",
  E = "E",
  N = "N",
  NE = "NE",
  NW = "NW",
  S = "S",
  SE = "SE",
  SW = "SW",
  UP = "UP",
  W = "W",
}

/**
 * Defines a source of the image
 */
export enum ImageSource {
  C = "C",
  G = "G",
}

export enum InvitationState {
  A = "A",
  D = "D",
  P = "P",
}

export enum ProjectSortField {
  NAME = "NAME",
  USER_CREATED_DATE = "USER_CREATED_DATE",
  USER_EDIT_DATE = "USER_EDIT_DATE",
}

/**
 * Sort order to keep names consistent in all parts of the application
 */
export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

/**
 * Defines roles a user can be assigned on a project
 */
export enum UserRole {
  ARCHITECT = "ARCHITECT",
  DEVELOPER = "DEVELOPER",
  ELECTRICAL_SUBCONTRACTOR = "ELECTRICAL_SUBCONTRACTOR",
  GENERAL_CONTRACTOR = "GENERAL_CONTRACTOR",
  INSURANCE_AGENT = "INSURANCE_AGENT",
  LANDLORD = "LANDLORD",
  LOWVOLTAGE_SUBCOTRACTOR = "LOWVOLTAGE_SUBCOTRACTOR",
  OWNER = "OWNER",
  PLUMBING_SUNCONTRACTOR = "PLUMBING_SUNCONTRACTOR",
  PROPERTY_MANAGER = "PROPERTY_MANAGER",
  SUPERINTENDENT = "SUPERINTENDENT",
}

export interface AcceptProjectInput {
  token?: string | null;
  invitationId?: string | null;
  projectId?: string | null;
  email?: string | null;
  name?: string | null;
}

export interface AcceptTermsInput {
  deviceId: string;
  gpsTerms?: NewGPS | null;
  userAcceptedDate: RsDate;
}

export interface AddCameraStationInput {
  id: string;
  userCreatedDate: RsDate;
  projectId: string;
  floorId: FloorID;
  floorLevel: number;
  label: string;
  index: number;
  position: CameraStationPositionUpdate;
}

export interface AddFloorInput {
  projectId: string;
  data: NewFloor;
}

export interface CameraStationImageUpdate {
  direction: ImageDirection;
  image: RsURL;
  userCreatedDate: RsDate;
  imageSource: ImageSource;
  gps?: NewGPS | null;
}

export interface CameraStationPositionUpdate {
  x: number;
  y: number;
  userLastEditDate: RsDate;
}

export interface DeclineProjectInput {
  invitationId: string;
}

export interface DeleteCameraStationInput {
  cameraStationId: string;
  projectId: string;
  floorId: FloorID;
  userDeletedDate: RsDate;
}

export interface DeleteFloorInput {
  id: FloorID;
  projectId: string;
  userDeletedDate: RsDate;
}

export interface DeleteProjectInput {
  id: string;
  userDeletedDate: RsDate;
}

export interface FloorFullID {
  projectId: string;
  id: string;
}

export interface FloorID {
  fullId?: FloorFullID | null;
  pathId?: RsPathID | null;
}

export interface NewFloor {
  id: string;
  userCreatedDate: RsDate;
  level: number;
  plan?: NewFloorPlan | null;
  notes?: string | null;
}

export interface NewFloorPlan {
  id: string;
  userCreatedDate: RsDate;
  image: RsURL;
  imageSource: ImageSource;
  area?: number | null;
}

export interface NewGPS {
  lat: number;
  lon: number;
}

export interface NewProject {
  id: string;
  userId: string;
  userEmail?: string | null;
  name: string;
  os: string;
  deviceInfo?: string | null;
  address?: string | null;
  gps?: NewGPS | null;
  ownerRole: UserRole;
  userCreatedDate: RsDate;
}

/**
 * Input type to query paginated data
 * 
 * first/after pair is used to paginate from start to end, e.g { first: 10, after: "<cursor>" } returns
 * first 10 entries after an item specified by 'after' cursor
 * 
 * last/before pair is used to paginate backwards.
 * 
 * after/before cursor values are opaque and returned along with the initial set of pagainted items
 */
export interface Page {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

export interface ProjectFilter {
  name?: string | null;
  owner?: string | null;
}

export interface ProjectFloorsAtTimepointInput {
  projectId: string;
  timepoint: RsDate;
}

export interface ProjectFloorsWithAllImagesInput {
  projectId: string;
}

export interface ProjectInput {
  id: string;
  entireHistory?: boolean | null;
}

export interface ProjectSorting {
  field: ProjectSortField;
  direction: SortOrder;
}

export interface ReportProblemInput {
  userId: string;
  isRegisteredUser: boolean;
  problemDescription: string;
  formEmail?: string | null;
  userEmail?: string | null;
  os?: string | null;
  deviceInfo?: string | null;
  diagnosticData?: string | null;
}

export interface SetInstallReferringParamsInput {
  deviceId: string;
  installReferringParamsJSON: string;
}

export interface SetPushNotificationsTokenInput {
  deviceId?: string | null;
  userId?: string | null;
  pushNotificationsToken: string;
  os: string;
}

export interface ShareProjectInput {
  token: string;
  projectId: string;
  withEmail?: string | null;
  withPhone?: string | null;
  branchLink: string;
}

/**
 * Set of updated camera images is always related to 1 timepoint
 */
export interface UpdateCameraStationImagesInput {
  cameraStationId: string;
  projectId: string;
  floorId: FloorID;
  userProjectLastEditDate: RsDate;
  timepoint: RsDate;
  update: CameraStationImageUpdate[];
}

export interface UpdateCameraStationPositionInput {
  cameraStationId: string;
  projectId: string;
  floorId: FloorID;
  userProjectLastEditDate: RsDate;
  position: CameraStationPositionUpdate;
}

export interface UpdateFloorInput {
  projectId: string;
  floorId: string;
  name?: string | null;
  userLastEditDate: RsDate;
}

export interface UpdateFloorPlanInput {
  id: FloorID;
  projectId: string;
  userLastEditDate: RsDate;
  image?: RsURL | null;
  imageSource?: ImageSource | null;
  area?: number | null;
}

export interface UpdateProjectInput {
  projectId: string;
  name: string;
  userLastEditDate: RsDate;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
