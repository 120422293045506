import React, { FunctionComponent, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ShareIcon from '@material-ui/icons/Share'
import DeleteIcon from '@material-ui/icons/Close'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Person'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'
import * as Sentry from '@sentry/browser'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import { callApi } from '@recordset-local/core/web/request'
import { signDataRequest } from '@recordset-local/core/api/requests/login'
import { ShareProjectData } from '@recordset-local/core/services/branch'
import ShareProject from '@recordset-local/types/graphql/ShareProject.graphql'
import { isRegisteredUser } from '@recordset-local/core/utils/login'

import { createShareProjectLink, sendAppInstallSms } from '@/services/branch'
import { isEmail, isPhone } from '@recordset-local/core/utils'

import ProgressBar from './ProgressBar'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '50vw',
    },
  },
  listItemText: {
    fontSize: '0.875rem',
  },
  listItemAction: {
    right: 0,
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    flexGrow: 1,
    margin: theme.spacing(4, 2, 4, 0),
  },
  buttons: {
    position: 'relative',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  shareIcon: {
    marginRight: theme.spacing(1),
  },
}))

interface IProjectShareFormProps {
  projectId: string
  projectName: string
  onClose: () => void
}

const ProjectShareForm: FunctionComponent<IProjectShareFormProps> = ({ projectId, projectName, onClose }) => {
  const classes = useStyles()
  const { user } = useCurrentAuthGQLUser()
  const [inputString, setInputString] = useState('')
  const [inputError, setInputError] = useState(false)
  const [sending, setSending] = useState(false)
  const [values, setValues] = useState<{ type: 'phone' | 'email'; value: string }[]>([])
  const [shareProject] = useMutation<Types.ShareProject>(ShareProject)

  const handleInvite = async ({ withEmail, withPhone }: { withEmail?: string; withPhone?: string }) => {
    if (!isRegisteredUser(user)) {
      return
    }

    try {
      const { token } = await callApi(signDataRequest, { projectId })
      const shareProjectData: ShareProjectData = {
        sharerId: user.id,
        sharerName: user.email,
        projectId,
        projectName,
        shareWithEmail: withEmail,
        shareWithPhone: withPhone,
        token,
      }
      const link = await createShareProjectLink('webapp', shareProjectData)

      const variables: Types.ShareProjectVariables = {
        input: {
          token,
          projectId,
          withEmail,
          withPhone,
          branchLink: link,
        },
      }
      const result = await shareProject({ variables })
      if (withPhone !== undefined && result.data !== undefined && result.data.view.shareProject.ok) {
        await sendAppInstallSms('webapp', withPhone, shareProjectData)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleInvites = async () => {
    // TODO: show failed, can offer retry for failed requests?
    setSending(true)
    const promises = values.map(async ({ type, value }) =>
      type === 'email' ? handleInvite({ withEmail: value }) : handleInvite({ withPhone: value }),
    )
    await Promise.all(promises)
    setSending(false)
    onClose()
  }

  const handleInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputString(ev.target.value)
  }

  const handleAddValue = () => {
    if (values.find((v) => v.value === inputString) === undefined) {
      const type = isPhone(inputString) ? 'phone' : isEmail(inputString) ? 'email' : null
      if (type !== null) {
        setValues(values.concat({ type, value: inputString }))
        setInputString('')
        setInputError(false)
      } else {
        setInputError(true)
      }
    }
  }

  const handleDeleteValue = ({ value }: { value: string }) => () => {
    setValues(values.filter((v) => v.value !== value))
  }

  const handleEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      handleAddValue()
    }
  }

  const renderShareList = () => (
    <List disablePadding>
      {values.map((input) => (
        <ListItem disableGutters key={input.value}>
          <ListItemAvatar>
            <Avatar>{input.type === 'phone' ? <PhoneIcon fontSize="small" /> : <EmailIcon fontSize="small" />}</Avatar>
          </ListItemAvatar>
          <ListItemText disableTypography primary={input.value} classes={{ root: classes.listItemText }} />
          <ListItemSecondaryAction classes={{ root: classes.listItemAction }}>
            <IconButton aria-label="Delete" onClick={handleDeleteValue(input)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )

  return (
    <I18n>
      {({ i18n }) => (
        <Dialog open={true} onClose={onClose} maxWidth="sm" classes={{ paper: classes.modal }}>
          <DialogTitle disableTypography>Project invitation</DialogTitle>
          <DialogContent dividers>
            <DialogContentText color="textPrimary">Send invite</DialogContentText>
            <Box className={classes.inputWrapper}>
              <TextField
                value={inputString}
                placeholder={i18n._(t('input.email_or_phone_recipient_placeholder')`Email address or phone number`)}
                onChange={handleInput}
                className={classes.textField}
                onKeyPress={handleEnter}
                error={inputError}
                helperText={
                  inputError ? (
                    <Trans id="share.invalid_input">Please enter a valid email address or a phone number</Trans>
                  ) : null
                }
              />
              <IconButton color="primary" onClick={handleAddValue}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
            {renderShareList()}
          </DialogContent>
          <DialogActions className={classes.buttons}>
            {sending ? (
              <ProgressBar />
            ) : (
              <>
                <Button size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={values.length === 0}
                  onClick={handleInvites}
                >
                  <ShareIcon fontSize="small" className={classes.shareIcon} />
                  <Trans id="share.send_invitations">Share</Trans>
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </I18n>
  )
}

export default ProjectShareForm
