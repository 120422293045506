// @ts-nocheck

import React, { FunctionComponent, useState } from 'react'
import { Badge } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'

import { getTimepointFromDateTime } from '@recordset-local/core/utils/dateTime'

interface ICameraStationDatePickerProps {
  availableDates: DateTime[]
  onDateSelected: (date: DateTime) => void
}

const CameraStationDatePicker: FunctionComponent<ICameraStationDatePickerProps> = ({
  availableDates,
  onDateSelected,
}) => {
  const [selectedDate, setSelectedDate] = useState(DateTime.local())

  const handleSelectedDate = (dt: any) => {
    const date = dt as DateTime
    setSelectedDate(date)
    onDateSelected(getTimepointFromDateTime(date))
  }

  const renderDay = (day: any, _selectedDate: any, isInCurrentMonth: boolean, dayComponent: JSX.Element) => {
    const isAvailable =
      isInCurrentMonth &&
      availableDates.find((date) => Math.abs(date.diff(day, 'day').days) < 1 && date.hasSame(day, 'day')) !== undefined
    return (
      <Badge color="secondary" variant={isAvailable ? 'dot' : undefined}>
        {dayComponent}
      </Badge>
    )
  }

  return <DatePicker value={selectedDate} onChange={handleSelectedDate} renderDay={renderDay} />
}

export default CameraStationDatePicker
