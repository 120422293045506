import { useState, useEffect, useReducer } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { DateTime } from 'luxon'
import * as Sentry from '@sentry/browser'

import GetProjects from '@recordset-local/types/graphql/ProjectList.graphql'
import GetPendingInvitations from '@recordset-local/types/graphql/PendingInvitations.graphql'
import GetProjectFloorsAtTimepoint from '@recordset-local/types/graphql/GetProjectFloorsAtTimepoint.graphql'
import * as Types from '@recordset-local/types/graphql/generatedTypes'

type ProjectListState = {
  page: Types.Page
  filter?: Types.ProjectFilter
  sorting: Types.ProjectSorting
  pageSize: number
}

const makeProjectListState = (
  pageSize: number,
  sortField: Types.ProjectSortField,
  sortOrder: Types.SortOrder,
): ProjectListState => ({
  page: { first: pageSize },
  filter: undefined,
  sorting: { field: sortField, direction: sortOrder },
  pageSize,
})

type ProjectListAction =
  | { type: 'setPage'; page: Types.Page }
  | { type: 'setFilter'; filter: Types.ProjectFilter }
  | { type: 'setSorting'; sorting: Types.ProjectSorting }

const projectListReducer = (state: ProjectListState, action: ProjectListAction) => {
  switch (action.type) {
    case 'setPage':
      return {
        ...state,
        page: action.page,
      }
    case 'setFilter':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
        page: { first: state.pageSize },
      }
    case 'setSorting':
      return {
        ...state,
        sorting: action.sorting,
        page: { first: state.pageSize },
      }
  }
  return state
}

export const useProjectList = (pageSize: number, sortField: Types.ProjectSortField, sortOrder: Types.SortOrder) => {
  const [state, dispatch] = useReducer(projectListReducer, makeProjectListState(pageSize, sortField, sortOrder))

  const setSearchText = ({ name, owner }: { name?: string; owner?: string }) => {
    const filter: Types.ProjectFilter = {}
    filter.name = name !== undefined && name !== '' ? name : undefined
    filter.owner = owner !== undefined && owner !== '' ? owner : undefined
    dispatch({ type: 'setFilter', filter })
  }

  const setSorting = (field: Types.ProjectSortField, direction: Types.SortOrder) => {
    dispatch({ type: 'setSorting', sorting: { field, direction } })
  }

  const setPage = (page: Types.Page) => {
    dispatch({ type: 'setPage', page })
  }

  // TODO: look into custom IDs to make use of cache
  // TODO FUTURE - Web should show only paid projects https://salsita.atlassian.net/browse/REC-449
  const { loading, error, data } = useQuery<Types.ProjectList>(GetProjects, {
    variables: state,
    fetchPolicy: 'network-only',
  })

  return {
    projects: data !== undefined ? data.view.projects : null,
    loading,
    error,
    state,
    setSearchText,
    setSorting,
    setPage,
  }
}

export const useSelectFloor = (projectId: string, floorId: string, timepoint?: DateTime) => {
  const endOfDate = timepoint !== undefined ? timepoint.endOf('day') : DateTime.local().endOf('day')
  const variables: Types.GetProjectFloorsAtTimepointVariables = {
    input: {
      projectId,
      timepoint: endOfDate.toISO(),
    },
  }
  // TODO: look into custom IDs to make use of cache
  const { loading, data } = useQuery<Types.GetProjectFloorsAtTimepoint>(GetProjectFloorsAtTimepoint, {
    variables,
    fetchPolicy: 'network-only',
  })

  const [floor, setFloor] = useState<Types.FloorDetails | null>(null)

  useEffect(() => {
    if (!loading && data !== undefined && data.view !== null) {
      const floor = data.view.projectFloorsAtTimepoint.floors.find((floor) => floor.id === floorId)
      if (floor === undefined) {
        Sentry.captureException(
          new Error(`useSelectorFloor cannot find floor ${floorId} in floor data for project ${projectId}`),
        )
      }
      setFloor(floor !== undefined ? floor : null)
    } else {
      setFloor(null)
    }
  }, [loading, data, floorId, projectId])

  return { floor, loading }
}

export const useGetPendingInvitations = () => {
  const { loading, error, data } = useQuery<Types.PendingInvitations>(GetPendingInvitations)
  const [invitations, setInvitations] = useState<Types.PendingInvitations_view_pendingInvitations_invitations[]>([])

  useEffect(() => {
    if (!loading && data !== undefined) {
      setInvitations(data.view.pendingInvitations.invitations)
    }
  }, [loading, data])

  return { invitations, loading, error }
}
