import { ExtendableError } from '@recordset-local/core/utils'

import * as ErrorCodes from './errorCodes'

export type ApiServerErrorDescriptor = {
  message: string
  code: string
}

export type ApiUserErrorDescriptor = {
  message: string
  code: string
}

const API_USER_ERROR_STATUS = 200

export class ApiUserError extends ExtendableError {
  constructor(
    message: string,
    public clientCode: string,
    public context: any = null,
    public status: number = API_USER_ERROR_STATUS,
  ) {
    super(message)
  }
}

const API_SERVER_ERROR_STATUS = 500

// tslint:disable:max-classes-per-file
export class ApiServerError extends ExtendableError {
  constructor(
    message: string,
    public source: Error | null,
    public clientCode: string = ErrorCodes.SERVER_INTERNAL_ERROR,
    public context: any = null,
    public status: number = API_SERVER_ERROR_STATUS,
  ) {
    super(message)
  }
}

export const isApiUserError = (error: any): error is ApiUserError => {
  return error instanceof ApiUserError
}

export const isApiServerError = (error: any): error is ApiServerError => {
  return error instanceof ApiServerError
}

export const isApiUserErrorDescriptor = (descriptor: any): descriptor is ApiUserErrorDescriptor =>
  descriptor.code !== undefined &&
  descriptor.code !== ErrorCodes.SERVER_INTERNAL_ERROR &&
  descriptor.message !== undefined

export const isApiServerErrorDescriptor = (descriptor: any): descriptor is ApiServerErrorDescriptor =>
  descriptor.code !== undefined &&
  descriptor.code === ErrorCodes.SERVER_INTERNAL_ERROR &&
  descriptor.message !== undefined

export const isExpectedError = (error: any): error is ApiUserError | ApiServerError =>
  isApiServerError(error) || isApiUserError(error)

export const formatError = (error: ApiServerError | ApiUserError) => ({
  message: error.message,
  code: error.clientCode,
  context: error.context !== null ? error.context : undefined,
})

export { ErrorCodes }
