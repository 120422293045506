import React, { FunctionComponent, useState, useEffect } from 'react'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import { isRegisteredUser, isLinkUser, LinkUser, RegisteredUser } from '@recordset-local/core/utils/login'

import { useLogoutApi, useSendConfirmEmailApi } from '@/api/login'
import { getApiRequestErrorMessage } from '@/api/messages'

const useStyles = makeStyles((theme: Theme) => ({
  userName: {
    marginBottom: theme.spacing(3),
  },
  message: {
    marginTop: theme.spacing(3),
  },
  confirmButton: {
    marginRight: theme.spacing(2),
  },
}))

interface IUserProps {
  afterLogout: () => Promise<void>
}

const User: FunctionComponent<IUserProps> = ({ afterLogout }) => {
  const { user } = useCurrentAuthGQLUser()
  const logout = useLogoutApi()
  const classes = useStyles()
  const [showConfirm, setShowConfirm] = useState(isRegisteredUser(user) && user.emailConfirmedDate === null)
  const [{ success: confirmSuccess, error: confirmError }, sendConfirm] = useSendConfirmEmailApi()

  const handleLogout = () => {
    logout(afterLogout)
  }

  const handleConfirm = (email: string) => {
    setShowConfirm(false)
    sendConfirm(email)
  }

  useEffect(() => {
    if (confirmError !== null) {
      setShowConfirm(true)
    }
  }, [confirmError])

  // TODO: Kira - remove this component and put email confirm button to the menu?
  const renderRegisteredUser = (user: RegisteredUser) => {
    return (
      <>
        <Typography variant="h2" className={classes.userName}>
          {user.email}
        </Typography>
        {showConfirm ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirm(user.email)
            }}
            className={classes.confirmButton}
          >
            Confirm email
          </Button>
        ) : null}
        {user !== null ? (
          <Button variant="outlined" color="primary" onClick={handleLogout}>
            Logout
          </Button>
        ) : null}
        {confirmSuccess === true ? (
          <Typography className={classes.message}>Check your email for instructions</Typography>
        ) : null}
        {confirmError !== null ? (
          <Typography color="error" className={classes.message}>
            {getApiRequestErrorMessage(confirmError)}
          </Typography>
        ) : null}
      </>
    )
  }

  const renderLinkUser = (user: LinkUser) => {
    // TODO: offer registration, mobile app etc
    return (
      <>
        <Typography variant="h2" className={classes.userName}>
          Hello {JSON.stringify(user)}!
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleLogout}>
          Logout
        </Button>
      </>
    )
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          {isRegisteredUser(user) ? renderRegisteredUser(user) : isLinkUser(user) ? renderLinkUser(user) : null}
        </Grid>
      </Grid>
    </Container>
  )
}

export default User
