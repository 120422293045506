import React, { FunctionComponent } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Container, Grid, Typography } from '@material-ui/core'
import { Trans } from '@lingui/macro'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import GetProject from '@recordset-local/types/graphql/GetProject.graphql'
import ProjectHeader from '@recordset-local/core/web/components/ProjectHeader'

import { ResourceProvider } from '@/services/resources'

import ProgressBar from './ProgressBar'
import Floor from './Floor'

interface IProjectPreviewProps {
  projectId: string
  userId: string
}

const ProjectPreview: FunctionComponent<IProjectPreviewProps> = ({ projectId, userId }) => {
  const queryVariables: Types.GetProjectVariables = { input: { id: projectId } }
  const { loading, error, data } = useQuery<Types.GetProject>(GetProject, { variables: queryVariables })

  if (error !== undefined || data === undefined || data.view.project === null) {
    return (
      <Container>
        {error !== undefined ? (
          <Typography color="error">{error.message}</Typography>
        ) : (
          <Typography>
            <Trans id="project.no_data">Cannot load project data</Trans>
          </Typography>
        )}
      </Container>
    )
  }

  const { project } = data.view

  return (
    <ResourceProvider projectId={projectId} userId={userId}>
      <Grid container alignContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} alignContent="flex-start" alignItems="flex-start">
          <ProjectHeader title={project.name} />
          <Container disableGutters maxWidth={false}>
            <Grid container direction="column" alignContent="flex-start" alignItems="flex-start">
              <Grid item alignContent="flex-start" alignItems="flex-start">
                {loading ? <ProgressBar /> : null}
                {project.floors.length > 0 ? (
                  <Floor projectId={project.id} floorId={project.floors[0].id} />
                ) : (
                  <Typography>
                    <Trans id="project.no_floors">There are no floors in this project {userId}</Trans>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </ResourceProvider>
  )
}

export default ProjectPreview
