import React, { FunctionComponent, useState, useEffect } from 'react'
import { useRouter } from 'react-router5'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons'
import QRCode from 'qrcode'
import * as Sentry from '@sentry/browser'
import * as branchSdk from 'branch-sdk'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'

import { extractProjectShareDetails } from '@recordset-local/core/services/branch'
import { ThemeColor } from '@recordset-local/theme'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import { isRegisteredUser, isLinkUser } from '@recordset-local/core/utils/login'

import { RouteName } from '@/router'
import { i18n } from '@/locale'
import * as branchServices from '@/services/branch'

import qrStep1 from '@/assets/qr-step1.svg'
import qrStep2 from '@/assets/qr-step2.svg'
import qrStep3 from '@/assets/qr-step3.svg'
import StoreButton from './StoreButton'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '2rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem',
    },
    margin: theme.spacing(3, 0),
  },
  paperGrid: {
    maxWidth: 450,
  },
  paperGridQr: {
    maxWidth: 650,
  },
  paper: {
    flex: '1 1 auto',
    padding: theme.spacing(7, 5),
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(5, 3),
    },
  },
  paperTitle: {
    marginBottom: theme.spacing(5),
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  paperButton: {
    marginTop: theme.spacing(5),
  },
  or: {
    display: 'block',
    textTransform: 'uppercase',
    margin: theme.spacing(2, 0, 2, 1),
  },
  codeWrapper: {
    padding: theme.spacing(4),
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(2),
    },
    border: `2px solid ${ThemeColor.BLACK}`,
    borderRadius: 20,
  },
  code: {
    width: '100%',
  },
  steps: {
    background: ThemeColor.LIGHT_BACKGROUND,
    borderRadius: 20,
    padding: theme.spacing(5, 4),
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(3, 2),
    },
  },
  stepsList: {
    '& *': {
      fontSize: '0.875rem',
    },
  },
}))

interface IInstallAppProps {
  branchData: branchSdk.InitData
  title?: string
  allowGoToWeb?: boolean
}

const InstallApp: FunctionComponent<IInstallAppProps> = ({
  branchData,
  title = i18n._(t('install.nudge_mobile')`Get the RecordSet mobile app`),
  allowGoToWeb = false,
}) => {
  const { user } = useCurrentAuthGQLUser()
  const classes = useStyles()
  const router = useRouter()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [qrUrl, setQrUrl] = useState<string | null>(null)
  const [shareDetails, setShareDetails] = useState(extractProjectShareDetails(branchData.data_parsed))

  useEffect(() => {
    let cancelled = false
    setShareDetails(extractProjectShareDetails(branchData.data_parsed))

    const generateQR = async () => {
      try {
        let link = branchData.data_parsed['~referring_link']
        if (link === undefined) {
          link = await branchServices.createInstallAppLink('webapp')
        }
        const url = await QRCode.toDataURL(link)
        if (!cancelled) {
          setQrUrl(url)
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    if (!isRegisteredUser(user)) {
      generateQR()
    }

    return () => {
      cancelled = true
    }
  }, [branchData, user])

  const handlePhoneNumber = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(ev.target.value)
  }

  const sendInstallSms = async (ev: React.FormEvent) => {
    ev.preventDefault()
    ev.stopPropagation()

    if (phoneNumber !== '') {
      await branchServices.sendAppInstallSms('webapp', phoneNumber, shareDetails !== null ? shareDetails : undefined)
    }
  }

  const renderInstallApp = () => {
    return (
      <I18n>
        {({ i18n }) => (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={10}>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </Grid>

            {allowGoToWeb && (
              <Grid item xs={12} lg={10}>
                <Link onClick={() => router.navigate(RouteName.Login)}>
                  <Trans id="install.goToWeb-text">Take me to the web app</Trans>
                </Link>
              </Grid>
            )}

            <Grid item container xs={12} md={6} lg={3} xl={3} className={classes.paperGrid}>
              <Paper variant="outlined" className={classes.paper}>
                <form onSubmit={sendInstallSms}>
                  <Typography variant="h3" className={classes.paperTitle}>
                    <Trans id="install.donwload_sms">Download via sms</Trans>
                  </Typography>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VerifiedUser fontSize="inherit" />
                        </InputAdornment>
                      ),
                    }}
                    value={phoneNumber}
                    placeholder={i18n._(t('input.phone_placeholder')`Enter your phone number`)}
                    onChange={handlePhoneNumber}
                    required
                    fullWidth
                    autoFocus
                  />
                  <Button color="primary" variant="contained" type="submit" fullWidth className={classes.paperButton}>
                    <Trans id="install.send_message">Send SMS</Trans>
                  </Button>
                </form>
              </Paper>
            </Grid>

            <Grid item container xs={12} md={6} lg={3} xl={3} className={classes.paperGrid}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h3" className={classes.paperTitle}>
                  <Trans id="install.donwload_store">Download from the store</Trans>
                </Typography>

                <StoreButton platform="android" />

                <Box m={1}>
                  <Typography paragraph>
                    <Trans id="install.iosComingSoon-text">iOS app coming soon</Trans>
                  </Typography>
                </Box>
                {/* <Typography className={classes.or}>
                  <Trans id="install.donwload_or">or</Trans>
                </Typography>
                <StoreButton platform="ios" /> */}
              </Paper>
            </Grid>

            {qrUrl !== null && (
              <Grid item container xs={12} md={7} lg={5} xl={5} className={classes.paperGridQr}>
                <Paper variant="outlined" className={classes.paper}>
                  <Grid item container spacing={4}>
                    <Grid item xs={12} sm={7} lg={6} xl={7}>
                      <Typography variant="h3" className={classes.paperTitle}>
                        <Trans id="install.donwload_qr">Download via QR code</Trans>
                      </Typography>
                      <Box className={classes.codeWrapper}>
                        <img alt="QR" src={qrUrl} className={classes.code} />
                      </Box>
                    </Grid>
                    <Grid item container xs={12} sm={5} lg={6} xl={5}>
                      <Grid item xs={12} className={classes.steps}>
                        <Typography variant="h4">
                          <Trans id="install.how_qr">How does it work?</Trans>
                        </Typography>
                        <List className={classes.stepsList}>
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <img src={qrStep1} alt="" />
                            </ListItemIcon>
                            <ListItemText>
                              <Trans id="install.open_camera">Open phone camera</Trans>
                            </ListItemText>
                          </ListItem>
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <img src={qrStep2} alt="" />
                            </ListItemIcon>
                            <ListItemText>
                              <Trans id="install.point_camera">Point your phone on the QR code</Trans>
                            </ListItemText>
                          </ListItem>
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <img src={qrStep3} alt="" />
                            </ListItemIcon>
                            <ListItemText>
                              <Trans id="install.click_notification">Click on the notification</Trans>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        )}
      </I18n>
    )
  }

  return (
    <>
      {isLinkUser(user) ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography>
              <Trans id="install.preview_project">
                You are{' '}
                <Link
                  color="secondary"
                  onClick={() => router.navigate(RouteName.ProjectDetails, { projectId: user.projectId })}
                >
                  previewing a project
                </Link>{' '}
                that was shared with you
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {renderInstallApp()}
    </>
  )
}

export default InstallApp
