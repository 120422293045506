import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

interface IErrorBoundaryProps {
  dsn?: string
}

interface IErrorBoundaryState {
  eventId?: string
  hasError: boolean
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  constructor(props: IErrorBoundaryProps) {
    super(props)

    if (props.dsn !== undefined) {
      Sentry.init({ dsn: props.dsn })
    }
    this.state = { eventId: undefined, hasError: false }
  }

  public componentDidCatch(error: any, errorInfo: any) {
    if (this.props.dsn !== undefined) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  public render() {
    if (this.state.hasError && this.props.dsn !== undefined) {
      return <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
    }

    return this.props.children
  }
}

export default ErrorBoundary
