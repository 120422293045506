import React, { FunctionComponent, useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { DataProxy } from 'apollo-cache'
import { useRouter } from 'react-router5'
import {
  Toolbar,
  Container,
  Button,
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import NotificationsIcon from '@material-ui/icons/Notifications'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import AcceptProject from '@recordset-local/types/graphql/AcceptProject.graphql'
import DeclineProject from '@recordset-local/types/graphql/DeclineProject.graphql'
import GetPendingInvitations from '@recordset-local/types/graphql/PendingInvitations.graphql'

import { RouteName } from '@/router'
import { useGetPendingInvitations } from '@/api/queries'

import * as Sentry from '@sentry/browser'

import ProgressBar from './ProgressBar'
import { ThemeColor } from '@recordset-local/theme'

const useStyles = makeStyles((theme: Theme) => ({
  invitationsWrapper: {
    marginBottom: theme.spacing(6),
    padding: 0,
  },
  title: {
    margin: theme.spacing(3, 0),
  },
  notificationItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(3),
  },
  notificationItemText: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '70%',
    },
  },
  notificationButtons: {
    // alignment - buttons has 5px right padding
    right: 11,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: theme.spacing(3),
      left: theme.spacing(6),
      right: 0,
      marginBottom: theme.spacing(2),
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(7),
    },
  },
  declineButton: {
    color: ThemeColor.BRAND_NEGATIVE,
    '&:hover': {
      backgroundColor: 'rgba(255, 122, 0, .08)',
    },
  },
}))

type Invitation = Types.PendingInvitations_view_pendingInvitations_invitations

type ComposedInvitations = {
  highlight: Invitation | null
  rest: Invitation[]
}

const composeInvitations = (
  invitations: Types.PendingInvitations_view_pendingInvitations_invitations[],
  hightlightId?: string,
): ComposedInvitations => {
  const index =
    hightlightId !== undefined ? invitations.findIndex((invitation) => invitation.projectId === hightlightId) : -1

  if (index >= 0) {
    return {
      highlight: invitations[index],
      rest: [...invitations.slice(0, index), ...invitations.slice(index + 1)],
    }
  }
  return { highlight: null, rest: invitations }
}

const getCachedInvitations = (cache: DataProxy) => {
  const data = cache.readQuery<Types.PendingInvitations>(
    {
      query: GetPendingInvitations,
    },
    true,
  )
  if (data !== null) {
    return data.view.pendingInvitations.invitations
  }
  return null
}

const updateInvitation = (addToAccepted: (project: Types.AcceptProject_view_acceptProject_project) => void) => (
  cache: DataProxy,
  { data }: { data?: Types.AcceptProject | Types.DeclineProject | null },
) => {
  if (data === undefined || data === null) {
    return
  }

  const payload: { ok: boolean; invitationId: string | null; acceptAction?: boolean } =
    'acceptProject' in data.view
      ? { ...data.view.acceptProject, acceptAction: true }
      : 'declineProject' in data.view
      ? data.view.declineProject
      : { ok: false, invitationId: '' }

  if (!payload.ok) {
    // TODO: notifications
    return
  }

  const cached = getCachedInvitations(cache)
  if (cached !== null) {
    const updated = {
      view: {
        __typename: 'RootMutationView',
        pendingInvitations: {
          __typename: 'PendingInvitationsPayload',
          invitations: cached.filter((i) => i.id !== payload.invitationId),
        },
      },
    }
    cache.writeQuery({
      query: GetPendingInvitations,
      data: updated,
    })
    if (payload.acceptAction === true) {
      const project = (payload as Types.AcceptProject_view_acceptProject).project
      if (project !== null) {
        addToAccepted(project)
      }
    }
  }
}

type AcceptedProject = Types.AcceptProject_view_acceptProject_project

interface IPendingInvitationsProps {
  // if specified, this project will be rendered in its own section to set it as primary for action
  hightlightId?: string
  hideAccepted?: boolean
  onAccepted?: (project: AcceptedProject) => void
}

const PendingInvitations: FunctionComponent<IPendingInvitationsProps> = ({
  hightlightId,
  hideAccepted,
  onAccepted,
}) => {
  const router = useRouter()
  const classes = useStyles()
  const { invitations, loading, error } = useGetPendingInvitations()
  const [viewAccepted, setViewAccepted] = useState<AcceptedProject[]>([])
  const [composedInvitations, setComposedInvitations] = useState<ComposedInvitations>(
    composeInvitations(invitations, hightlightId),
  )

  const addToAccepted = (project: Types.AcceptProject_view_acceptProject_project) => {
    setViewAccepted(viewAccepted.concat(project))
    if (onAccepted !== undefined) {
      onAccepted(project)
    }
  }

  const [acceptProject] = useMutation<Types.AcceptProject>(AcceptProject, { update: updateInvitation(addToAccepted) })
  const [declineProject] = useMutation<Types.DeclineProject>(DeclineProject, {
    update: updateInvitation(addToAccepted),
  })

  useEffect(() => {
    setComposedInvitations(composeInvitations(invitations, hightlightId))
  }, [invitations, hightlightId])

  const handleAccept = (invitationId: string) => async () => {
    try {
      const variables: Types.AcceptProjectVariables = {
        input: { invitationId },
      }
      await acceptProject({ variables })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleDecline = (invitationId: string) => async () => {
    try {
      const variables: Types.DeclineProjectVariables = {
        input: { invitationId },
      }
      await declineProject({ variables })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const navigateToProject = (projectId: string) => () => {
    router.navigate(RouteName.ProjectDetails, { projectId })
  }

  const renderInvitation = (invitation: Invitation | null) => {
    if (invitation === null) {
      return null
    }

    return (
      <ListItem disableGutters key={invitation.id}>
        <ListItemIcon className={classes.notificationItemIcon}>
          <NotificationsIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText className={classes.notificationItemText}>
          You have an invitation to the project "{invitation.projectName}" from the user {invitation.invitedBy.name}
        </ListItemText>
        <ListItemSecondaryAction className={classes.notificationButtons}>
          <Button className={classes.declineButton} size="small" onClick={handleDecline(invitation.id)}>
            Decline
          </Button>
          <Button color="primary" size="small" onClick={handleAccept(invitation.id)}>
            Accept
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  const renderViewAccepted = () => {
    // tslint:disable-next-line:no-inverted-boolean-check
    return viewAccepted.length > 0 && !(hideAccepted === true) ? (
      <>
        <Typography variant="h3" className={classes.title}>
          Projects you have accepted
        </Typography>
        <List disablePadding>
          {viewAccepted.map((p) => {
            return (
              <ListItem disableGutters key={p.id}>
                <Link onClick={navigateToProject(p.id)}>{p.name}</Link>
              </ListItem>
            )
          })}
        </List>
      </>
    ) : null
  }

  // TODO Translations
  return (
    <>
      {loading ||
      error !== undefined ||
      composedInvitations.highlight !== null ||
      composedInvitations.rest.length > 0 ? (
        <Toolbar className={classes.invitationsWrapper}>
          <Container>
            {loading ? <ProgressBar /> : null}
            {error !== undefined ? (
              <Typography variant="h3" className={classes.title}>
                {error.message}
              </Typography>
            ) : null}
            {composedInvitations.highlight !== null ? (
              <>
                <Typography variant="h3" className={classes.title}>
                  Accept this invitation to see the project
                </Typography>
                <List disablePadding>{renderInvitation(composedInvitations.highlight)}</List>
                {composedInvitations.rest.length > 0 ? (
                  <Typography variant="h3" className={classes.title}>
                    Other invitations you have
                  </Typography>
                ) : null}
              </>
            ) : null}
            <List disablePadding>{composedInvitations.rest.map(renderInvitation)}</List>
            {renderViewAccepted()}
          </Container>
        </Toolbar>
      ) : null}
    </>
  )
}

export default PendingInvitations
