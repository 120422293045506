import { useState, useEffect } from 'react'

import { callApi } from '@recordset-local/core/web/request'
import { downloadProjectRequest, downloadProjectPoll } from '@recordset-local/core/api/requests/downloadProject'
import { ProjectDownloadJobStatus as RemoteStatus } from '@recordset-local/core/api/constants'

const POLL_INTERVAL_MS = 5000
type DownloadState = null | RemoteStatus

export const useOfflineDownload = (projectId: string) => {
  const [state, setState] = useState<DownloadState>(null)
  const [error, setError] = useState<string | null>(null)
  const [jobId, setJobId] = useState<string | null>(null)
  const [result, setResult] = useState<File | null>(null)
  const [trigger, setTrigger] = useState(false)

  const initiate = async () => {
    try {
      setError(null)
      setState('waiting')
      const { jobId } = await callApi(downloadProjectRequest, { projectId })
      setJobId(jobId)
    } catch (e) {
      setError(e.message)
      setState('failed')
    }
  }

  const poll = async () => {
    if (jobId !== null) {
      setError(null)

      try {
        const result = (await callApi(downloadProjectPoll, { jobId })) as Response
        const disposition = result.headers.get('content-disposition')
        if (disposition !== null && disposition.indexOf('attachment') !== -1) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition)
          if (matches != null && matches[1] !== undefined) {
            const filename = matches[1].replace(/['"]/g, '')
            const data = await result.blob()
            const file = new File([data], filename, { type: 'application/zip' })
            setResult(file)
            setJobId(null)
            setState(null)
          } else {
            throw new Error(`invalid response, no file attachment is found`)
          }
        } else {
          const { ok, status, message } = (await result.json()) as {
            ok: boolean
            status: RemoteStatus
            message?: string
          }
          if (ok) {
            setState(status)
          } else {
            throw new Error(`cannot poll project state, ${message}`)
          }
        }
      } catch (e) {
        setError(e.message)
        setJobId(null)
        setState('failed')
      }
    }
  }

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null

    if (jobId !== null && interval === null) {
      interval = setInterval(poll, POLL_INTERVAL_MS)
    }
    if (jobId === null && interval !== null) {
      clearInterval(interval)
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId])

  useEffect(() => {
    if (state === null && trigger) {
      setTrigger(false)
      initiate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, trigger])

  return { state, error, result, download: () => setTrigger(true) }
}
