import { makeStyles, Theme } from '@material-ui/core'

export const useFormStyles = makeStyles((theme: Theme) => ({
  title: {
    /* fake tab - avoid jumping */
    margin: `6px 0 ${theme.spacing(5)}px`,
    minHeight: theme.spacing(5),
    padding: '6px 0 6px 12px',
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(3),
  },
  link: {
    display: 'inline-flex',
    marginBottom: theme.spacing(3),
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
}))
