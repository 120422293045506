// @ts-nocheck

import React, { FunctionComponent, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Box, Typography, Popover, Fade } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ProgressiveImage from 'react-progressive-image'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { ThemeColor } from '@recordset-local/theme'
import { useCameraImage } from '@recordset-local/core/web/resources/hooks'
import { imageDirectionToLocaleString, cameraImageDataDiffers } from '@recordset-local/core/web/utils'
import ProgressBar from '@recordset-local/core/web/components/ProgressBar'

// TODO UPSHOT implement
type Wedge = Types.FloorDetails_stations_images

interface ICameraStationPlanePreviewProps {
  floorId: string
  cameraId: string
  cameraName: string
  plane: Wedge
  position: { x: number; y: number }
  width?: number
  height?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    position: 'absolute',
    top: 142,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 230,
  },
  popoverPaper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  textWrapper: {
    padding: theme.spacing(2, 4),
  },
  cameraNumber: {
    display: 'block',
    fontWeight: theme.typography.fontWeightBold,
  },
  cameraDirection: {
    display: 'block',
    fontSize: '0.875rem',
  },
  cameraDirectionNoImage: {
    color: ThemeColor.TEXT_GRAY,
  },
  image: {
    width: 200,
    marginBottom: theme.spacing(2),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
}))

const getImageData = (plane: Wedge, floorId: string, cameraId: string) => {
  const [lastImage] = plane.images.slice(-1)
  return lastImage !== undefined
    ? {
        floorId,
        cameraId,
        timepoint: lastImage.timepoint,
        direction: plane.direction,
        imageName: lastImage.image,
      }
    : null
}

const CameraStationPlaneImage: FunctionComponent<ICameraStationPlanePreviewProps> = ({
  plane,
  floorId,
  cameraId,
  position,
  cameraName,
  width,
  height,
}) => {
  const [imageData, setImageData] = useState(getImageData(plane, floorId, cameraId))
  const [image, state] = useCameraImage(imageData, true)
  const classes = useStyles()

  useEffect(() => {
    const newData = getImageData(plane, floorId, cameraId)
    if (cameraImageDataDiffers(newData, imageData)) {
      setImageData(newData)
    }
  }, [plane, floorId, cameraId])

  return (
    <div>
      {state === 'loading' && <ProgressBar />}
      <div style={{ width, height }}>
        {image !== null && (
          <img src={image.src} style={{ backgroundSize: 'contain' }} alt="" className={classes.image} />
        )}
      </div>
      <Box className={classes.textWrapper}>
        <Typography className={classes.cameraNumber}>Photo point {cameraName}</Typography>
        <Typography
          className={classNames(classes.cameraDirection, { [classes.cameraDirectionNoImage]: image === null })}
        >
          {imageDirectionToLocaleString(plane.direction)}
        </Typography>
      </Box>
    </div>
  )
}

const CameraStationPlanePreview: FunctionComponent<ICameraStationPlanePreviewProps> = ({
  width = 200,
  height = 300,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Popover
      open={true}
      anchorReference="anchorPosition"
      anchorPosition={{ left: restProps.position.x, top: restProps.position.y }}
      className={classes.popover}
      classes={{ paper: classes.popoverPaper }}
      TransitionComponent={Fade}
    >
      <CameraStationPlaneImage width={width} height={height} {...restProps} />
    </Popover>
  )
}

export default CameraStationPlanePreview
