import React, { FunctionComponent } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  progressBar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
  },
}))

interface IProgressBarProps {
  progressBarSize?: number
}

const ProgressBar: FunctionComponent<IProgressBarProps> = ({ progressBarSize = 40 }) => {
  const classes = useStyles()

  return (
    <Box className={classes.progressBar}>
      <CircularProgress size={progressBarSize} />
    </Box>
  )
}

export default ProgressBar
