// @ts-ignore
import * as t from 'io-ts'

import { request as doRequest, IApiRequest } from '../request'
import { ApiCalls } from '../constants'

// @ts-ignore
import { PurchaseData } from '@recordset-local/types/server/api'

export const getProjectQuoteRequest = async (apiRequest: IApiRequest<{ projectId: string; userAuth?: string }>) => {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  if (apiRequest.requestData!.userAuth !== undefined) {
    headers.authorization = `Bearer ${apiRequest.requestData!.userAuth}`
  }

  return doRequest(PurchaseData, apiRequest.apiUrl)(ApiCalls.GET_PROJECT_QUOTE_AND_PREPARE_PAYMENT, {
    method: 'POST',
    headers,
    body: JSON.stringify({ projectId: apiRequest.requestData!.projectId }),
  })
}
