import { t } from '@lingui/macro'

import { isApiServerError, isApiUserError, ErrorCodes } from '@recordset-local/core/api/errors'

import { i18n } from '@/locale'

import { ApiRequestError } from './login'

// TODO This is duplicated also in mobile until we figure out how to run lingui in core package
const API_REQUEST_ERROR_MESSAGE_CATALOG: { [key: string]: string } = {
  [ErrorCodes.SERVER_INTERNAL_ERROR]: i18n._(
    t(
      'apiError.SERVER_INTERNAL_ERROR-text',
    )`Something went wrong. Please try again later. If problem persists contact our support.`,
  ),
  [ErrorCodes.SERVER_SESSION_ERROR]: i18n._(
    t(
      'apiError.SERVER_SESSION_ERROR-text',
    )`Something went wrong. Please try again later. If problem persists contact our support.`,
  ),
  [ErrorCodes.USER_ACCESS_VIOLATION]: i18n._(
    t('apiError.USER_ACCESS_VIOLATION-text')`You don't have rights to access this feature.`,
  ),
  [ErrorCodes.USER_ACCOUNT_ALREADY_CONFIRMED]: i18n._(
    t('apiError.USER_ACCOUNT_ALREADY_CONFIRMED-text')`Your account was already verified.`,
  ),
  [ErrorCodes.USER_DUPLICATE_NAME]: i18n._(
    t('apiError.USER_DUPLICATE_NAME-text')`User with specified email already exists.`,
  ),
  [ErrorCodes.USER_DUPLICATE_PROJECT_NAME]: i18n._(
    t('apiError.USER_DUPLICATE_PROJECT_NAME-text')`Project with given name already exists.`,
  ),
  [ErrorCodes.USER_INVALID_CREDENTIALS]: i18n._(
    t('apiError.USER_INVALID_CREDENTIALS-text')`Credentials are not valid.`,
  ),
  // Also used on multiple places like USER_INVALID_INPUT,
  // but in this case message can be probably generalized
  [ErrorCodes.USER_RECORD_NOT_FOUND]: i18n._(t('apiError.USER_RECORD_NOT_FOUND-text')`User was not found.`),
  [ErrorCodes.USER_REQUIRES_REGISTRATION]: i18n._(
    t('apiError.USER_REQUIRES_REGISTRATION-text')`You must be logged in to perform this action.`,
  ),
  [ErrorCodes.USER_TOO_MANY_REQUESTS]: i18n._(
    t(
      'apiError.USER_TOO_MANY_REQUESTS-text',
    )`Password reset link was already sent to this account. You can request password reset once in an hour.`,
  ),
  [ErrorCodes.USER_UNAUTHENTICATED]: i18n._(
    t('apiError.USER_UNAUTHENTICATED-text')`Login failed. Incorrect email or password.`,
  ),
  [ErrorCodes.USER_WEAK_PASSWORD]: i18n._(
    t(
      'apiError.USER_WEAK_PASSWORD-text',
    )`Password must use at least 6 characters including at least 1 number and 1 letter`,
  ),
  [ErrorCodes.USER_NOT_VERIFIED]: i18n._(t('apiError.USER_NOT_VERIFIED-text')`Account was not yet verified`),
  [ErrorCodes.VERIFICATION_LINK_EXPIRED]: i18n._(
    t(
      'apiError.VERIFICATION_LINK_EXPIRED-text',
    )`The verification link has expired. Please generate a new one by re-sending the verification email.`,
  ),
  [ErrorCodes.USER_ACCOUNT_LOCKED]: i18n._(
    t(
      'apiError.USER_ACCOUNT_LOCKED-text',
    )`User account has been locked due to excesive amount of failed verification requests. Please contact our support.`,
  ),
  [ErrorCodes.USER_CONFIRM_TOO_MANY_REQ]: i18n._(
    t('apiError.USER_CONFIRM_TOO_MANY_REQ-text')`Too many requests in a row. Please try again in 1 min`,
  ),
}

export const getApiRequestErrorMessage = (error: ApiRequestError): string => {
  if (!isApiServerError(error) && !isApiUserError(error)) {
    return error.message
  }

  const message = API_REQUEST_ERROR_MESSAGE_CATALOG[error.clientCode]
  return message === undefined ? error.message : message
}
