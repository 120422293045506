// @ts-ignore
import * as t from 'io-ts'

import { timestampToDirName } from '@recordset-local/core/utils'
import { DownloadUrl } from '@recordset-local/types/server/api'

export type UploadFloorPlan = {
  userId: string
  projectId: string
  floorId: string
  // ignore non-existing File type on Node
  // @ts-ignore
  file: File
  fileNameWithSuffix: string
}

export type DownloadFloorPlan = {
  userId: string
  projectId: string
  floorId: string
  imageName: string
}

export type DownloadCameraImage = {
  userId: string
  projectId: string
  floorId: string
  cameraId: string
  timepoint: string
  direction: string
  imageName: string
}

export type AccessUrl = t.TypeOf<typeof DownloadUrl>

/*
 * Given projectId constructs a path for floor plan bucket under which
 * all floor plans for the given projectId can be found.
 *
 * __Note: userId was left out from the path because user is working with different IDs
 * before he register and after he registers__
 */
export const getResourcesRootPath = (_userId: string, projectId: string) => `${projectId}/`

/*
 * Returns a path to a floor plan image for the specified floor.
 * Can be combined with DownloadUrl data to construct image URLs
 */
export const getFloorPlanFilePath = ({ userId, projectId, floorId, imageName }: DownloadFloorPlan) =>
  `${getResourcesRootPath(userId, projectId)}floorPlans/${floorId}/${imageName}`

/*
 * Returns path to a THUMBNAIL floor plan image for the specified floor.
 */
export const getFloorPlanImageThumbnailFilePath = ({ userId, projectId, floorId, imageName }: DownloadFloorPlan) =>
  `${getResourcesRootPath(userId, projectId)}floorPlans/${floorId}/thumbs/${imageName}`

/*
 * Returns path to a camera image for specified project/floor/camera/date/direction
 */
export const getCameraImageFilePath = ({
  userId,
  projectId,
  floorId,
  cameraId,
  timepoint,
  direction,
  imageName,
}: DownloadCameraImage) =>
  `${getResourcesRootPath(userId, projectId)}floorPlans/${floorId}/${cameraId}/${timestampToDirName(
    timepoint,
  )}/${direction}/${imageName}`

/*
 * Returns path to a THUMBNAIL camera image for specified project/floor/camera/date/direction
 */
export const getCameraImageThumbnailFilePath = ({
  userId,
  projectId,
  floorId,
  cameraId,
  timepoint,
  direction,
  imageName,
}: DownloadCameraImage) =>
  `${getResourcesRootPath(userId, projectId)}floorPlans/${floorId}/${cameraId}/${timestampToDirName(
    timepoint,
  )}/${direction}/thumbs/${imageName}`

/**
 *
 * @param accessUrl
 * @param floorPlan
 */
export const getFloorPlanDownloadUrl = (
  accessUrl: AccessUrl,
  floorPlan: DownloadFloorPlan,
  thumbnail: boolean = false,
) => {
  const path = thumbnail ? getFloorPlanImageThumbnailFilePath(floorPlan) : getFloorPlanFilePath(floorPlan)
  return `${accessUrl.downloadUrl}${path}?Authorization=${accessUrl.authorizationToken}`
}

/**
 *
 * @param accessUrl
 * @param cameraImage
 * @param thumbnail
 */
export const getCameraImageDownloadUrl = (
  accessUrl: AccessUrl,
  cameraImage: DownloadCameraImage,
  thumbnail: boolean,
) => {
  const path = thumbnail ? getCameraImageThumbnailFilePath(cameraImage) : getCameraImageFilePath(cameraImage)
  return `${accessUrl.downloadUrl}${path}?Authorization=${accessUrl.authorizationToken}`
}
