import { useState, useEffect } from 'react'
import { t } from '@lingui/macro'

import {
  registerRequest,
  loginRequest,
  logoutRequest,
  passwordForgotRequest,
  passwordResetRequest,
  sendConfirmAccountEmailRequest,
  confirmAccountRequest,
  passwordCreateRequest,
  checkAccountStatusRequest,
} from '@recordset-local/core/api/requests/login'
import { useStorage } from '@recordset-local/core/hooks/storage'
import { callApi, HttpError } from '@recordset-local/core/web/request'
import { ApiUserError, ApiServerError } from '@recordset-local/core/api/errors'

import { removeSessionToken } from '@recordset-local/core/web/storage/session'
import { nonSecureStorage } from '@recordset-local/core/web/storage'
import { i18n } from '@/locale'

export type ApiRequestError = Error | ApiUserError | ApiServerError

export const LOGIN_TOKEN_STORAGE_KEY = 'login_token'

export const useRegisterApi = () => {
  const [data, setData] = useState({ email: '', password: '' })
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<ApiRequestError | null>(null)

  useEffect(() => {
    let cancelled = false

    const callRegister = async () => {
      setError(null)

      try {
        await callApi(registerRequest, data)
        if (!cancelled) {
          setSuccess(true)
        }
      } catch (e) {
        setSuccess(false)
        setError(e)
      }
    }

    if (data.email !== '' && data.password !== '') {
      callRegister()
    }

    return () => {
      cancelled = true
    }
  }, [data])

  return [{ success, error }, setData] as const
}

export const useLoginApi = () => {
  const [login, setLogin] = useState({ email: '', password: '' })
  const [error, setError] = useState<ApiRequestError | null>(null)
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    let cancelled = false

    const callLogin = async () => {
      setError(null)

      try {
        // remove any temp LinkUser logins
        removeSessionToken()

        const result = await callApi(loginRequest, login)
        if (!cancelled) {
          setToken(result.token)
        }
      } catch (e) {
        if (!cancelled) {
          setToken(null)
          setError(e)
        }
      }
    }

    if (login.email !== '' && login.password !== '') {
      callLogin()
    }

    return () => {
      cancelled = true
    }
  }, [login])

  return [{ error, token }, setLogin] as const
}

export const useLogoutApi = () => {
  type CB = () => Promise<void>
  const [logout, setLogout] = useState<[boolean, CB?]>([false, undefined])

  useEffect(() => {
    const callLogout = async () => {
      try {
        // remove any temp LinkUser logins
        removeSessionToken()
        await callApi(logoutRequest)
        if (logout[1] !== undefined) {
          await logout[1]()
        }
        setLogout([false, undefined])
      } catch {
        // TODO: ignore or sentry
      }
    }

    if (logout[0]) {
      callLogout()
    }
  }, [logout])

  return (cb?: CB) => setLogout([true, cb])
}

export const useAuthTokenStorage = () => useStorage(LOGIN_TOKEN_STORAGE_KEY, null, nonSecureStorage)
export const loadAuthToken = async () => nonSecureStorage.getItem(LOGIN_TOKEN_STORAGE_KEY)

export const useSendConfirmEmailApi = () => {
  const [success, setSuccess] = useState<boolean | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ApiRequestError | null>(null)
  const [verificationEmail, setVerificationEmail] = useState<string | null>(null)

  useEffect(() => {
    let cancelled = false
    if (verificationEmail === null) {
      return
    }

    const callSendEmail = async () => {
      setLoading(true)
      setError(null)
      setSuccess(null)

      try {
        await callApi(sendConfirmAccountEmailRequest, { email: verificationEmail })
        if (!cancelled) {
          setSuccess(true)
          setLoading(false)
          setVerificationEmail(null)
        }
      } catch (e) {
        if (!cancelled) {
          setLoading(false)
          setSuccess(false)
          setVerificationEmail(null)
          setError(e)
        }
      }
    }

    callSendEmail()

    return () => {
      cancelled = true
    }
  }, [verificationEmail])

  return [{ error, success, loading }, setVerificationEmail] as const
}

export const useConfirmAccountApi = () => {
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<ApiRequestError | null>(null)

  const sendConfirm = (token: string) => {
    setError(null)

    const callSendEmail = async () => {
      try {
        await callApi(confirmAccountRequest, { token })
        setSuccess(true)
      } catch (e) {
        setSuccess(false)
        if (e instanceof HttpError && e.code === 401) {
          setError(
            new Error(
              i18n._(
                t(
                  'errors.expired_confirm_link-confirmAccount',
                )`The verification link has expired, please generate a new one by re-sending the verification email from the mobile app.`,
              ),
            ),
          )
        } else {
          setError(e)
        }
      }
    }

    callSendEmail()
  }

  return [{ error, success }, sendConfirm] as const
}

export const useForgotPasswordApi = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<ApiRequestError | null>(null)

  useEffect(() => {
    let cancelled = false
    setError(null)

    const callForgot = async () => {
      try {
        await callApi(passwordForgotRequest, { email })
        if (!cancelled) {
          setSuccess(true)
        }
      } catch (e) {
        if (!cancelled) {
          setSuccess(false)
          setError(e)
        }
      }
    }

    if (email !== '') {
      callForgot()
    }

    return () => {
      cancelled = true
    }
  }, [email])

  return [{ error, success }, setEmail] as const
}

export const useResetPasswordApi = () => {
  const [data, setData] = useState({ token: '', password: '' })
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<ApiRequestError | null>(null)

  useEffect(() => {
    let cancelled = false
    setError(null)
    setSuccess(null)

    const callReset = async () => {
      try {
        await callApi(passwordResetRequest, data)
        if (!cancelled) {
          setSuccess(true)
        }
      } catch (e) {
        if (!cancelled) {
          setSuccess(false)
          setError(e)
        }
      }
    }

    if (data.token !== '' && data.password !== '') {
      callReset()
    }

    return () => {
      cancelled = true
    }
  }, [data])

  return [{ error, success }, setData] as const
}

export const useCreatePasswordApi = () => {
  const [data, setData] = useState({ token: '', password: '' })
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<ApiRequestError | null>(null)

  useEffect(() => {
    let cancelled = false
    setError(null)
    setSuccess(null)

    const callCreatePassword = async () => {
      try {
        await callApi(passwordCreateRequest, data)
        if (!cancelled) {
          setSuccess(true)
        }
      } catch (e) {
        setSuccess(false)
        if (e instanceof HttpError && e.code === 401) {
          setError(
            new Error(
              i18n._(
                t(
                  'errors.expired_confirm_link-createPassword',
                )`The verification link has expired and it is no longer possible to create a password. Please create your password via "Forgot password" form accessible from the login page.`,
              ),
            ),
          )
        } else {
          setError(e)
        }
      }
    }

    if (data.token !== '' && data.password !== '') {
      callCreatePassword()
    }

    return () => {
      cancelled = true
    }
  }, [data])

  return [{ error, success }, setData] as const
}

export const useCheckAccountStatusApi = () => {
  const [data, setData] = useState({ token: '' })
  const [result, setResult] = useState<{ isPasswordSet: boolean; isAccountConfirmed: boolean } | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<ApiRequestError | null>(null)

  useEffect(() => {
    let cancelled = false
    setError(null)
    setResult(null)
    setSuccess(null)

    const callCheckAccountStatus = async () => {
      try {
        const { isAccountConfirmed, isPasswordSet } = await callApi(checkAccountStatusRequest, data)
        setResult({ isAccountConfirmed, isPasswordSet })
        if (!cancelled) {
          setSuccess(true)
        }
      } catch (e) {
        if (!cancelled) {
          setSuccess(false)
          setError(e)
        }
      }
    }

    if (data.token !== '') {
      callCheckAccountStatus()
    }

    return () => {
      cancelled = true
    }
  }, [data])

  return [{ accountStatus: result, error, success }, setData] as const
}
