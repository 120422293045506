// Layout
export const BASELINE = 8

export enum Layout {
  BASE_PADDING = BASELINE,
  BASE_MARGIN = BASELINE,
  BASE_BORDER_RADIUS = 10,
}

// Colors
export enum ThemeColor {
  BRAND_PRIMARY = '#00A699',
  BRAND_PRIMARY_LIGHT = '#c6eae8',
  BRAND_SECONDARY = '#FFB26F',
  BRAND_NEGATIVE = '#FF5B6E',
  // This is the same as "typoColors.black.secondary" on mobile
  // these typo colors need to be moved here
  BUTTON_BLACK = 'rgba(0,0,0,0.54)',
  WHITE = '#FFF',
  WHITE_TRANSPARENT = 'rgba(255, 255, 255, 0.5)',
  WHITE_SEMI_TRANSPARENT = 'rgba(255, 255, 255, 0.8)',
  BLACK = '#000',
  INFO = '#00A699',
  ERROR = '#eb1c26',
  WARN = '#FFA17A',
  SUCCESS = '#66C990',
  TEXT = '#292828',
  TEXT_GRAY = '#CCCCCC',
  TEXT_GRAY_DARKER = '#A6A6A6',
  LIGHT_BACKGROUND = '#F0F0F0',
  TRANSPARENT = 'rgba(0, 0, 0, 0)',
  DISABLED = '#A6ADAD',
  DISABLED_TRANSPARENT = 'rgba(166, 173, 173, 0.5)',
  WEDGE_ACTIVE = '#FFB26F', // same as BRAND_SECONDARY
  WEDGE_WITH_PHOTO = '#00897B',
  WEDGE_WITH_OLD_PHOTO = '#71b6ae',
  WEDGE_NO_PHOTO = '#ff5794',
  WEDGE_SEPARATOR = '#fff',
}

export enum Font {
  THIN = 'WorkSans-Thin',
  EXTRA_LIGHT = 'WorkSans-ExtraLight',
  LIGHT = 'WorkSans-Light',
  REGULAR = 'WorkSans-Regular',
  MEDIUM = 'WorkSans-Medium',
  SEMI_BOLD = 'WorkSans-SemiBold',
  BOLD = 'WorkSans-Bold',
  EXTRA_BOLD = 'WorkSans-ExtraBold',
  BLACK = 'WorkSans-Black',
}

export const inputStyles = {
  backgroundColor: ThemeColor.WHITE,
  borderRadius: Layout.BASE_BORDER_RADIUS,
}

export const buttonStyles = {
  borderRadius: Layout.BASE_BORDER_RADIUS,
}
