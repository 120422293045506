import React, { FunctionComponent, useState, useEffect } from 'react'
import { Button, Typography, Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Trans } from '@lingui/macro'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
  ReactStripeElements,
  Elements,
} from 'react-stripe-elements'

import { toUSD } from '@recordset-local/core/utils'
import { BASELINE, ThemeColor } from '@recordset-local/theme'
import { useStripeStyles } from '@/styles/StripeStyles'

import { usePurchaseProject } from '@/api/stripe'

import ProgressBar from './ProgressBar'

const useStyles = makeStyles((theme: Theme) => ({
  // TODO style it responsive
  priceList: {
    marginBottom: theme.spacing(3),
    maxWidth: 315,
  },
  priceTitle: {
    fontSize: '0.875rem',
    '& > span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  priceTotal: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  purchaseTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  // card fields styled without Material
  purchaseWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: 315,
  },
  cardField: {
    position: 'relative',
    marginBottom: BASELINE * 3,
    width: '100%',
    maxWidth: 315,
  },
  halfWidthField: {
    width: `calc(50% - ${BASELINE}px)`,
    display: 'inline-flex',
  },
  cardLabel: {
    zIndex: 1,
    transform: 'translate(14px, 17px) scale(1)',
    pointerEvents: 'none',
    transition: 'color 200ms ease-out, transform 200ms ease-out',
    top: 0,
    left: 0,
    position: 'absolute',
    display: 'block',
    transformOrigin: 'top left',
    fontSize: '1rem',
    background: ThemeColor.WHITE,
  },
}))

interface IProjectPurchaseProps {
  projectId: string
  userAuth?: string
  onCompleted?: () => void
  onProcessing?: (processing: boolean) => void
}

type PurchaseFormProps = IProjectPurchaseProps & ReactStripeElements.InjectedStripeProps
type CardState = {
  cardNumber: boolean
  cardExpiry: boolean
  cardCvc: boolean
}

const PurchaseForm: FunctionComponent<PurchaseFormProps> = ({
  stripe,
  projectId,
  userAuth,
  onCompleted,
  onProcessing,
}) => {
  const { step, error, quote, getQuote, purchase, processing, reset } = usePurchaseProject(projectId, userAuth)
  const [cardElement, setCardElement] = useState<stripe.elements.Element | null>(null)
  const [paymentStatus, setPaymentStatus] = useState<CardState>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })
  const [cardReady, setCardReady] = useState(false)
  const classes = useStyles()
  const stripeClasses = useStripeStyles()

  const handleCardReady = (element: stripe.elements.Element) => {
    setCardElement(element)
  }

  const onCardChange = (ev: stripe.elements.ElementChangeResponse) => {
    const newStatus = { ...paymentStatus, [ev.elementType]: ev.complete }
    setPaymentStatus(newStatus)
    setCardReady(newStatus.cardNumber && newStatus.cardExpiry && newStatus.cardCvc)
  }

  useEffect(() => {
    if (step === 'INITIATE') {
      getQuote()
    } else if (step === 'COMPLETED' && onCompleted !== undefined) {
      onCompleted()
    }
    // eslint-disable-next-line
  }, [step])

  useEffect(() => {
    if (onProcessing !== undefined) {
      onProcessing(processing)
    }
    // eslint-disable-next-line
  }, [processing])

  const handlePurchase = () => {
    if (stripe !== undefined && stripe !== null && cardElement !== null) {
      purchase(cardElement, stripe)
    }
  }

  const renderQuote = () => {
    if (quote === null) {
      // shouldn't be here, either it is in processing or returned with an error
      return null
    }

    if (quote.inProcessing) {
      return (
        <Typography paragraph>
          <Trans id="projectPurchase.processingPayment-text">
            We are processing your payment. You will receive your receipt shortly.
          </Trans>
        </Typography>
      )
    }

    return (
      <Box className={classes.priceList}>
        {/*{Object.entries(quote.floors).map(([floor, price]) => (
          <Typography
            key={floor}
            className={classes.priceTitle}
          >
            <span>Price for floor {floor}:</span> {toUSD(price)}
          </Typography>
        ))} */}
        <Typography color="primary" className={classes.priceTotal}>
          <Trans id="projectPurchase.totalPrice-text">Total price:</Trans> {toUSD(quote.total)}
        </Typography>
      </Box>
    )
  }

  const renderPurchaseComplete = () => {
    return (
      <Typography variant="h3" className={classes.purchaseTitle}>
        <Trans id="projectPurchase.purchaseCompleted-text">Purchase completed</Trans>
      </Typography>
    )
  }

  const renderError = () => {
    return (
      <>
        <Typography paragraph color="error">
          {error}
        </Typography>
        <Button size="small" color="primary" onClick={reset}>
          <Trans id="projectPurchase.tryAgain-btn">Try again</Trans>
        </Button>
      </>
    )
  }

  const renderStep = () => {
    if (processing) {
      return <ProgressBar />
    }

    switch (step) {
      case 'NEEDS_CONFIRMATION':
        return renderQuote()
      case 'COMPLETED':
        return renderPurchaseComplete()
      case 'ERROR':
        return renderError()
    }
  }

  return (
    <form>
      <Typography variant="h3" className={classes.purchaseTitle}>
        <Trans id="projectPurchase.securePaymentTitle-text">Secure payment</Trans>
      </Typography>
      {renderStep()}
      <div style={{ display: step === 'NEEDS_CONFIRMATION' && quote !== null && !quote.inProcessing ? '' : 'none' }}>
        <Typography variant="h3" className={classes.purchaseTitle}>
          <Trans id="projectPurchase.yourCardTitle-text">Your card</Trans>
        </Typography>
        <div className={classes.purchaseWrapper}>
          <div className={classes.cardField}>
            <CardNumberElement
              onReady={handleCardReady}
              onChange={onCardChange}
              placeholder=""
              classes={stripeClasses}
            />
            <label className={classes.cardLabel}>
              <Trans id="projectPurchase.cardNumber-text">Card number</Trans>
            </label>
          </div>
          <div className={`${classes.cardField} ${classes.halfWidthField}`}>
            <CardExpiryElement onChange={onCardChange} placeholder="" classes={stripeClasses} />
            <label className={classes.cardLabel}>
              <Trans id="projectPurchase.cardExpiryDate-text">Expiry date</Trans>
            </label>
          </div>
          <div className={`${classes.cardField} ${classes.halfWidthField}`}>
            <CardCvcElement onChange={onCardChange} placeholder="" classes={stripeClasses} />
            <label className={classes.cardLabel}>
              <Trans id="projectPurchase.cardCvv-text">CVV</Trans>
            </label>
          </div>
          <Button variant="contained" color="primary" fullWidth disabled={!cardReady} onClick={handlePurchase}>
            <Trans id="projectPurchase.purchaseButton-btn">Purchase</Trans>
          </Button>
        </div>
      </div>
    </form>
  )
}

const WrappedForm = injectStripe(PurchaseForm)

const ProjectPurchase: FunctionComponent<IProjectPurchaseProps> = ({ projectId, userAuth, onCompleted }) => (
  <Elements>
    <WrappedForm projectId={projectId} userAuth={userAuth} onCompleted={onCompleted} />
  </Elements>
)

export default ProjectPurchase
