import React, { FunctionComponent, useState, useEffect } from 'react'
import { Button, Link, TextField, Typography } from '@material-ui/core'
import { useRouter, useRoute } from 'react-router5'
import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'

import { RouteName } from '@/router'
import { useLoginApi } from '@/api/login'
import { getApiRequestErrorMessage } from '@/api/messages'
import { useFormStyles } from '@/styles/FormStyles'

interface ILoginFormProps {
  onLogin: () => Promise<void>
}

const LoginForm: FunctionComponent<ILoginFormProps> = ({ onLogin }) => {
  const router = useRouter()
  const {
    route: {
      params: { email: routeEmail },
    },
  } = useRoute()

  const [email, setEmail] = useState(routeEmail)
  const [password, setPassword] = useState('')
  const [{ token, error: apiError }, doLogin] = useLoginApi()
  const classes = useFormStyles()

  useEffect(() => {
    if (token === null) {
      return
    }
    const loginCb = async () => {
      await onLogin()
    }
    loginCb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const onForgotPassword = () => {
    router.navigate(RouteName.PasswordForgot, { email })
  }
  const handleUsername = (ev: React.ChangeEvent<HTMLInputElement>) => setEmail(ev.target.value.trim().toLowerCase())
  const handlePassword = (ev: React.ChangeEvent<HTMLInputElement>) => setPassword(ev.target.value)
  const handleLogin = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    doLogin({ email, password })
  }

  return (
    <I18n>
      {({ i18n }) => (
        <form onSubmit={handleLogin}>
          <TextField
            className={classes.textField}
            type="email"
            value={email}
            placeholder={i18n._(t('input.email_placeholder')`Enter your email`)}
            label={i18n._(t('input.email')`Email`)}
            onChange={handleUsername}
            variant="outlined"
            inputProps={{
              autoCapitalize: 'none',
            }}
            autoCapitalize="none"
            required
            fullWidth
            autoFocus
          />

          <TextField
            className={classes.textField}
            type="password"
            value={password}
            placeholder={i18n._(t('input.password_placeholder')`Enter your password`)}
            label={i18n._(t('input.password')`Password`)}
            onChange={handlePassword}
            variant="outlined"
            required
            fullWidth
          />

          <Link color="primary" onClick={onForgotPassword} className={classes.link}>
            <Trans id="user.forgot_password">Forgot password or don't have one yet?</Trans>
          </Link>

          <Link href="/mobile" color="primary" className={classes.link}>
            <Trans id="user.mobile_app">Looking for the mobile app?</Trans>
          </Link>

          <Button fullWidth variant="contained" color="primary" type="submit" className={classes.button}>
            <Trans id="user.login">Login</Trans>
          </Button>

          {apiError !== null ? (
            <Typography paragraph color="error">
              {getApiRequestErrorMessage(apiError)}
            </Typography>
          ) : null}
        </form>
      )}
    </I18n>
  )
}

export default LoginForm
