export enum BranchLinkFeatures {
  SHARE_PROJECT = 'SHARE_PROJECT',
  SHARE_APP = 'SHARE_APP',
  VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',
  PAID_FOR_SHARING = 'PAID_FOR_SHARING',
  PAID_FOR_BACKUP = 'PAID_FOR_BACKUP',
  PAID_FOR_VIEW_ELSEWHERE = 'PAID_FOR_VIEW_ELSEWHERE',
  INSTALL_APP = 'INSTALL_APP',
  DEMO_PROJECT = 'DEMO_PROJECT',
}

/**
 * Paths as defined in fallback non-branch URI links:
 */
export const FallbackUriPaths = {
  VERIFY_ACCOUNT: 'verify-account',
}

export type ShareProjectData = {
  sharerId: string
  sharerName: string
  projectId: string
  projectName: string
  shareWithEmail?: string
  shareWithPhone?: string
  token: string
}

export type PaidForProjectData = {
  projectId: string
  projectName: string
}

export type VerifyAccountData = {
  email: string
  token: string
}

const ShareProjectOptionalFields: (keyof ShareProjectData)[] = ['shareWithEmail', 'shareWithPhone']

const ShareProjectRequiredFields: (keyof ShareProjectData)[] = [
  'sharerId',
  'sharerName',
  'projectId',
  'projectName',
  'token',
]

const PaidForProjectRequiredFields: (keyof PaidForProjectData)[] = ['projectId', 'projectName']

const VerifyAccountRequiredFields: (keyof VerifyAccountData)[] = ['email', 'token']

type Acc = { [key: string]: string }

export const extractDetailsReduce = (data: CombinedBranchLinkParams): any => (acc: Acc, f: any) => {
  const value = data[f]
  if (value !== undefined && value !== '') {
    acc[f] = value
  }
  return acc
}

export const extractProjectShareDetails = (data: CombinedBranchLinkParams | null): ShareProjectData | null => {
  if (data === null) {
    return null
  }
  const requiredFieldsResult = ShareProjectRequiredFields.reduce(extractDetailsReduce(data), {})
  if (Object.keys(requiredFieldsResult).length !== ShareProjectRequiredFields.length) {
    return null
  } else {
    const optionalFieldsResult = ShareProjectOptionalFields.reduce(extractDetailsReduce(data), {})
    return {
      ...(requiredFieldsResult as ShareProjectData),
      ...optionalFieldsResult,
    }
  }
}

export const extractPaidForProjectDetails = (data: CombinedBranchLinkParams | null): PaidForProjectData | null => {
  const result = data !== null ? PaidForProjectRequiredFields.reduce(extractDetailsReduce(data), {}) : null
  return result !== null && Object.keys(result).length === PaidForProjectRequiredFields.length
    ? (result as PaidForProjectData)
    : null
}

export const extractVerifyAccountDetails = (data: CombinedBranchLinkParams | null): VerifyAccountData | null => {
  const result = data !== null ? VerifyAccountRequiredFields.reduce(extractDetailsReduce(data), {}) : null
  return result !== null && Object.keys(result).length === VerifyAccountRequiredFields.length
    ? (result as VerifyAccountData)
    : null
}
