import * as t from 'io-ts'

import { request as doRequest, IApiRequest, getHeaders } from '../request'
import { ApiCalls } from '../constants'

const LoginResponse = t.type({ token: t.string, expires: t.string })

export interface ILoginDeviceOnlyProps {
  password: string
  deviceId: string
}

export interface ILoginUserProps {
  password: string
  email: string
}

export interface IRegisterProps {
  email: string
  password: string
}

export interface IRegisterDeviceOnlyProps {
  deviceId: string
  password: string
}

export const isDeviceAccountRegisterData = (
  data: IRegisterProps | IRegisterDeviceOnlyProps,
): data is IRegisterDeviceOnlyProps => 'deviceId' in data && 'password' in data

export const isRegularAccountRegisterData = (data: IRegisterProps | IRegisterDeviceOnlyProps): data is IRegisterProps =>
  'email' in data && 'password' in data

export const loginRequest = async (apiRequest: IApiRequest<ILoginDeviceOnlyProps | ILoginUserProps>) =>
  doRequest(LoginResponse, apiRequest.apiUrl)(ApiCalls.LOGIN, {
    method: 'POST',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify(apiRequest.requestData),
  })

export const logoutRequest = async (apiRequest: IApiRequest) =>
  doRequest(t.type({}), apiRequest.apiUrl)(ApiCalls.LOGOUT, {
    method: 'POST',
  })

export const registerRequest = async (apiRequest: IApiRequest<IRegisterProps | IRegisterDeviceOnlyProps>) =>
  doRequest(t.type({}), apiRequest.apiUrl)(ApiCalls.REGISTER, {
    method: 'POST',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify(apiRequest.requestData),
  })

export const sendConfirmAccountEmailRequest = async (apiRequest: IApiRequest<{ email: string }>) =>
  doRequest(t.type({}), apiRequest.apiUrl)(ApiCalls.SEND_CONFIRM_ACCOUNT, {
    method: 'PUT',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify(apiRequest.requestData),
  })

export const confirmAccountRequest = async (apiRequest: IApiRequest<{ token: string }>) =>
  doRequest(t.type({ token: t.string }), apiRequest.apiUrl)(
    `${ApiCalls.CONFIRM_ACCOUNT}?token=${apiRequest.requestData!.token}`,
    {
      method: 'POST',
      headers: getHeaders(apiRequest.headers),
    },
  )

export const passwordForgotRequest = async (apiRequest: IApiRequest<{ email: string }>) =>
  doRequest(t.type({}), apiRequest.apiUrl)(ApiCalls.FORGOT_PASSWORD, {
    method: 'POST',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify(apiRequest.requestData),
  })

export const passwordResetRequest = async (apiRequest: IApiRequest<{ token: string; password: string }>) =>
  doRequest(t.type({}), apiRequest.apiUrl)(`${ApiCalls.RESET_PASSWORD}?token=${apiRequest.requestData!.token}`, {
    method: 'POST',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify({ password: apiRequest.requestData!.password }),
  })

export const passwordCreateRequest = async (apiRequest: IApiRequest<{ token: string; password: string }>) =>
  doRequest(t.type({}), apiRequest.apiUrl)(`${ApiCalls.CREATE_PASSWORD}?token=${apiRequest.requestData!.token}`, {
    method: 'POST',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify({ password: apiRequest.requestData!.password }),
  })
export const checkAccountStatusRequest = async (apiRequest: IApiRequest<{ token: string }>) =>
  doRequest(t.type({ isAccountConfirmed: t.boolean, ok: t.boolean, isPasswordSet: t.boolean }), apiRequest.apiUrl)(
    `${ApiCalls.CHECK_ACCOUNT_STATUS}?token=${apiRequest.requestData!.token}`,
    {
      method: 'GET',
      headers: getHeaders(apiRequest.headers),
    },
  )

export const signDataRequest = async (apiRequest: IApiRequest<object>) =>
  doRequest(t.type({ token: t.string }), apiRequest.apiUrl)(ApiCalls.SIGN_DATA, {
    method: 'PUT',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify({ data: apiRequest.requestData! }),
  })

export const branchLoginRequest = async (apiRequest: IApiRequest<{ token: string; branchId?: string }>) =>
  doRequest(t.type({ token: t.string }), apiRequest.apiUrl)(
    `${ApiCalls.BRANCH_LOGIN}?token=${apiRequest.requestData!.token}`,
    {
      method: 'POST',
      headers: getHeaders(apiRequest.headers),
      body: JSON.stringify({ branchId: apiRequest.requestData!.branchId }),
    },
  )

export const branchDemoLoginRequest = async (apiRequest: IApiRequest<{ projectId: string; branchId?: string }>) =>
  doRequest(t.type({ token: t.string }), apiRequest.apiUrl)(ApiCalls.BRANCH_DEMO_LOGIN, {
    method: 'POST',
    headers: getHeaders(apiRequest.headers),
    body: JSON.stringify(apiRequest.requestData),
  })
