import React, { FunctionComponent, useState, useEffect } from 'react'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useRoute } from 'react-router5'
import { Container, Grid, Button, Typography } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import * as branchSdk from 'branch-sdk'
import { Trans, t } from '@lingui/macro'

import { extractProjectShareDetails, ShareProjectData } from '@recordset-local/core/services/branch'
import { branchLoginRequest } from '@recordset-local/core/api/requests/login'
import { callApi } from '@recordset-local/core/web/request'
import { useCurrentAuthGQLUser } from '@recordset-local/core/hooks/login'
import * as Types from '@recordset-local/types/graphql/generatedTypes'
import GetProject from '@recordset-local/types/graphql/GetProject.graphql'
import AcceptProject from '@recordset-local/types/graphql/AcceptProject.graphql'
import { setSessionToken } from '@recordset-local/core/web/storage/session'
import { isRegisteredUser } from '@recordset-local/core/utils/login'

import { RouteName } from '@/router'
import { i18n } from '@/locale'

import InstallApp from './InstallApp'

interface IAcceptProjectInvitationFormProps {
  branchData: branchSdk.InitData
  branchMatchId: string | null
}

const AcceptProjectInvitationForm: FunctionComponent<IAcceptProjectInvitationFormProps> = ({
  branchData,
  branchMatchId,
}) => {
  const client = useApolloClient()
  const { user, refetch: refetchUser } = useCurrentAuthGQLUser()
  const { router } = useRoute()
  const [shareDetails, setShareDetails] = useState(extractProjectShareDetails(branchData.data_parsed))
  const [showAccept, setShowAccept] = useState(false)

  useEffect(() => {
    setShareDetails(extractProjectShareDetails(branchData.data_parsed))
  }, [branchData, user])

  // triggers project loading for registered users to see if they have accepted the project already
  useEffect(() => {
    if (isRegisteredUser(user) && shareDetails !== null) {
      if (shareDetails.sharerId === user.id) {
        router.navigate(RouteName.ProjectDetails, { projectId: shareDetails.projectId })
      } else {
        const variables: Types.GetProjectVariables = {
          input: {
            id: shareDetails.projectId,
          },
        }
        loadProject({ variables })
      }
    }
    // eslint-disable-next-line
  }, [shareDetails, user])

  // for registered users try to fetch the project, if succeeds it has been accepted already, navigate to it,
  // if it fails then display AcceptButton so they can take action
  const [loadProject, { loading: projectLoading }] = useLazyQuery<Types.GetProject>(GetProject, {
    onCompleted: (data: Types.GetProject) => {
      if (data.view.project !== null) {
        router.navigate(RouteName.ProjectDetails, { projectId: data.view.project.id })
      } else {
        setShowAccept(true)
      }
    },
    onError: () => {
      setShowAccept(true)
    },
  })

  const [acceptProject] = useMutation<Types.AcceptProject>(AcceptProject, {
    onCompleted: (data: Types.AcceptProject) => {
      if (data.view.acceptProject.project !== null) {
        router.navigate(RouteName.ProjectDetails, { projectId: data.view.acceptProject.project.id })
      }
    },
  })

  const handleViewProject = (shareDetails: ShareProjectData) => async () => {
    try {
      if (!isRegisteredUser(user)) {
        // exchange branch token for login token and redirect to login with the new token
        const { token } = await callApi(branchLoginRequest, {
          token: shareDetails.token,
          branchId: branchMatchId !== null ? branchMatchId : undefined,
        })
        setSessionToken(token)
        await client.resetStore()
        refetchUser(() => {
          router.navigate(RouteName.ProjectDetails, { projectId: shareDetails.projectId })
        })
      } else {
        router.navigate(RouteName.ProjectDetails, { projectId: shareDetails.projectId })
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleAcceptProject = (shareDetails: ShareProjectData) => () => {
    const variables: Types.AcceptProjectVariables = {
      input: {
        token: shareDetails.token,
      },
    }
    acceptProject({ variables })
  }

  const renderOpenApp = () => {
    return (
      <Typography variant="h2">
        <Trans id="invitation.accept_mobile">Hello! Please, accept invitation in your mobile app</Trans>
      </Typography>
    )
  }

  const renderActions = () => {
    if (shareDetails === null) {
      return
    }
    if (isRegisteredUser(user)) {
      return showAccept ? (
        <Button color="primary" size="small" onClick={handleAcceptProject(shareDetails)}>
          <Trans id="invitation.accept_project">Accept project</Trans>
        </Button>
      ) : (
        <Button color="primary" size="small" onClick={handleViewProject(shareDetails)}>
          <Trans id="invitation.view_project">View project</Trans>
        </Button>
      )
    }

    return (
      <Button color="primary" size="small" onClick={handleViewProject(shareDetails)}>
        <Trans id="invitation.preview_project">Preview project</Trans>
      </Button>
    )
  }

  if (shareDetails !== null && !projectLoading) {
    return (
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">
              <Trans id="invitation.accept_request">Invitation from the {shareDetails.sharerName}</Trans>
            </Typography>

            {renderActions()}
          </Grid>
        </Grid>

        {branchData.has_app !== false ? (
          renderOpenApp()
        ) : !isRegisteredUser(user) ? (
          <InstallApp
            branchData={branchData}
            title={i18n._(
              t(
                'invitation.installMobileTitle-text',
              )`Project invitation can be accepted only through the RecordSet mobile app`,
            )}
          />
        ) : null}
      </Container>
    )
  }

  return !projectLoading ? (
    <Container maxWidth="xl">
      <Typography variant="h2">
        <Trans id="invitation.invalid_link">Invalid share link</Trans>
      </Typography>
    </Container>
  ) : null
}

export default AcceptProjectInvitationForm
