import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { ApolloProvider } from '@apollo/react-hooks'
import { RouterProvider } from 'react-router5'
import { StripeProvider } from 'react-stripe-elements'
import { I18nProvider } from '@lingui/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import * as Sentry from '@sentry/browser'

import { getSessionToken } from '@recordset-local/core/web/storage/session'

import { ConfigProvider, ConfigContext } from './ConfigContext'
import ErrorBoundary from './ErrorBoundary'
import router from './router'
import AppLayout from './App'
import * as serviceWorker from './serviceWorker'
import { catalogs } from './locale'

const authLink = setContext((_, { headers }) => {
  const token = getSessionToken()
  const newHeaders = token !== null ? { ...headers, Authorization: `Bearer ${token}` } : { ...headers }
  return {
    headers: newHeaders,
  }
})

const clientLink = authLink.concat(
  new HttpLink({
    // If web app and server runs on the same domain
    uri: `${process.env.REACT_APP_API_PATH}/graphql`,
    credentials: 'same-origin',
  }),
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors !== undefined) {
        graphQLErrors.forEach((e) => {
          const { message, locations, path, extensions } = e
          if (extensions === undefined || extensions.code !== 'UNAUTHENTICATED') {
            Sentry.captureException(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          }
        })
      }
      if (networkError !== undefined && (networkError as any).statusCode !== 401) {
        Sentry.captureException(`[Network error]: ${networkError}`)
      }
    }),
    clientLink,
  ]),
  cache: new InMemoryCache(),
})

router.start(() => {
  const App = (
    <ConfigProvider>
      <ConfigContext.Consumer>
        {(config) => (
          <ErrorBoundary dsn={config.webapp.sentryDsn}>
            <ApolloProvider client={client}>
              <RouterProvider router={router}>
                <StripeProvider apiKey={config.stripePk}>
                  <I18nProvider language="en" catalogs={catalogs}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <AppLayout />
                    </MuiPickersUtilsProvider>
                  </I18nProvider>
                </StripeProvider>
              </RouterProvider>
            </ApolloProvider>
          </ErrorBoundary>
        )}
      </ConfigContext.Consumer>
    </ConfigProvider>
  )

  ReactDOM.render(App, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
