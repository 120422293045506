// @ts-nocheck

import { useState, useEffect } from 'react'
import * as t from 'io-ts'

import { UploadFileData, DownloadUrl } from '@recordset-local/types/server/api'
import { decode } from '@recordset-local/core/api/request'
import {
  uploadFileRequest,
  downloadResourceRequest,
  getFloorPlanFilePath,
  UploadFloorPlan,
} from '@recordset-local/core/api/requests/resources'

import { callApi } from '@recordset-local/core/web/request'

const MAX_RETRIES = 3

const readFile = async (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.result !== null) {
        resolve(reader.result as ArrayBuffer)
      } else {
        reject(reader.error)
      }
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsArrayBuffer(file)
  })
}

const fromPromiseLike = async <T>(promiseLike: PromiseLike<T>): Promise<T> => {
  return new Promise((resolve) => {
    promiseLike.then(resolve)
  })
}

const getSha1Hex = async (buffer: ArrayBuffer) => {
  const sha1 = await fromPromiseLike(crypto.subtle.digest('SHA-1', buffer))
  const hashArray = Array.from(new Uint8Array(sha1))
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

const uploadPlan = async ({ userId, projectId, floorId, file, fileNameWithSuffix }: UploadFloorPlan) => {
  const { url } = await callApi(uploadFileRequest)
  const fileData = await readFile(file)
  const hash = await getSha1Hex(fileData)

  const resp = await fetch(url.uploadUrl, {
    method: 'POST',
    headers: {
      Authorization: url.authorizationToken,
      'X-Bz-File-Name': getFloorPlanFilePath({ userId, projectId, floorId, imageName: fileNameWithSuffix }),
      'Content-Type': file.type,
      'X-Bz-Content-Sha1': hash,
    },
    body: file,
  })
  const data = await resp.json()
  return decode(UploadFileData, data)
}

export const useUploadPlan = () => {
  const [fileToUpload, setFileToUpload] = useState<UploadFloorPlan | null>(null)
  const [result, setResult] = useState<t.TypeOf<typeof UploadFileData> | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [retries, setRetries] = useState(0)

  useEffect(() => {
    let cancelled = false
    setError(null)

    const callUploadPlan = async (file: UploadFloorPlan) => {
      try {
        const data = await uploadPlan(file)
        if (!cancelled) {
          setFileToUpload(null)
          setResult(data)
        }
      } catch (e) {
        if (!cancelled) {
          if (retries < MAX_RETRIES) {
            setRetries(retries + 1)
          } else {
            setFileToUpload(null)
            setResult(null)
            setError(e)
          }
        }
      }
    }

    if (fileToUpload !== null) {
      callUploadPlan(fileToUpload)
    }

    return () => {
      cancelled = true
    }
  }, [fileToUpload, retries])

  return [setFileToUpload, { error, result }] as const
}

export const getAccessUrl = async (projectId: string) => callApi(downloadResourceRequest, { projectId })
