import React, { FunctionComponent } from 'react'
import { useRoute } from 'react-router5'

import { Grid } from '@material-ui/core'

import { routerParamToProjectField, routerParamToSortOrder } from '@/router'
import { useConfig } from '@/ConfigContext'
import ProjectListView from '@/components/ProjectListView'
import ProjectDetailsView from '@/components/ProjectDetailsView'

const Projects: FunctionComponent = () => {
  const { route } = useRoute()
  const {
    local: { projectsPerPage, projectSortField: defaultField, projectSortOrder: defaultOrder },
  } = useConfig()

  const { projectId, sort, order } = route.params
  const projectSortField = sort !== undefined ? routerParamToProjectField(sort) : defaultField
  const projectSortOrder = order !== undefined ? routerParamToSortOrder(order) : defaultOrder

  return (
    <Grid container>
      <Grid item xs={12}>
        {projectId !== undefined ? (
          <ProjectDetailsView projectId={projectId} />
        ) : (
          <ProjectListView pageSize={projectsPerPage} sortField={projectSortField} sortOrder={projectSortOrder} />
        )}
      </Grid>
    </Grid>
  )
}

export default Projects
