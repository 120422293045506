// @ts-nocheck

import { useState, useEffect } from 'react'

import { useResourceProvider, CameraImage, FloorImage } from '@recordset-local/core/web/resources/ResourceContext'
import { usePrevious } from '@recordset-local/core/hooks/previous'
import { cameraImageDataDiffers } from '@recordset-local/core/web/utils'
import noImage from '@/assets/no-image.png'

type State = 'loading' | 'loaded' | 'error' | 'missing' | 'cancelled'

const imageLoader = (
  url: string | null,
  setImage: (image: HTMLImageElement | null) => void,
  setState: (state: State) => void,
  useCors: boolean = false,
) => {
  let cancelled = false
  const img = new Image()

  const onLoad = () => {
    if (!cancelled) {
      setState(url !== null ? 'loaded' : 'missing')
      setImage(img)
    }
  }

  const onError = () => {
    if (!cancelled) {
      setState('error')
      setImage(null)
    }
  }

  setState('loading')

  img.addEventListener('load', onLoad)
  img.addEventListener('error', onError)
  img.src = url !== null ? url : noImage
  if (useCors) {
    img.crossOrigin = 'Anonymous'
  }

  return () => {
    cancelled = true
    img.removeEventListener('load', onLoad)
    img.removeEventListener('error', onError)
    setState('cancelled')
  }
}

export const useCameraImageUrl = (imageData: CameraImage | null, useThumbnail: boolean = false) => {
  const { getCameraImage } = useResourceProvider()
  const prevImageData = usePrevious(imageData)
  const prevUseThumbnail = usePrevious(useThumbnail)
  const [url, setUrl] = useState<string | null>(null)
  const [state, setState] = useState<State>('loading')

  useEffect(() => {
    if (cameraImageDataDiffers(imageData, prevImageData) || prevUseThumbnail !== useThumbnail) {
      const url: string | null = getCameraImage(imageData, useThumbnail)
      setState(url !== null ? 'loaded' : 'missing')
      setUrl(url)
    }
  }, [imageData, useThumbnail])

  return [url, state] as const
}

export const useCameraImage = (imageData: CameraImage, preferThumbnail: boolean = false, useCors: boolean = false) => {
  const [useThumbnail, setUseThumbnail] = useState(preferThumbnail)
  const [url] = useCameraImageUrl(imageData, useThumbnail)
  const [state, setState] = useState<State>('loading')
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    return imageLoader(url, setImage, setState, useCors)
  }, [url])

  useEffect(() => {
    if (state === 'error' && useThumbnail === true) {
      // couldn't find a thumbnail, try fullsize
      setUseThumbnail(false)
    }
  }, [state, useThumbnail])

  return [image, state] as const
}

export const useFloorPlanImageUrl = (imageData: FloorImage | null) => {
  const { getFloorPlan } = useResourceProvider()
  const prevImageData = usePrevious(imageData)
  const [url, setUrl] = useState<string | null>(null)
  const [state, setState] = useState<State>('loading')

  useEffect(() => {
    if (
      imageData !== null &&
      (url === null ||
        prevImageData === null ||
        prevImageData.floorId !== imageData.floorId ||
        prevImageData.image !== imageData.image)
    ) {
      const url = imageData !== null ? getFloorPlan(imageData) : null
      setState(url !== null ? 'loaded' : 'missing')
      setUrl(url)
    } else if (imageData === null) {
      setState('missing')
      setUrl(noImage)
    }
  }, [imageData, prevImageData])

  return [url, state] as const
}

export const useFloorPlanImage = (imageData: FloorImage | null) => {
  const [url] = useFloorPlanImageUrl(imageData)
  const [state, setState] = useState<State>('loading')
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => imageLoader(url, setImage, setState), [url])

  return [image, state] as const
}
