// @ts-ignore
import { useState, useEffect, useCallback } from 'react'
// @ts-ignore
import { useQuery } from '@apollo/react-hooks'

// @ts-ignore
import * as Types from '@recordset-local/types/graphql/generatedTypes'
import GetUser from '@recordset-local/types/graphql/User.graphql'

// tslint:disable-next-line:max-union-size
export type LoggedUserType = Types.User_identity_user | Types.User_identity_linkUser | Types.User_identity_device | null

type UserState = {
  user: LoggedUserType
  refetch: (onSuccess?: () => void, onError?: (error: any) => void) => Promise<void>
  loading: boolean
}

type LoginOpts = {
  skipQuery?: boolean
}

/**
 * Returns currently authenticated user in GQL context.
 * User gets into the context after koa passport JWT auth on "/graphql" endpoint is successfully performed.
 * JWT is either set in the cookie or in request header.
 */
export const useCurrentAuthGQLUser = ({ skipQuery }: LoginOpts = { skipQuery: false }): UserState => {
  const [user, setUser] = useState<Omit<UserState, 'refetch'>>({
    user: null,
    loading: false,
  })

  const getUser = useCallback((identity: Types.User_identity): LoggedUserType => {
    return identity.user !== null
      ? identity.user
      : identity.linkUser !== null
      ? identity.linkUser
      : identity.device !== null
      ? identity.device
      : null
  }, [])

  const { loading: dataLoading, data: fetchedUserData, refetch: dataRefetch } = useQuery<Types.User>(GetUser, {
    skip: skipQuery,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    setUser({
      // Set user only once loading finished
      user: dataLoading
        ? null
        : fetchedUserData !== null && fetchedUserData !== undefined
        ? getUser(fetchedUserData.identity)
        : null,
      loading: dataLoading,
    })
  }, [fetchedUserData, dataLoading])

  const refetch = useCallback(
    async (onSuccess?: () => void, onError?: (error: any) => void): Promise<void> => {
      try {
        const newUser = await dataRefetch()
        setUser({ user: getUser(newUser.data.identity), loading: false })
        if (onSuccess !== undefined) {
          onSuccess()
        }
      } catch (e) {
        if (onError !== undefined) {
          onError(e)
        }
        // TODO Pass Sentry to hook
        // Sentry.captureException(e)
        setUser({ user: null, loading: false })
      }
    },
    [dataRefetch, setUser, getUser],
  )

  return { ...user, refetch }
}
