// @ts-ignore
import * as t from 'io-ts'

// Most of the fields in the types below map directly to Backblaze API unless specified otherwise

/*
 * Holds data necessary to upload a file to Backblaze
 * see recordset-server/services/backblaze for details
 */
export const UploadUrl = t.type({
  bucketId: t.string,
  uploadUrl: t.string,
  authorizationToken: t.string,
})

/*
 * Holds data necessary to download a file from Backblaze
 * see recordset-server/services/backblaze for details
 */
export const DownloadUrlRaw = t.type({
  bucketId: t.string,
  fileNamePrefix: t.string,
  authorizationToken: t.string,
})

/*
 * Extension: BB API doesn't return a download URL, clients cant obtain it either
 */
export const DownloadUrl = t.intersection([DownloadUrlRaw, t.type({ downloadUrl: t.string })])

/*
 * Used by client apps as a response type for uploaded files
 */
export const UploadFileData = t.type({
  fileId: t.string,
  fileName: t.string,
  accountId: t.string,
  bucketId: t.string,
  contentLength: t.number,
  contentSha1: t.string,
  contentType: t.string,
  fileInfo: t.object,
})

/*
 * Client App settings that can be fetched from the backend
 * DO NOT include private settings here, the data will be visible to unauthorized users
 */
export const WebAppConfig = t.type({
  enableEditing: t.boolean,
  sentryDsn: t.union([t.string, t.undefined]),
})

export const Config = t.type({
  stripePk: t.string, // Stripe public key for payments
  webapp: WebAppConfig, // contains webapp specific settings
})

/*
 * A stripe purchase data that is passed from the backend to clients to display project cost and
 proceed with the payment. floors/total are prices for each floor and a sum respectively.
 inProcessing will be set to true if a purchase has been made already and is being processed.
 No attempts should be made to purchase a project that is in processing. See /recordset-server/src/api.ts for details
 Prices are in a smallest currency unit e.g. cents
 */
export const PurchaseData = t.type({
  secret: t.union([t.string, t.null]),
  floors: t.record(t.string, t.number),
  total: t.number,
  inProcessing: t.boolean,
})
