import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeColor, buttonStyles, inputStyles } from '@recordset-local/theme'

const defaultTheme = createMuiTheme()

export const Theme = createMuiTheme({
  palette: {
    primary: {
      main: ThemeColor.BRAND_PRIMARY,
    },
    secondary: {
      main: ThemeColor.BRAND_SECONDARY,
    },
    error: {
      main: ThemeColor.ERROR,
    },
    background: {
      default: ThemeColor.WHITE,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Work Sans", sans-serif',
    fontSize: 18,
  },
  overrides: {
    MuiAppBar: {
      root: {
        background: ThemeColor.WHITE,
        boxShadow: 'none',
      },
    },
    MuiToolbar: {
      root: {
        background: ThemeColor.WHITE,
        borderBottom: `1px solid ${ThemeColor.LIGHT_BACKGROUND}`,
      },
      regular: {
        minHeight: 56,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: inputStyles.borderRadius,
        fontSize: '1rem',
      },
      input: {
        background: inputStyles.backgroundColor,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: inputStyles.borderRadius,
      },
    },
    MuiInput: {
      disabled: {},
      focused: {},
      underline: {
        '&::before': {
          borderBottomWidth: 1,
        },
        '&::after': {
          borderBottomWidth: 1,
        },
        '&:hover:not($disabled)::before': {
          borderBottomWidth: 1,
        },
        '&$focused::after': {
          borderBottomWidth: 1,
        },
      },
    },
    MuiSelect: {
      root: {
        borderRadius: buttonStyles.borderRadius,
      },
      select: {
        '&:focus': {
          borderRadius: buttonStyles.borderRadius,
          backgroundColor: ThemeColor.WHITE,
        },
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: buttonStyles.borderRadius,
      },
      contained: {
        boxShadow: 'none',
        border: `1px solid ${ThemeColor.TEXT_GRAY}`,
      },
    },
    MuiButton: {
      root: {
        borderRadius: buttonStyles.borderRadius,
        fontSize: '1.125rem',
        fontWeight: 500,
        height: 'auto',
        minHeight: 56,
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:active': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        fontWeight: 700,
      },
      sizeSmall: {
        fontSize: '1.125rem',
        height: 'auto',
        minHeight: 0,
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
      },
      extended: {
        '&$sizeSmall': {
          borderRadius: 20,
          height: 'auto',
          minHeight: 34,
          padding: defaultTheme.spacing(1, 2),
        },
      },
      sizeSmall: {
        textTransform: 'none',
        fontSize: '0.874rem',
      },
      primary: {
        backgroundColor: ThemeColor.BRAND_PRIMARY_LIGHT,
        color: ThemeColor.BLACK,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: '0 4px 14px rgba(0, 0, 0, .15)',
        },
      },
    },
    MuiTabs: {
      indicator: {
        borderRadius: 2,
        height: 4,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&$selected': {
          fontWeight: 700,
        },
      },
      textColorPrimary: {
        color: ThemeColor.BLACK,
      },
      wrapper: {
        alignItems: 'flex-start',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '2.375rem',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: buttonStyles.borderRadius,
      },
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.125rem',
      },
    },
    MuiTable: {
      root: {
        width: '100%',
        marginBottom: defaultTheme.spacing(4),
      },
    },
    MuiTableCell: {
      root: {
        border: 'none',
        paddingLeft: 0,
        whiteSpace: 'nowrap',
      },
      head: {
        borderBottom: 'none',
        verticalAlign: 'top',
      },
      body: {
        borderBottom: 'none',
        [defaultTheme.breakpoints.up('md')]: {
          paddingBottom: 0,
        },
        [defaultTheme.breakpoints.down('sm')]: {
          borderBottom: `1px solid ${ThemeColor.LIGHT_BACKGROUND}`,
        },
        '&:first-child': {
          maxWidth: '50%',
          whiteSpace: 'normal',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'inherit',
        opacity: 0.42,
        paddingBottom: 3,
        '&:hover': {
          color: 'inherit',
          opacity: 1,
          '& $icon': {
            opacity: 1,
          },
        },
      },
      active: {
        opacity: 1,
        '& $icon': {
          opacity: 1,
        },
      },
      icon: {
        color: 'inherit',
        opacity: 1,
        fill: 'inherit',
        order: 0,
        marginLeft: 0,
        marginRight: 8,
      },
    },
    MuiTablePagination: {
      toolbar: {
        borderBottom: 'none',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
        boxShadow: '0 4px 10px rgba(0, 0, 0, .2)',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '1.5rem',
        fontWeight: 700,
        padding: defaultTheme.spacing(4, 8, 2),
      },
    },
    MuiDialogContent: {
      root: {
        padding: defaultTheme.spacing(5, 8),
      },
      dividers: {
        padding: defaultTheme.spacing(5, 8),
      },
    },
    MuiDialogContentText: {
      root: {
        color: ThemeColor.BLACK,
      },
    },
    MuiDialogActions: {
      root: {
        padding: defaultTheme.spacing(2, 8),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .4)',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 10,
        boxShadow: '0 10px 24px rgba(0, 0, 0, .15)',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '1.5rem',
        fontWeight: 700,
      },
      h3: {
        fontSize: '1.125rem',
        fontWeight: 400,
      },
      h4: {
        fontSize: '0.875rem',
        fontWeight: 700,
      },
      body1: {
        fontSize: '1.125rem',
      },
    },
  },
})
