// @ts-ignore
import * as Types from '@recordset-local/types/graphql/generatedTypes'

export type RegisteredUser = Omit<Types.User_identity_user, '__typename'>
export type LinkUser = Omit<Types.User_identity_linkUser, '__typename'>
export type DeviceUser = Omit<Types.User_identity_device, '__typename'>
export type User = RegisteredUser | LinkUser | DeviceUser

export const isRegisteredUser = (user: User | null): user is RegisteredUser =>
  user !== null && (user as any).projectId === undefined && (user as any).email !== undefined

export const isDeviceUser = (user: User | null): user is DeviceUser =>
  user !== null && (user as any).projectId === undefined && (user as any).deviceId !== undefined

export const isLinkUser = (user: User | null): user is LinkUser =>
  user !== null && (user as any).projectId !== undefined

export const isDemoLinkUser = (user: User | null): user is LinkUser => isLinkUser(user) && user.demoProject === true
