import React, { FunctionComponent, useState, useEffect } from 'react'
import { Container, Grid, Typography, Button, Link } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Trans } from '@lingui/macro'

import { useDebounce } from '@recordset-local/core/hooks/debounce'
import { BranchLinkFeatures } from '@recordset-local/core/services/branch'

import ProjectPurchase from '@/components/ProjectPurchase'
import { createPaidForProjectLink } from '@/services/branch'
import { useFormStyles } from '@/styles/FormStyles'

const useStyles = makeStyles((theme: Theme) => ({
  purchaseContainer: {
    padding: theme.spacing(4),
  },
  purchaseTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  purchaseMesssage: {
    marginBottom: theme.spacing(3),
  },
}))

interface IPurchaseProjectProps {
  token: string
  projectId: string
  projectName: string
  branchFeature?: string
  redirectUrl?: string
}

const PurchaseProject: FunctionComponent<IPurchaseProjectProps> = ({
  projectId,
  projectName,
  token,
  redirectUrl,
  branchFeature,
}) => {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const [completed, setCompleted] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [branchUrl, setBranchUrl] = useState<string | null>(null)
  const debouncedRedirect = useDebounce(completed, 5000)

  useEffect(() => {
    if (!completed) {
      if (branchFeature !== undefined && branchFeature === BranchLinkFeatures.PAID_FOR_SHARING) {
        const createBranchUrl = async () => {
          const url = await createPaidForProjectLink('webApp', { projectId, projectName })
          setBranchUrl(url)
        }
        createBranchUrl()
      } else if (redirectUrl !== undefined) {
        window.location.href = redirectUrl
      }
    }
    // eslint-disable-next-line
  }, [debouncedRedirect])

  const handleCompleted = () => {
    setCompleted(true)
  }

  const handleProcessing = (isProcessing: boolean) => {
    setProcessing(isProcessing)
  }

  return (
    <Container maxWidth="xl" className={classes.purchaseContainer}>
      <Grid container>
        <Grid item xs={12}>
          {!completed && !processing ? (
            <>
              <Typography variant="h3" className={classes.purchaseTitle}>
                <Trans id="purchaseProject.purchaseMainHeadline-text">Download your RecordSet</Trans>
              </Typography>
              <Typography className={classes.purchaseMesssage}>
                <Trans id="purchaseProject.appreciate-text">
                  We appreciate your interest in downloading your dynamic, interactive RecordSet!
                </Trans>
              </Typography>
              <Typography className={classes.purchaseMesssage}>
                <Trans id="purchaseProject.hostedFeatures-text">Downloading also lets you:</Trans>
              </Typography>
              <ul>
                <li>
                  <Typography className={classes.purchaseMesssage}>
                    <Trans id="purchaseProject.featureShare-text">Share your project with others</Trans>
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.purchaseMesssage}>
                    <Trans id="purchaseProject.featureBackup-text">Backup your project automatically</Trans>
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.purchaseMesssage}>
                    <Trans id="purchaseProject.featureViewElsewhere-text">
                      View your project on another device such as your laptop
                    </Trans>
                  </Typography>
                </li>
              </ul>
              <Typography className={classes.purchaseMesssage}>
                <Trans id="purchaseProject.purchaseProceed-text">Complete this purchase with a one-time fee:</Trans>
              </Typography>
              <ProjectPurchase
                projectId={projectId}
                userAuth={token}
                onCompleted={handleCompleted}
                onProcessing={handleProcessing}
              />
            </>
          ) : null}
          {completed && redirectUrl !== undefined ? (
            <Typography>
              <Trans id="purchaseProject.purchaseTakingBack-text">Taking you back in 5 seconds</Trans>
            </Typography>
          ) : null}
          {completed && branchUrl !== null ? (
            <>
              <Typography variant="h3" className={classes.purchaseTitle}>
                <Trans id="purchaseProject.purchaseCompleted-text">Purchase completed!</Trans>
              </Typography>

              <Typography className={classes.purchaseMesssage}>
                <Trans id="purchaseProject.purchaseCompletedViewWebApp-text">
                  As a free bonus, we are now hosting your project on the web at:
                </Trans>
              </Typography>

              <Link href="http://app.getrecordset.com" color="primary" className={classes.purchaseMesssage}>
                <Trans id="purchaseProject.purchaseCompletedViewWebApp-link">app.getrecordset.com</Trans>
              </Link>

              <br />
              <br />

              <Button variant="contained" color="primary" className={formClasses.button} href={branchUrl}>
                <Trans id="purchaseProject.purchaseShareNow-text">Share project with others</Trans>
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PurchaseProject
