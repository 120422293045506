import createRouter, { Route, State } from 'router5'
import browserPlugin from 'router5-plugin-browser'
import { t } from '@lingui/macro'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { URLS } from '@recordset-local/core/constants'

import { i18n } from './locale'

export const ROUTE_SEPARATOR = '.'

// Registration is currently possible only via the mobile app

export enum RouteName {
  Home = 'home',
  Login = 'login',
  // Register = 'register',
  User = 'user',
  ProjectList = 'projects',
  ProjectDetails = 'project',
  PasswordForgot = 'forgotPassword',
  PasswordReset = 'resetPassword',
  AccountConfirm = 'confirmAccount',
  ProjectView = 'viewProject',
  PurchaseProject = 'purchaseProject',
  GetMobileApp = 'getMobileApp',
  ReceiptWebAccess = 'receiptWebAccess',
}

const routes: Route[] = [
  { name: RouteName.Home, path: '/' },
  { name: RouteName.Login, path: '/login?:email' },
  // { name: RouteName.Register, path: '/register' },
  { name: RouteName.User, path: '/user' },
  { name: RouteName.ProjectDetails, path: '/project/:projectId?:floor' },
  { name: RouteName.ProjectList, path: '/projects?:sort&:order' },
  { name: RouteName.PasswordForgot, path: '/password/forgot/?:email' },
  { name: RouteName.PasswordReset, path: URLS.PASSWORD_RESET },
  { name: RouteName.AccountConfirm, path: '/verify-account' },
  { name: RouteName.ProjectView, path: '/project/view?:token' },
  { name: RouteName.PurchaseProject, path: URLS.PURCHASE_PROJECT },
  { name: RouteName.GetMobileApp, path: '/mobile' },
  { name: RouteName.ReceiptWebAccess, path: URLS.RECEIPT_WEB },
]

export const getDisplayNameForRoute = (route: RouteName) => {
  switch (route) {
    case RouteName.Home:
      return i18n._(t('route.home')`Home`)
    case RouteName.Login:
      return i18n._(t('route.login')`Login`)
    // case RouteName.Register: return i18n._(t('route.register')`Register`)
    case RouteName.User:
      return i18n._(t('route.user')`User`)
    case RouteName.ProjectList:
      return i18n._(t('route.projectList')`Projects`)
    case RouteName.ProjectDetails:
      return i18n._(t('route.projectDetails')`Project details`)
    case RouteName.PasswordForgot:
      return i18n._(t('route.passwordForgot')`Forgot password`)
    case RouteName.PasswordReset:
      return i18n._(t('route.passwordReset')`Reset password`)
    case RouteName.AccountConfirm:
      return i18n._(t('route.accountConfirm')`Confirm account`)
    case RouteName.ProjectView:
      return i18n._(t('route.projectView')`View project`)
    case RouteName.PurchaseProject:
      return i18n._(t('route.purchaseProject')`Purchase project`)
    case RouteName.GetMobileApp:
      return i18n._(t('route.getMobileApp')`Get mobile app`)
  }
}

export const extractRoute = (route: State): RouteName => route.name.split(ROUTE_SEPARATOR)[0] as RouteName

export const projectFieldToRouterParam = (field: Types.ProjectSortField) => {
  switch (field) {
    case Types.ProjectSortField.USER_EDIT_DATE:
      return 'modified'
    case Types.ProjectSortField.NAME:
      return 'title'
    case Types.ProjectSortField.USER_CREATED_DATE:
      return 'created'
  }
}

export const sortOrderToRouterParam = (order: Types.SortOrder) => {
  return order === Types.SortOrder.ASCENDING ? 'asc' : 'desc'
}

export const routerParamToProjectField = (field: ReturnType<typeof projectFieldToRouterParam>) => {
  switch (field) {
    case 'modified':
      return Types.ProjectSortField.USER_EDIT_DATE
    case 'created':
      return Types.ProjectSortField.USER_CREATED_DATE
    case 'title':
    default:
      return Types.ProjectSortField.NAME
  }
}

export const routerParamToSortOrder = (order: string) =>
  order === 'asc' ? Types.SortOrder.ASCENDING : Types.SortOrder.DESCENDING

const router = createRouter(routes, { defaultRoute: RouteName.Home, queryParamsMode: 'loose' })
router.usePlugin(browserPlugin())

export default router
