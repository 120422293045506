import * as Types from '../graphql/generatedTypes'

// TODO UPSHOT Diagonal shots disabled for now
export type CameraStationPlaneDirection = Extract<
  Types.ImageDirection,
  // tslint:disable-next-line max-union-size
  | Types.ImageDirection.N
  // | Types.ImageDirection.DN
  | Types.ImageDirection.NE
  // | Types.ImageDirection.DNE
  | Types.ImageDirection.E
  // | Types.ImageDirection.DE
  | Types.ImageDirection.SE
  // | Types.ImageDirection.DSE
  | Types.ImageDirection.S
  // | Types.ImageDirection.DS
  | Types.ImageDirection.SW
  // | Types.ImageDirection.DSW
  | Types.ImageDirection.W
  // | Types.ImageDirection.DW
  | Types.ImageDirection.NW
  // | Types.ImageDirection.DNW
  | Types.ImageDirection.UP
>

export type CameraStationDiagonalPlaneDirection = Extract<
  Types.ImageDirection,
  // tslint:disable-next-line max-union-size
  | Types.ImageDirection.DN
  | Types.ImageDirection.DNE
  | Types.ImageDirection.DE
  | Types.ImageDirection.DSE
  | Types.ImageDirection.DS
  | Types.ImageDirection.DSW
  | Types.ImageDirection.DW
  | Types.ImageDirection.DNW
>

export type CameraStationCompassDirection = Extract<
  Types.ImageDirection,
  // tslint:disable-next-line max-union-size
  | Types.ImageDirection.N
  | Types.ImageDirection.NE
  | Types.ImageDirection.E
  | Types.ImageDirection.SE
  | Types.ImageDirection.S
  | Types.ImageDirection.SW
  | Types.ImageDirection.W
  | Types.ImageDirection.NW
>

// Ordered basic directions
export const ORDERED_CAMERA_STATION_COMPASS_DIRECTIONS: CameraStationCompassDirection[] = [
  Types.ImageDirection.N,
  Types.ImageDirection.NE,
  Types.ImageDirection.E,
  Types.ImageDirection.SE,
  Types.ImageDirection.S,
  Types.ImageDirection.SW,
  Types.ImageDirection.W,
  Types.ImageDirection.NW,
]

// TODO UPSHOT Diagonal shots disabled for now
// Ordered advanced directions
export const ORDERED_CAMERA_STATION_PLANE_DIRECTIONS: CameraStationPlaneDirection[] = [
  Types.ImageDirection.N,
  // Types.ImageDirection.DN,
  Types.ImageDirection.NE,
  // Types.ImageDirection.DNE,
  Types.ImageDirection.E,
  // Types.ImageDirection.DE,
  Types.ImageDirection.SE,
  // Types.ImageDirection.DSE,
  Types.ImageDirection.S,
  // Types.ImageDirection.DS,
  Types.ImageDirection.SW,
  // Types.ImageDirection.DSW,
  Types.ImageDirection.W,
  // Types.ImageDirection.DW,
  Types.ImageDirection.NW,
  // Types.ImageDirection.DNW,
  Types.ImageDirection.UP,
]

export const ORDERED_CAMERA_STATION_DIAGONAL_PLANE_DIRECTIONS: CameraStationDiagonalPlaneDirection[] = [
  Types.ImageDirection.DN,
  Types.ImageDirection.DNE,
  Types.ImageDirection.DE,
  Types.ImageDirection.DSE,
  Types.ImageDirection.DS,
  Types.ImageDirection.DSW,
  Types.ImageDirection.DW,
  Types.ImageDirection.DNW,
]

// TODO Dirty fix for WEB camera station rendering - wedges starts from EAST on the web
// Fix Konva render later to have it started from NORTH

// Ordered basic directions
export const WEB_ORDERED_CAMERA_STATION_COMPASS_DIRECTIONS: CameraStationCompassDirection[] = [
  Types.ImageDirection.E,
  Types.ImageDirection.SE,
  Types.ImageDirection.S,
  Types.ImageDirection.SW,
  Types.ImageDirection.W,
  Types.ImageDirection.NW,
  Types.ImageDirection.N,
  Types.ImageDirection.NE,
]

// TODO UPSHOT Diagonal shots disabled for now
// Ordered advanced directions
export const WEB_ORDERED_CAMERA_STATION_PLANE_DIRECTIONS: CameraStationPlaneDirection[] = [
  Types.ImageDirection.E,
  // Types.ImageDirection.DE,
  Types.ImageDirection.SE,
  // Types.ImageDirection.DSE,
  Types.ImageDirection.S,
  // Types.ImageDirection.DS,
  Types.ImageDirection.SW,
  // Types.ImageDirection.DSW,
  Types.ImageDirection.W,
  // Types.ImageDirection.DW,
  Types.ImageDirection.NW,
  // Types.ImageDirection.DNW,
  Types.ImageDirection.UP,
  Types.ImageDirection.N,
  // Types.ImageDirection.DN,
  Types.ImageDirection.NE,
  // Types.ImageDirection.DNE,
]

export const WEB_ORDERED_CAMERA_STATION_DIAGONAL_PLANE_DIRECTIONS: CameraStationDiagonalPlaneDirection[] = [
  Types.ImageDirection.DE,
  Types.ImageDirection.DSE,
  Types.ImageDirection.DS,
  Types.ImageDirection.DSW,
  Types.ImageDirection.DW,
  Types.ImageDirection.DNW,
  Types.ImageDirection.DN,
  Types.ImageDirection.DNE,
]

export const isSupportedPlaneDirection = (
  direction: Types.ImageDirection | null,
): direction is CameraStationPlaneDirection =>
  direction !== null && ORDERED_CAMERA_STATION_PLANE_DIRECTIONS.indexOf(direction as CameraStationPlaneDirection) !== -1

export const isSupportedCompassDirection = (
  direction: Types.ImageDirection | null,
): direction is CameraStationCompassDirection =>
  direction !== null &&
  ORDERED_CAMERA_STATION_COMPASS_DIRECTIONS.indexOf(direction as CameraStationCompassDirection) !== -1

export const isDiagonalPlaneDirection = (
  direction: Types.ImageDirection | null,
): direction is CameraStationDiagonalPlaneDirection =>
  direction !== null &&
  ORDERED_CAMERA_STATION_DIAGONAL_PLANE_DIRECTIONS.indexOf(direction as CameraStationDiagonalPlaneDirection) !== -1
