// @ts-ignore
import { DateTime, LocaleOptions } from 'luxon'

export type DateFormat = 'SHORT'

type AllDateFormats = { [key in DateFormat]: LocaleOptions & Intl.DateTimeFormatOptions }

const DateFormatOptions: AllDateFormats = {
  SHORT: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  },
}

export const formatDate = (dateTime: DateTime, format: DateFormat = 'SHORT') =>
  dateTime.toLocaleString(DateFormatOptions[format])

export const isSameDate = (firstDateTime: DateTime, secondDateTime: DateTime) =>
  firstDateTime.hasSame(secondDateTime, 'year') &&
  firstDateTime.hasSame(secondDateTime, 'month') &&
  firstDateTime.hasSame(secondDateTime, 'day')

// Since we compare timepoints with <, >, <=, >=
// We need to compare the equality of timestamp here (we don't care about the timezone and other info)
// to make it consistent with the comparisons above,
// that's why we coerce given date times.
// see https://moment.github.io/luxon/docs/manual/math.html#comparing-datetimes
export const isSameTimepoint = (firstTimepointDateTime: DateTime, secondTimepointDateTime: DateTime) =>
  +firstTimepointDateTime === +secondTimepointDateTime

export const timestampToDirName = (isoTimestamp: string) =>
  DateTime.fromISO(trimTimezone(isoTimestamp)).toFormat('yyyy-MM-dd')

const trimTimezone = (timepoint: string) => timepoint.substring(0, timepoint.indexOf('T'))

/**
 * Converts given ISO timestamp to a timepoint.
 * Timepoint "time" part is timezone specific, that's why ISO timepoint strings
 * returned by the server needs to be converted to a "client specific timepoint".
 * In the end we don't care about the timezone or time information, that's why it is stripped.
 * Timepoint is currently defined as a date only information (time is set to end of the day).
 * Given timestamp is therefore just shifted to the end of the day.
 *
 * TODO FUTURE It would be ideal to just pass plane date strings, like "11-11-2020" instead of ISO date strings
 * and store these plain strings to the DB instead of the "Date" object.
 *
 * @param isoTimestamp
 */
export const getTimepointFromTimestamp = (isoTimestamp: string) =>
  // trimZone is a temporary fix until timepoint will be plain string
  DateTime.fromISO(trimTimezone(isoTimestamp)).endOf('day')

/**
 * Same as getTimepointFromTimestamp but returns DateTime in UTC instead of local timezone.
 * Supposed to be used on the server.
 * @param isoTimestamp
 */
export const getTimepointFromTimestampUTC = (isoTimestamp: string) =>
  // trimZone is a temporary fix until timepoint will be plain string
  DateTime.fromISO(trimTimezone(isoTimestamp), { zone: 'utc' }).endOf('day')

/**
 * Converts given DateTime to a timepoint.
 *
 * @see getTimepointFromTimestamp
 * @param isoTimestamp
 */
export const getTimepointFromDateTime = (dateTime: DateTime) => dateTime.endOf('day')

export const sortDateTimes = (dateTimes: DateTime[], sortDirection: 'asc' | 'desc') => {
  if (sortDirection === 'desc') {
    return [...dateTimes].sort((a, b) => (a > b ? -1 : 1))
  } else {
    return [...dateTimes].sort((a, b) => (a > b ? 1 : -1))
  }
}

/**
 * Takes a DateTime in local timezone and converts it to the JSDate timepoint, meaning:
 * - it ignores the timezone of the local DateTime
 * - it returns the JSDate set to the same date as the local DateTime (no tz shifts) and the time is set to the end of the day
 * @param local Local DateTime to be converted to JSDate
 */
export const localDateToJsDateWithoutTz = (local: DateTime) => {
  return getTimepointFromTimestampUTC(local.toISO()).toJSDate()
}
