// @ts-nocheck

import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import { Button, ButtonGroup, Select, MenuItem } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import ZoomInIcon from '@material-ui/icons/Add'
import ZoomOutIcon from '@material-ui/icons/Remove'
import ResetIcon from '@material-ui/icons/ZoomOutMap'
import { Stage, Layer, Image, Text } from 'react-konva'
import konva from 'konva'

import * as Types from '@recordset-local/types/graphql/generatedTypes'
import { ThemeColor, inputStyles } from '@recordset-local/theme'
import { usePrevious } from '@recordset-local/core/hooks/previous'
import { useFloorPlanImage } from '@recordset-local/core/web/resources/hooks'
import CameraStationPlanePreview from '@recordset-local/core/web/components/CameraStationPlanePreview'
import CameraStationPlaneDialog from '@recordset-local/core/web/components/CameraStationPlaneDialog'
import CameraStation from '@recordset-local/core/web/components/CameraStation'
import ProgressBar from '@recordset-local/core/web/components/ProgressBar'
import { calculateImageParams } from '@recordset-local/core/web/utils'

type Floor = Types.FloorDetails
type Station = Types.FloorDetails_stations
type Wedge = Types.FloorDetails_stations_images

const useStyles = makeStyles((theme: Theme) => ({
  zoomButtons: {
    position: 'absolute',
    left: theme.spacing(4),
    top: theme.spacing(4),
  },
  zoomButton: {
    background: ThemeColor.WHITE,
  },
  cameraSelect: {
    width: 292,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: theme.spacing(4),
  },
  cameraSelectRoot: {
    fontSize: '1rem',
  },
  cameraSelectOutlined: {
    borderRadius: inputStyles.borderRadius,
  },
  cameraSelectIcon: {
    fontSize: '1.5rem',
  },
  cameraSelectItem: {
    padding: '18px 14px',
    fontSize: '1rem',
  },
  cameraSelectPlaceholder: {
    display: 'none',
  },
}))

interface IFloorPlanProps {
  size: IFloorSize
  floor: Floor | null
}

interface IFloorSize {
  width: number
  height: number
}

const INITIAL_SIZE = { scale: 1, offset: { x: 0, y: 0 } }
const CAMERA_MIN_SIZE = 60
const CAMERA_MAX_SIZE = 120
const PREVIEW_IMAGE_WIDTH = 200
const PREVIEW_IMAGE_HEIGHT = 300

// tslint:disable-next-line:no-big-function
const FloorPlan: FunctionComponent<IFloorPlanProps> = ({ size, floor }) => {
  const [floorImage, floorImageState] = useFloorPlanImage(
    floor !== null && floor.plan !== null ? { floorId: floor.id, imageName: floor.plan.image } : null,
  )
  const [planSize, setPlanSize] = useState<{ scale: number; offset: konva.Vector2d }>(INITIAL_SIZE)
  const [cameraSize, setCameraSize] = useState(0)
  const [previewPos, setPreviewPos] = useState({ x: 0, y: 0 })
  const prevSize = usePrevious(planSize)
  const [activeStation, setActiveStation] = useState<Station | null>(null)
  const [previewPlaneDismissIntervalId, setPreviewPlaneDismissIntervalId] = useState<string | null>(null)
  const [previewPlane, setPreviewPlane] = useState<{
    plane: Wedge
    cameraId: string
    cameraName: string
    pos: konva.Vector2d
  } | null>(null)
  const [dialogStation, setDialogStation] = useState<{ station: Station; direction: Types.ImageDirection } | null>(null)
  const stageRef = useRef<any>()
  const screenRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  const getStage = () => {
    const stage = stageRef.current
    if (stage !== null && stage instanceof konva.Stage) {
      return stage
    }
    return null
  }

  // Prevent any interval leak
  useEffect(() => {
    return () => {
      if (previewPlaneDismissIntervalId !== null) {
        clearInterval(previewPlaneDismissIntervalId)
      }
    }
  }, [previewPlaneDismissIntervalId])

  // calculate floor plan view parameters
  useEffect(() => {
    setPlanSize(floorImage !== null ? calculateImageParams(floorImage, size) : INITIAL_SIZE)
  }, [floorImage, size])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      planSize !== INITIAL_SIZE &&
      prevSize !== null &&
      (prevSize.offset.x !== planSize.offset.x ||
        prevSize.offset.y !== planSize.offset.y ||
        prevSize.scale !== planSize.scale)
    ) {
      resetStage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planSize])

  useEffect(() => {
    if (activeStation !== null) {
      zoomStation(activeStation)
    } else {
      resetStage()
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [activeStation])

  const updateCameraSize = () => {
    const stage = getStage()
    if (stage === null || floorImage === null) {
      return
    }

    const imageSize = (floorImage.width * 0.01) / stage.scaleX()
    const clamped = Math.min(Math.max(imageSize, CAMERA_MIN_SIZE), CAMERA_MAX_SIZE)
    setCameraSize(clamped)
  }

  const zoomStation = ({ id }: Station) => {
    const stage = getStage()
    if (stage === null) {
      return
    }

    const station = stage.findOne(`.${id}`)
    if (station === undefined) {
      return
    }

    // TODO: figure out zoom level?
    stage.scale({ x: 2, y: 2 })
    updateCameraSize()

    const center = { x: size.width / 2, y: size.height / 2 }
    const absPos = station.getAbsolutePosition()
    const offset = { x: center.x - absPos.x, y: center.y - absPos.y }
    const stagePos = stage.getAbsolutePosition()
    stage.position({ x: stagePos.x + offset.x, y: stagePos.y + offset.y })
    stage.batchDraw()
  }

  const zoomFloor = (delta: number, atPoint: { x: number; y: number }) => {
    const stage = getStage()
    if (stage === null) {
      return
    }

    const oldScale = stage.scaleX()
    const pos = {
      x: (atPoint.x - stage.x()) / oldScale,
      y: (atPoint.y - stage.y()) / oldScale,
    }

    const scaleBy = 1.1
    const newScale = delta > 0 ? oldScale * scaleBy : oldScale / scaleBy
    if (newScale < planSize.scale) {
      return
    }

    stage.scale({ x: newScale, y: newScale })
    updateCameraSize()

    const newPos = {
      x: -(pos.x - atPoint.x / newScale) * newScale,
      y: -(pos.y - atPoint.y / newScale) * newScale,
    }
    stage.position(newPos)
    stage.batchDraw()
  }

  const handleStationPlaneClick = (station: Station, direction: Types.ImageDirection) => {
    setDialogStation({ station, direction })
  }

  const handleStationPlaneHover = (station: Station, direction: Types.ImageDirection, action: 'enter' | 'leave') => {
    if (action === 'leave') {
      // Set dismiss interval
      const dismissIntervalId = setInterval(() => {
        setPreviewPlane(null)
        setPreviewPlaneDismissIntervalId(null)
      }, 300)

      setPreviewPlaneDismissIntervalId(dismissIntervalId)
    } else {
      const stage = getStage()
      if (stage === null) {
        return
      }
      const stageStation = stage.findOne(`.${station.id}`)
      if (stageStation === undefined) {
        return
      }

      let pos = previewPos
      if (previewPlaneDismissIntervalId !== null) {
        clearInterval(previewPlaneDismissIntervalId)
        setPreviewPlaneDismissIntervalId(null)
      } else {
        const parentPos = screenRef.current !== null ? screenRef.current.getBoundingClientRect() : { top: 0, left: 0 }
        const stageRect = stageStation.getClientRect()

        const defaultPosX = stageRect.x + parentPos.left
        const defaultPosY = stageRect.y + parentPos.top
        pos = { x: defaultPosX, y: defaultPosY }
        if (defaultPosX - PREVIEW_IMAGE_WIDTH < 0) {
          // right shift
          pos.x = pos.x + PREVIEW_IMAGE_WIDTH * 1.2
        } else {
          // left shift
          pos.x = pos.x - PREVIEW_IMAGE_WIDTH / 2
        }
        setPreviewPos(pos)
      }

      const plane = station.images.find((i) => i.direction === direction)
      setPreviewPlane(plane !== undefined ? { plane, cameraId: station.id, cameraName: `${station.label}`, pos } : null)
    }
  }

  const handleChangeStation = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const id = ev.target.value as string
    if (floor !== null) {
      const station = floor.stations.find((s) => s.id === id)
      setActiveStation(station !== undefined ? station : null)
    }
  }

  const handleStageWheel = (ev: konva.KonvaEventObject<WheelEvent>) => {
    ev.evt.preventDefault()

    const stage = getStage()
    if (stage === null) {
      return
    }

    const pointer = stage.getPointerPosition()
    if (pointer === null) {
      return
    }

    zoomFloor(-ev.evt.deltaY, pointer)
  }

  const handleZoomIn = () => {
    zoomFloor(1, { x: size.width / 2, y: size.height / 2 })
  }

  const handleZoomOut = () => {
    zoomFloor(-1, { x: size.width / 2, y: size.height / 2 })
  }

  const handleMoveFloor = (ev: konva.KonvaEventObject<DragEvent>) => {
    const stage = getStage()
    if (stage !== null) {
      stage.position({ x: ev.target.x(), y: ev.target.y() })
    }
  }

  const resetStage = () => {
    const stage = getStage()
    if (stage !== null) {
      stage.position({ x: 0, y: 0 })
      stage.scale({ x: planSize.scale, y: planSize.scale })
      stage.batchDraw()
      updateCameraSize()
    }
  }

  const renderPlanLayer = () => {
    if (floorImage === null) {
      return null
    }

    return (
      <Layer x={planSize.offset.x} y={planSize.offset.y}>
        <Image image={floorImage} />
      </Layer>
    )
  }

  const renderCameraLayer = () => {
    if (floorImageState === 'loaded' && floor !== null) {
      return (
        <Layer x={planSize.offset.x} y={planSize.offset.y}>
          {floor.stations.map((s) => (
            <CameraStation
              key={s.id}
              station={s}
              size={cameraSize}
              onClick={() => {
                return
              }}
              onPlaneClick={handleStationPlaneClick}
              onPlaneHover={handleStationPlaneHover}
            />
          ))}
        </Layer>
      )
    }

    return null
  }

  return (
    <>
      <div ref={screenRef} style={{ alignSelf: 'flex-start' }} />

      {floorImageState === 'loading' ? <ProgressBar /> : null}

      <Stage
        ref={stageRef}
        width={size.width}
        height={size.height}
        onWheel={handleStageWheel}
        draggable
        onDragEnd={handleMoveFloor}
      >
        {renderPlanLayer()}
        {renderCameraLayer()}
      </Stage>

      <ButtonGroup
        orientation="vertical"
        size="small"
        variant="contained"
        classes={{ root: classes.zoomButtons, grouped: classes.zoomButton }}
      >
        <Button onClick={handleZoomIn}>
          <ZoomInIcon color="secondary" fontSize="small" />
        </Button>
        <Button onClick={handleZoomOut}>
          <ZoomOutIcon color="secondary" fontSize="small" />
        </Button>
        <Button onClick={resetStage}>
          <ResetIcon fontSize="small" />
        </Button>
      </ButtonGroup>

      {floor !== null && floor.stations.length > 0 && (
        <Select
          value={activeStation !== null ? activeStation.id : ''}
          onChange={handleChangeStation}
          displayEmpty
          variant="outlined"
          className={classes.cameraSelect}
          classes={{
            root: classes.cameraSelectRoot,
            outlined: classes.cameraSelectOutlined,
            icon: classes.cameraSelectIcon,
          }}
        >
          <MenuItem value="" disabled className={classes.cameraSelectPlaceholder}>
            Select photo point
          </MenuItem>
          {floor.stations.map((s) => (
            <MenuItem key={s.id} value={s.id} className={classes.cameraSelectItem}>
              {s.label}
            </MenuItem>
          ))}
        </Select>
      )}

      {previewPlane !== null && (
        <CameraStationPlanePreview
          position={previewPlane.pos}
          floorId={floor.id}
          cameraId={previewPlane.cameraId}
          plane={previewPlane.plane}
          cameraName={previewPlane.cameraName}
        />
      )}

      {dialogStation !== null && (
        <CameraStationPlaneDialog
          floorId={floor.id}
          station={dialogStation.station}
          direction={dialogStation.direction}
          onClose={() => setDialogStation(null)}
        />
      )}
    </>
  )
}

export default FloorPlan
